import 'reflect-metadata';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import ruRU from 'antd/lib/locale/ru_RU';

import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { LoginForm } from './components/LoginForm';
import { Permissions } from './enums/permissions';
import { authService, userService } from './services';

import './index.css';
import 'moment/locale/ru';

moment.locale('ru');

const init = async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  const token = authService.getToken();

  if (token) {
    await userService.getUser();
  }

  if (!userService.hasAccess([
    Permissions.SUPER_ADMIN,
    Permissions.ADMIN,
    Permissions.MANAGER,
    Permissions.MECHANIC,
  ])) {
    return root.render(<LoginForm />);
  }

  return root.render(
    <BrowserRouter>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </BrowserRouter>,
  );
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
