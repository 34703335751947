import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader, Button, Tabs } from 'antd';

import { notificationService, userService } from '../../services';
import { Permissions } from '../../enums/permissions';
import { Pages, Table, useResize } from '1_shared';
import { ProductTableType } from '2_entities';
import { getProductAdvanceSearchData, getProductSimpleSearchData } from '3_features';
import {
  ProductSimpleSearch,
  ProductAdvanceSearch,
  ProductTableColumns,
} from '4_widgets';

export const ProductsPage = () => {
  const { width, height, isScreenLg } = useResize();
  const [activeTabKey, setActiveTabKey] = useState('simple-search');
  
  const yСoef = activeTabKey === 'advance-search' ? 75 : 0;

  return (
    <>
      <PageHeader
        className="page-header"
        title="Товары"
        subTitle="Список товаров"
        extra={userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) && [
          <Button key="1" type="primary">
            <Link to={Pages.PRODUCT_CREATE}>
              Создать товар
            </Link>
          </Button>,
        ]}
      />
      <Tabs
        defaultActiveKey={ProductTableType.SIMPLE}
        activeKey={activeTabKey}
        onChange={setActiveTabKey}
        items={[
          {
            key: 'simple-search',
            label: 'Поиск',
            children: <ProductSimpleSearch />,
          },
          {
            key: 'advance-search',
            label: 'Расширенный поиск',
            children: <ProductAdvanceSearch />,
          },
        ]}
      />
      <Table
        columns={ProductTableColumns}
        fetchTableData={activeTabKey === 'simple-search' ? getProductSimpleSearchData : getProductAdvanceSearchData}
        defaultSortOrder={['changedAt DESC']}
        notificationService={notificationService}
        scroll={isScreenLg ? { y: height - 280 - yСoef } : { x: width, y: 400 - yСoef }}
      />
    </>
  );
};
