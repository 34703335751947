import { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Product } from 'interfaces';
import { ImagePreview } from '1_shared';

interface IProductTableQuickViewButton {
  product: Partial<Product>
}

export const ProductTableQuickViewButton = ({ product }: IProductTableQuickViewButton): JSX.Element => {
  const [open, setOpen] = useState(false);

  const {
    name,
    image,
    gallery,
    code,
    price,
    count,
    additionalCount,
    secondAdditionalCount,
    locations = [],
    comment,
  } = product;

  return (
    <>
      <Tooltip placement="top" title="Быстрый просмотр">
        <Button type="dashed" size="small" style={{ marginTop: 12 }} onClick={() => setOpen(true)}>
          <EyeOutlined />
        </Button>
      </Tooltip>
      <Modal
        title={name}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        style={{ textAlign: 'center' }}
      >
        <div style={{ marginBottom: 10 }}><strong>Код товара: </strong>{code} <strong>Цена: </strong>{price}</div>
        <div style={{ marginBottom: 10 }}>
          <table style={{ textAlign: 'center', width: '100%' }}>
            <tr>
              <th>На Ириновском</th>
              <th>На Бокситогорской</th>
              <th>На Придорожной</th>
            </tr>
            <tr>
              <td>{count ?? 0}</td>
              <td>{additionalCount ?? 0}</td>
              <td>{secondAdditionalCount ?? 0}</td>
            </tr>
          </table>
        </div>
        <div style={{ marginBottom: 10 }}>
          <ImagePreview height={200} image={image} gallery={gallery} />
        </div>
        <div>
          {locations.map(({ row, rack, section, boxNumber }, index) => (
            <div key={index}>
              <strong>Ряд - </strong>{row}
              <strong> Стеллаж - </strong>{rack}
              <strong> Секция - </strong>{section}
              <strong> № Ящика - </strong>{boxNumber}
            </div>
          ))}
        </div>
        <div dangerouslySetInnerHTML={{ __html: comment as string }} />
      </Modal>
    </>
  );
};