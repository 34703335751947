import { useEffect, useState } from 'react';
import { dictionaries } from '../dictionaries';

export const useFetchDictionaries = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const promises: Array<Promise<any>> = [];

      Object.keys(dictionaries).forEach((key: string) => {
        // @ts-ignore: Unreachable code error
        promises.push(dictionaries[key].fetch());
      });

      await Promise.all(promises);

      setLoading(false);
    };

    fetch();
  }, []);

  return isLoading;
};