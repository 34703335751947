import { Tree, PageHeader, Button, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { Pages } from '../../1_shared/constants/pages';
import { dictionaries } from '../../dictionaries';
import { apiService, notificationService, userService } from '../../services';
import { Permissions } from '../../enums/permissions';

export const TypesPage = () => {
  const navigate = useNavigate();

  const handleSelect = (selectedKeys: any, e: any) => {
    const position: Array<string> = e.node.pos.split('-');

    if (position.length === 2) {
      navigate(`${Pages.TYPES}/${selectedKeys}`);
    }

    if (position.length === 3) {
      navigate(`${Pages.CATEGORIES}/${selectedKeys}`);
    }
  };

  const generateDictionary = async () => {
    try {
      await apiService.GET('/dictionaries/product-types/generate');
      notificationService.notifySuccess('Словарь для сайта успешно сгенерирован');
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  return (
    <>
      <PageHeader
        className="page-header"
        title="Типы"
        subTitle="Список типов"
        extra={userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) && [
          <Button key="1" type="primary">
            <Link to={Pages.TYPE_CREATE}>Создать тип</Link>
          </Button>,
          <Button key="2" type="primary">
            <Link to={Pages.CATEGORY_CREATE}>Создать категорию</Link>
          </Button>,
          <Divider key="devider" type="vertical" />,
          <Button key="generate" type="ghost" onClick={generateDictionary}>
            Сгенерировать словарь
          </Button>,
        ]}
      />
      <div className='page-content'>
        <Tree
          showLine={{ showLeafIcon: false }}
          treeData={dictionaries.protuctTypesDictionary.dictionaryForTree}
          onSelect={handleSelect}
        />
      </div>
    </>
  );
};
