import { ProductStatus } from '../enums/status';

export const statusOptions = [
  { label: ProductStatus.STOCK, value: ProductStatus.STOCK },
  { label: ProductStatus.NOT_AVAILABLE, value: ProductStatus.NOT_AVAILABLE },
  { label: ProductStatus.ORDER, value: ProductStatus.ORDER },
  { label: ProductStatus.NOT_FOUND, value: ProductStatus.NOT_FOUND },
];

export const productStatusFilter = [
  {
    text: ProductStatus.STOCK,
    value: ProductStatus.STOCK,
    color: 'green',
  },
  {
    text: ProductStatus.NOT_AVAILABLE,
    value: ProductStatus.NOT_AVAILABLE,
    color: 'red',
  },
  {
    text: ProductStatus.ORDER,
    value: ProductStatus.ORDER,
    color: 'red',
  },
  {
    text: ProductStatus.NOT_FOUND,
    value: ProductStatus.NOT_FOUND,
    color: 'red',
  },
];
