import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { Button, PageHeader, Table, Tag } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import { useFetchData } from '../../hooks/useFetchData';
import { Spinner } from '../../components/Spinner';
import { orderStatusFilter } from '../../constants/orderStatus';
import { OrderStatus } from '../../enums/orderStatus';
import { ACL } from '../../components/ACL';
import { Permissions } from '../../enums/permissions';
import { getDate, Pages } from '1_shared';

import styles from './OrdersPage.module.scss';

type TableItem = {
  createdAt: string;
  id: string;
  name: string;
  status: string;
  userName: string;
  email: string;
  phone: string;
  comment: string;
  total: number;
};

type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

const columns: Column[] = [
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: TableItem) => {
      const url = `${Pages.ORDERS}/${record.id}`;

      return <Link to={url}>{name}</Link>;
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    filters: orderStatusFilter,
    onFilter: (value: string | number | boolean, record: TableItem) => record.status.includes(value as string),
    render: (roles: string[]) => {
      const props = orderStatusFilter.find((item) => roles.includes(item.value)) || {
        text: OrderStatus.NEW,
        value: OrderStatus.NEW,
        color: 'blue',
      };

      return (
        <Tag color={props.color} key={props.text}>
          {props?.text}
        </Tag>
      );
    },
  },
  {
    title: 'Данные клиента',
    dataIndex: 'userName',
    key: 'userName',
    render: (userName: string, record: TableItem) => {
      return (
        <>
          <span>{userName}</span><br />
          <span>{record.email}</span><br />
          <span>{record.phone}</span><br />
        </>);
    },
  },
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 200,
    render: (createdAt: string) => getDate(createdAt),
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Сумма, руб',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: 150,
    align: 'center',
  },
];

export const OrdersPage: VFC = () => {
  const query = {
    order: ['createdAt DESC'],
  };
  const { isLoading, data = [] } = useFetchData(`/orders?filter=${JSON.stringify(query)}`);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Заказы"
          subTitle="Список заказов"
          extra={[
            <Button key="1" type="primary">
              <Link to={Pages.ORDER_CREATE}>Создать заказ</Link>
            </Button>,
          ]}
        />
        <Table
          className={styles.table}
          columns={columns}
          dataSource={data}
          rowKey="id"
          size='small'
        />
      </>
    </ACL>
  );
};