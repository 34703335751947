import { FC, useState } from 'react';
import { Upload, Image } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import Resizer from 'react-image-file-resizer';

import { apiService, notificationService } from '../../../services';
import { getThumbUrl } from '../../../utils/thumb';

import styles from './UploadImage.module.scss';

type Props = {
  images?: string[];
  onChange?: (v: any) => void;
  onRemove?: (v: any) => void;
  title?: string;
  multiple?: boolean;
  disabled?: boolean;
};

const getInitialImages = (images: string[], title: string) =>
  images?.map((image) => ({
    uid: v4(),
    name: title,
    thumbUrl: getThumbUrl(image),
  }));

const resizeFile: any = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      600,
      'WEBP',
      85,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
    );
  });

export const UploadImage: FC<Props> = ({
  images = [],
  onChange,
  title = '',
  multiple = false,
  onRemove,
  disabled = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const initialImages = getInitialImages(images, title);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);

      return;
    }

    if (info.file.status === 'done') {
      if (onChange && typeof onChange === 'function') {
        onChange(info.file.response);
      }

      setLoading(false);
    }
  };

  const handleUpload = async ({ file, onSuccess }: any) => {
    try {
      const resizedFile = await resizeFile(file);

      const body = new FormData();
      body.append('image', resizedFile);

      const response = await apiService.POST('/upload', body);

      onSuccess(...response);
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  const handleRemove = (response: any): void => {
    if (onRemove && typeof onRemove === 'function') {
      const { thumbUrl, response: res } = response;

      if (res) {
        onRemove(res);
      } else {
        const startIndex = thumbUrl.search('/upload');
        const result = thumbUrl.substring(startIndex, thumbUrl.length);

        onRemove(result);
      }
    }
  };

  const handlePreview = (file: any) => {
    setPreviewUrl(getThumbUrl(file.response) || file.thumbUrl);
  };

  const multipleProps = {
    maxCount: multiple ? 0 : 1,
    multiple,
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      <Upload
        accept="image/*"
        name="image"
        listType="picture-card"
        customRequest={handleUpload}
        onChange={handleChange}
        onRemove={handleRemove}
        defaultFileList={initialImages}
        disabled={isLoading}
        onPreview={handlePreview}
        {...multipleProps}
      >
        {!disabled && (
          <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className={styles.btnText}>Загрузить</div>
          </div>
        )}
      </Upload>
      <div style={{ display: 'none' }}>
        <Image
          preview={{
            visible: !!previewUrl,
            onVisibleChange: () => setPreviewUrl(''),
            src: previewUrl,
          }}
        />
      </div>
    </div>
  );
};
