import { Rule } from 'antd/lib/form';

export const emailRules: Rule[] = [
  { required: true, message: 'Email обязателен' },
  { type: 'email', message: 'Невалидный Email' },
];

export const passwordRules: Rule[] = [
  {
    required: true,
    message: 'Пароль обязателен',
  },
  {
    min: 8,
    message: 'Пароль не может быть меньше 8 символов',
  },
];

export const requiredField: Rule[] = [{ required: true, message: 'Обязательное поле', whitespace: true }];

export const requiredNumberField: Rule[] = [{ required: true, message: 'Обязательное поле' }];

export const requiredBooleanField: Rule[] = [{ required: true, message: 'Обязательное поле' }];

export const requiredArrayField: Rule[] = [{ required: true, type: 'array', message: 'Обязательное поле' }];

export const numberField: Rule[] = [
  { 
    required: true,
    type: 'number', 
    min: 0, 
    message: 'Может быть только числом',
  },
];

export const positiveNumberField: Rule[] = [
  { 
    required: true,
    type: 'number', 
    min: 1, 
    message: 'Может быть больше 0',
  },
];

export const introtextField: Rule[] = [
  { 
    type: 'string', 
    max: 100, 
    message: 'Максимальная длина поля 100 символом',
  },
];