import { Form, PageHeader } from 'antd';
import { useParams } from 'react-router-dom';

import { commonVerticalLayout } from '../../../constants/formLayout';
import { userService } from '../../../services';
import { Spinner } from '../../../components/Spinner';
import { useFetchData } from '../../../hooks/useFetchData';
import { ParsingCarTabs } from '../../../components/Tabs/ParsingCarTabs';
import { Permissions } from '../../../enums/permissions';
import { ParsingCarCancelButton, ParsingCarDeleteButton, ParsingCarSaveButton } from '2_entities';

export const ParsingCarsEditPage = () => {
  const param: any = useParams();
  const [form] = Form.useForm();

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/parsing-cars/${param.id}`);

  const isNew: boolean = !param.id;

  const subTitle: string = isNew ? 'Создание' : 'Редактирование';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        className="page-header"
        title="Автомобили в разборе"
        subTitle={subTitle}
        extra={[
          <ParsingCarDeleteButton key='delete-button' />,
          <ParsingCarCancelButton key='cancel-button' />,
          <ParsingCarSaveButton key='save-button' form={form} />,
        ]}
      />
      <div className='page-content'>
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={{ published: true, price: 0, ...data }}
          layout="vertical"
          {...commonVerticalLayout}
          disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
        >
          <ParsingCarTabs generalTabProps={{ data, form }} />
        </Form>
      </div>
    </>
  );
};
