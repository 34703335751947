import { FC } from 'react';
import { Tabs, FormInstance } from 'antd';
import { SeoTab } from '../SeoTab';
import { ServicesGeneralTab } from './ServicesGeneralTab';
import { ImagesVideoTab } from '../ImagesVideoTab';

type Props = {
  generalTabProps: {
    data: any;
    form: FormInstance;
  }
};

export const ServicesTabs: FC<Props> = ({ generalTabProps }) => {
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      tabBarGutter={5}
      items={[
        {
          key: 'generalTab',
          label: 'Основное',
          children: <ServicesGeneralTab {...generalTabProps} />,
        },
        {
          key: 'imagesVideoTab',
          label: 'Изображениe',
          children: <ImagesVideoTab {...generalTabProps} hasImageGallery={false} />,
        },
        {
          key: 'seoTab',
          label: 'SEO',
          children: <SeoTab />,
        },
      ]}
    />
  );
};