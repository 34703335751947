import { BaseDictionaryStore } from './BaseDictionaryStore';
import { mapToTreeFromData } from '../utils/tree';
import { sortByTitle } from '../utils/sort';

export class ProtuctTypesDictionary extends BaseDictionaryStore {
  name: string = 'product-types';

  types: Array<any> = [];

  categories: Array<any> = [];

  dictionaryForTree: Array<any> = [];

  afterLoad = (data: any): void => {
    this.types.length = 0;
    this.categories.length = 0;
    this.dictionaryForTree.length = 0;

    data.items.forEach((item: any) => {
      this.types.push({ value: item.id, label: item.name, alias: item.alias });

      this.categories.push(...item.items.map((category: any) => ({
        value: category.id,
        label: category.name,
        alias: category.alias,
        parentId: item.id,
      })));

      this.dictionaryForTree.push({
        title: item.name,
        key: item.id,
        children: item.items && item.items.map(mapToTreeFromData).sort(sortByTitle),
      });
    });

    this.dictionaryForTree.sort(sortByTitle);
  };
}