interface IBaseQuery {
  limit: number;
  offset: number;
  order: string[];
}

interface TableQuery {
  page?: number;
  limit?: number;
  order?: string;
}

const getOffset = (page: number, limit: number): number => (page - 1) * limit;

const getBaseQuery = ({ page = 1, limit = 10, order }: TableQuery, baseOrder: string[]): IBaseQuery => ({
  limit: Number(limit),
  offset: getOffset(page, limit),
  order: order ? [order].flat() : baseOrder,
});

export {
  getBaseQuery,
};