import { Input, Form } from 'antd';
import { useQuery } from '../../1_shared/hooks/useQuery';
import { getSearchValue } from '../../utils/data';

import styles from './ProductSimpleSearch.module.scss';

const { Search } = Input;

let timeout: ReturnType<typeof setTimeout>;

export const ProductSimpleSearch = (): JSX.Element => {
  const [form] = Form.useForm();
  const { getQuery, setQuery } = useQuery();

  const handleSimpleSearch = (event: any) => {
    const { target: { value } } = event;
    const { limit = 10, order = ['changedAt DESC'] } = getQuery();

    if (!value) {
      setQuery({ limit, page: 1 });

      return;
    }

    if (value.length > 3) {
      if (timeout) {
        clearTimeout(timeout);
      }

      const resultValue = getSearchValue(value);

      timeout = setTimeout(() => setQuery({ 
        limit, 
        page: 1, 
        order, 
        simpleSearchValue: resultValue,
      }), 300);
    }
  };

  const getInitialValue = () => {
    const query = getQuery();

    return { simpleSearchValue: query.simpleSearchValue ?? '' };
  };

  return (
    <Form form={form} initialValues={getInitialValue()}>
      <Form.Item className={styles.search} name="simpleSearchValue">
        <Search
          onChange={handleSimpleSearch}
          placeholder="Поиск по товарам (Наименование, код товара, оригинальный номер)"
          allowClear
        />
      </Form.Item>
    </Form>
  );
};