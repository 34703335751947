import {
  ITableFetchResponse,
  getProducts,
  getProductsCount,
  searchProducts,
} from '1_shared';
import {
  ProductTableType,
  getProductAdvanceSearchQuery,
  getProductParsingCarQuery,
  getProductSimpleSearchQuery,
} from '2_entities';
import { Product, ProductTableQuery } from 'interfaces';
import { isObjectNotEmpty, omitObjectUndefined } from 'utils/common';

const getDefaultProductSearchData = async <T>(query: ProductTableQuery): Promise<ITableFetchResponse<T>> => {
  const { limit, offset, order } = query;

  try {
    const data = await getProducts<Product>({ limit, offset, order });
    const totalData = await getProductsCount();

    return {
      data: data as T[],
      totalCount: totalData.count,
    };
  } catch (error) {
    throw error;
  }
};

export const getProductSimpleSearchData = async <T>(query: ProductTableQuery): Promise<ITableFetchResponse<T>> => {
  const { simpleSearchValue } = query;

  try {
    if (!simpleSearchValue) {
      const defaultTableData = await getDefaultProductSearchData<T>(query);

      return defaultTableData;
    }

    const simpleSearchQuery = getProductSimpleSearchQuery(query);

    const data = await getProducts<Product>(simpleSearchQuery);
    const totalData = await getProductsCount(simpleSearchQuery.where);

    return {
      data: data as T[],
      totalCount: totalData.count,
    };
  } catch (error) {
    throw error;
  }
};

export const getProductAdvanceSearchData = async <T>(query: ProductTableQuery): Promise<ITableFetchResponse<T>> => {
  const {
    searchValue,
    searchType,
    car,
    type,
    condition,
    status,
    boxNumber,
  } = query;

  const body = omitObjectUndefined({
    searchValue,
    searchType,
    car,
    type,
    condition,
    status,
    boxNumber,
  });

  const hasBody = isObjectNotEmpty(body);

  try {
    if (!hasBody) {
      const defaultTableData = await getDefaultProductSearchData<T>(query);

      return defaultTableData;
    }

    const advanceSearchQuery = getProductAdvanceSearchQuery(query);

    const { data = [], total } = await searchProducts<Product>({ ...advanceSearchQuery, ...body });

    return {
      data: data as T[],
      totalCount: total?.count ?? 0,
    };
  } catch (error) {
    throw error;
  }
};

export const getProductsParsingCarTableData = async <T>(query: ProductTableQuery): Promise<ITableFetchResponse<T>> => {
  try {
    if (!query.id) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    
    const parsingCarProductQuery = getProductParsingCarQuery(query);

    const data: Product[] = await getProducts<Product>(parsingCarProductQuery);
    const totalData = await getProductsCount(parsingCarProductQuery.where);

    return {
      data: data as T[],
      totalCount: totalData.count,
    };
  } catch (error) {
    throw error;
  }
};

export const getProductSearchData = async <T>(query: ProductTableQuery, defaultTableType?: string): Promise<ITableFetchResponse<T>> => {
  const {
    tableType = defaultTableType,
  } = query;

  try {
    if (tableType === ProductTableType.SIMPLE) {
      const tableData = await getProductSimpleSearchData<T>(query);

      return tableData;
    }

    if (tableType === ProductTableType.ADVANCE) {
      const tableData = await getProductAdvanceSearchData<T>(query);

      return tableData;
    }

    const defaultTableData = await getDefaultProductSearchData<T>(query);

    return defaultTableData;
  } catch (error) {
    throw error;
  }
};