import { ApiService } from './ApiService';
import { NotificationService } from './NotificationService';
import { UserStore } from '../stores/UserStore';

export class UserService {
  apiService: ApiService;

  notificationService: NotificationService;

  userStore: UserStore;

  constructor({ apiService, notificationService, userStore }: any) {
    this.apiService = apiService;
    this.notificationService = notificationService;
    this.userStore = userStore;
  }

  getUser = async (): Promise<void> => {
    try {
      const user = await this.apiService.GET('/whoAmI');
      
      this.userStore.fillStore(user);
    } catch (error) {
      this.notificationService.notifyError(error);
    }
  };

  hasAccess = (roles: string[]) => {
    const intersection = this.userStore.roles
      .slice()
      .filter(role => roles.includes(role));

    return !!intersection.length;
  };
}