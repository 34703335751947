import { useEffect, useState } from 'react';
import { Table as AtndTable, TablePaginationConfig } from 'antd';
import { ColumnGroupType, ColumnType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { NotificationService } from 'services/NotificationService';
import { useQuery, getBaseQuery, ITableFetchResponse } from '1_shared';

interface ITableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
  action?: string;
}

type Column = ColumnGroupType<any> | ColumnType<any>;

interface ITable {
  columns: Column[];
  fetchTableData: <T>(query: any) => Promise<ITableFetchResponse<T>>;
  notificationService: NotificationService;
  defaultSortOrder: string[];
  rowKey?: string;
  size?: SizeType;
  scroll?: { y?: number | string, x?: number | string };
  rowClassName?: (record: any, index: number) => string;
  bordered?: boolean;
}

// Мапинг сортировок из таблицы от antD на имена стандартные имена
const ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC',
};

const Table = ({
  columns,
  fetchTableData,
  notificationService,
  defaultSortOrder,
  rowKey = 'id',
  size = 'small',
  scroll,
  rowClassName,
  bordered = false,
}: ITable) => {
  const { getQuery, updateQuery } = useQuery();

  const pageQuery: any = getQuery();

  const { limit = 10, page = 1 } = pageQuery;

  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: +page,
      pageSize: +limit ?? 10,
      total: 0,
      position: ['bottomRight'],
    },
  });

  const fetch = async () => {
    try {
      setIsLoading(true);

      const tableQuery = getBaseQuery(pageQuery, defaultSortOrder);

      const { data, totalCount } = await fetchTableData({ ...pageQuery, ...tableQuery });

      setTableData(data);
      setTableParams({
        ...tableParams,
        pagination: {
          pageSize: limit,
          current: +page,
          total: totalCount || data.length,
          position: ['bottomRight'],
        },
      });
    } catch (error) {
      notificationService.notifyError(error);
      setTableData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [JSON.stringify(pageQuery)]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    const { current = 1, pageSize = 10 } = pagination;
    const { columnKey, order: changedOrder } = sorter;

    const newPageQuery = {
      limit: pageSize,
      page: current,
      order: columnKey && changedOrder
        ? [`${columnKey} ${ORDER_MAP[changedOrder]}`]
        : defaultSortOrder,
    };

    updateQuery(newPageQuery);
    // scrollToTop();
  };

  return (
    <AtndTable
      columns={columns}
      dataSource={tableData}
      rowKey={rowKey}
      size={size}
      loading={isLoading}
      pagination={tableParams.pagination}
      // @ts-ignore
      onChange={handleTableChange}
      scroll={scroll}
      rowClassName={rowClassName}
      bordered={bordered}
    />
  );
};

export default Table;