import { FormInstance, Tabs } from 'antd';

import { CarGeneralTab } from './CarGeneralTab';
import { ImagesVideoTab } from '../ImagesVideoTab';

import { SeoTab } from '../SeoTab';

type Props = {
  generalTabProps: { data: any; form: FormInstance };
  withParent?: boolean;
  parentOptions?: Array<any>;
  parentLabel?: string;
};

export const CarTabs = ({
  generalTabProps,
  withParent,
  parentOptions,
  parentLabel,
}: Props) => {
  return (
    <Tabs
      defaultActiveKey="1"
      type="card"
      tabBarGutter={5}
      items={[
        {
          key: 'generalTab',
          label: 'Основное',
          children: <CarGeneralTab
            {...generalTabProps}
            withParent={withParent}
            parentOptions={parentOptions}
            parentLabel={parentLabel}
          />,
        },
        {
          key: 'imageTab',
          label: 'Изображение',
          children: <ImagesVideoTab hasImageGallery={false} {...generalTabProps} />,
        },
        {
          key: 'seoTab',
          label: 'SEO', 
          children:<SeoTab />,
        },
      ]}
    />
  );
};
