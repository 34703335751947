
import { useEffect, useRef } from 'react';
import { InputNumber, InputRef, Form } from 'antd';
import { numberField } from 'constants/formRules';
import { Product } from 'interfaces';
import { notificationService } from 'services';
import { patchProduct } from '1_shared';

import styles from './ProductTableCountCellForm.module.scss';

type Counts = Pick<Product, 'count' | 'additionalCount' | 'secondAdditionalCount'>;

interface IProductTableCountCellForm {
  id: string;
  counts: Counts;
  onSave: (data: Counts) => void;
}

export const ProductTableCountCellForm = ({ id, counts, onSave }: IProductTableCountCellForm): JSX.Element => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);

  const { count, additionalCount, secondAdditionalCount } = counts;

  useEffect(() => {
    inputRef.current!.focus();
  }, [id]);

  const handleSave = async (event: any) => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }

    try {
      const body = await form.validateFields();

      if (
        body.count !== count
        || body.additionalCount !== additionalCount
        || body.secondAdditionalCount !== secondAdditionalCount
      ) {
        await patchProduct(id, body);
        notificationService.notifySuccess('Товар успешно сохранен');
      }
      onSave(body);
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  return (
    <Form
      initialValues={counts}
      form={form}
      requiredMark={false}
      onBlur={handleSave}
      layout="vertical"
    >
      <label className={styles.label}>На Ириновском</label>
      <Form.Item
        name="count"
        rules={numberField}
        className={styles.formItem}
      >
        <InputNumber
          className={styles.inputNumber}
          // @ts-ignore
          ref={inputRef}
          defaultValue={count ?? 0}
          min={0}
        />
      </Form.Item>
      <label className={styles.label}>На Бокситогорской</label>
      <Form.Item
        name="additionalCount"
        rules={numberField}
        className={styles.formItem}
      >
        <InputNumber
          className={styles.inputNumber}
          defaultValue={additionalCount ?? 0}
          min={0}
        />
      </Form.Item>
      <label className={styles.label}>На Придорожной</label>
      <Form.Item
        name="secondAdditionalCount"
        rules={numberField}
        className={styles.formItem}
      >
        <InputNumber
          className={styles.inputNumber}
          defaultValue={secondAdditionalCount ?? 0}
          min={0}
        />
      </Form.Item>
    </Form>
  );
};