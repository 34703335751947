import { Col, Row, Switch, DatePicker } from 'antd';
import { Moment } from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useQuery } from '1_shared';

const { RangePicker } = DatePicker;

const SaleFilters = (): JSX.Element => {
  const { getQuery, setQuery } = useQuery();

  const {
    limit = 10,
    order = ['changedAt DESC'],
    hasStartersAndGenerators,
    startDate,
    endDate,
  } = getQuery();

  const setNewQuery = (options: any) => {
    const newQuery = {
      limit,
      page: 1,
      order,
    };

    if (options.hasStartersAndGenerators) {
      // @ts-ignore
      newQuery.hasStartersAndGenerators = true;
    }

    if (options.startDate && options.endDate) {
      // @ts-ignore
      newQuery.startDate = options.startDate;
      // @ts-ignore
      newQuery.endDate = options.endDate;
    }

    setQuery({ ...newQuery });
  };

  const handleChange = (value: boolean): void => {
    setNewQuery({ hasStartersAndGenerators: value ? true : undefined, startDate, endDate });
  };

  const handleRangeDateChange = (dates: Moment[]): void => {    
    if (!dates) {
      setNewQuery({ hasStartersAndGenerators });
      return;
    }

    const [newStartDate, newEndDate] = dates;

    if (newStartDate && newEndDate) {
      setNewQuery({ 
        hasStartersAndGenerators, 
        startDate: newStartDate.utcOffset(0).startOf('day').toISOString(),
        endDate: newEndDate.utcOffset(0).endOf('day').toISOString(),
      });
    }
  };

  return (
    <Row gutter={16} style={{ margin: '16px 0' }}>
      <Col md={12}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ marginRight: '16px' }}
          checked={hasStartersAndGenerators}
          onChange={handleChange}
        />
        <span>Показать только стартеры и генераторы</span>
      </Col>
      <Col md={12}>
        <RangePicker
          // @ts-ignore
          onChange={handleRangeDateChange}
          format='DD.MM.yyyy'
          showTime={false}
          style={{ width: '300px' }}
        />
      </Col>
    </Row>
  );
};

export default SaleFilters;