import { Pages } from '1_shared';
import { Button } from 'antd';
import { Permissions } from 'enums/permissions';
import { Link } from 'react-router-dom';
import { userService } from 'services';

const SaleCreateButton = (): JSX.Element => {
  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    return <></>;
  }

  return (
    <Button type="primary">
      <Link to={Pages.SALE_CREATE}>
        Провести продажу
      </Link>
    </Button>
  );
};

export default SaleCreateButton;
