import { Layout, Menu } from 'antd';
import {
  ShoppingCartOutlined,
  CarOutlined,
  ApartmentOutlined,
  LaptopOutlined,
  PoweroffOutlined,
  CarFilled,
  UnorderedListOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useRoutes, useNavigate, Link, useLocation } from 'react-router-dom';

import { authService, userService } from './services';
import { Pages } from '1_shared';
import { routes } from './routes';
import { useFetchDictionaries } from './hooks/useFetchDictionaries';
import { LoadingForm } from './components/LoadingForm';
import { Permissions } from './enums/permissions';

import './App.css';

const { Content, Sider } = Layout;

export const App = observer(() => {
  const isDictionaryLoading: boolean = useFetchDictionaries();
  const navigate = useNavigate();
  const pages = useRoutes(routes);
  const location = useLocation();

  if (isDictionaryLoading) {
    return <LoadingForm />;
  }

  const handleMenuItemClick = ({ key }) => {
    navigate(key);
  };

  const selectedKeys = location.pathname
    .split('/')
    .map(item => `/${item}`)
    .slice(1, 2);

  const verticalItems = [
    {
      label: 'Товары',
      key: 'products-label',
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      label: 'Товары',
      key: Pages.PRODUCTS,
      icon: <LaptopOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: 'Обработка товаров',
      key: Pages.GROUP_PRODUCTS,
      icon: <UnorderedListOutlined />,
      onClick: handleMenuItemClick,
    },
    // {
    //   label: 'Блог',
    //   key: Pages.BLOGS,
    //   icon: <BookOutlined />,
    //   onClick: handleMenuItemClick,
    // },
    {
      label: 'Сущности',
      key: 'entities-label',
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      label: 'Типы',
      key: Pages.TYPES,
      icon: <ApartmentOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: 'Авто',
      key: Pages.CARS,
      icon: <CarFilled />,
      onClick: handleMenuItemClick,
    },
    {
      label: '',
      key: 'devider-1',
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      label: 'Продажи',
      key: Pages.SALES,
      icon: <TagsOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: 'Заказы',
      key: Pages.ORDERS,
      icon: <ShoppingCartOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: '',
      key: 'devider-2',
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      label: 'Автосервис',
      key: Pages.CAR_SERVICE,
      icon: <CarOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: 'В разборе',
      key: Pages.PARSING_CARS,
      icon: <CarOutlined />,
      onClick: handleMenuItemClick,
    },
    {
      label: '',
      key: 'devider-3',
      disabled: true,
      style: { cursor: 'default' },
    },
    {
      label: 'Выход',
      key: 'exit',
      icon: <PoweroffOutlined />,
      onClick: authService.logout,
    },
  ];

  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    ['devider-1', Pages.BLOGS, Pages.ORDERS].forEach(key => {
      const index = verticalItems.findIndex(item => item.key === key);

      if (index !== -1) {
        verticalItems.splice(index, 1);
      }
    });
  }

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
      >
        <div className="ant-layout-header logo">
          <Link to={Pages.HOME}>BUMERVOD</Link>
        </div>
        <Menu
          mode="inline"
          theme="dark"
          items={verticalItems}
          selectedKeys={selectedKeys}
        />
      </Sider>
      <Layout>
        <Content>
          <div style={{ padding: '12px 24px', minHeight: '100vh' }}>
            {pages}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
});
