import moment from 'moment';

export const getDate = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleString('ru-RU');
};

export const getMomentDate = (date?: string) => {
  if (!date) {
    return moment();
  }

  return moment(date);
};