import { Tree, PageHeader, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { Pages } from '../../1_shared/constants/pages';
import { useFetchData } from '../../hooks/useFetchData';
import { useState } from 'react';
import { mapToTreeFromData } from '../../utils/tree';

export const PagesPage = () => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const navigate = useNavigate();

  const { isLoading, data = [] } = useFetchData('/pages');

  const handleSelect = (selectedKey: any) => {
    navigate(`${Pages.PAGE}/${selectedKey}`);
  };

  if (!isLoading && data.length) {
    setTreeData(data.map((item: any) => ({
      title: item.name,
      key: item.id,
      children: item.items && item.items.map(mapToTreeFromData),
    })));
  }

  return (
    <>
    <PageHeader
      className="page-header"
      title="Страницы"
      subTitle="Статические страницы"
      extra={[
        <Button key="1" type="primary">
          <Link to={Pages.PAGE_CREATE}>
            Создать страницу
          </Link>
        </Button>,
      ]}
    />
    <div>
      <Tree
        showLine={{ showLeafIcon: false }}
        treeData={treeData}
        onSelect={handleSelect}
      />
    </div>
  </>
  );
};