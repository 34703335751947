import { Card, Col, Row, Statistic } from 'antd';
import { useFetchData } from '../../hooks/useFetchData';
import { ProductStatus } from '../../enums/status';
import { Spinner } from '../../components/Spinner';

export const HomePage = () => {
  const { isLoading: isAllProductsDataLoading, data: allProducts = {} } = useFetchData('/products/count');
  const { isLoading: isStockProductsDataLoading, data: stockProducts = {} } = useFetchData(`/products/count?where=${JSON.stringify({ status: ProductStatus.STOCK })}`);
  const { isLoading: isNotAvailableDataLoading, data: notAvailableProducts = {} } = useFetchData(`/products/count?where=${JSON.stringify({ status: ProductStatus.NOT_AVAILABLE })}`);
  const { isLoading: isOrderProductsDataLoading, data: orderProducts = {} } = useFetchData(`/products/count?where=${JSON.stringify({ status: ProductStatus.ORDER })}`);
  const { isLoading: isNotFoundProductsDataLoading, data: notFoundProducts = {} } = useFetchData(`/products/count?where=${JSON.stringify({ status: ProductStatus.NOT_FOUND })}`);

  if (isAllProductsDataLoading ||
    isStockProductsDataLoading ||
    isNotAvailableDataLoading ||
    isOrderProductsDataLoading ||
    isNotFoundProductsDataLoading) {
    return <Spinner />;
  }

  return (
    <div className="site-statistic-demo-card">
      <Row gutter={[16, 16]} justify='center'>
        <Col xs={24} md={12} xl={4}>
          <Card>
            <Statistic
              title="Всего товаров"
              value={allProducts.count}
              suffix="шт"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={4}>
          <Card>
            <Statistic
              title="Товаров в наличии"
              value={stockProducts.count}
              valueStyle={{ color: 'green' }}
              suffix="шт"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={4}>
          <Card>
            <Statistic
              title="Товаров нет в наличии"
              value={notAvailableProducts.count}
              valueStyle={{ color: 'red' }}
              suffix="шт"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={4}>
          <Card>
            <Statistic
              title="Товаров под заказ"
              value={orderProducts.count}
              valueStyle={{ color: 'red' }}
              suffix="шт"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={4}>
          <Card>
            <Statistic
              title="Товаров не найдено"
              value={notFoundProducts.count}
              valueStyle={{ color: 'red' }}
              suffix="шт"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
