import { useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Popconfirm,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchData } from '../../../../hooks/useFetchData';
import { Spinner } from '../../../../components/Spinner';
import { Pages } from '../../../../1_shared/constants/pages';
import { apiService, notificationService, userService } from '../../../../services';
import { commonVerticalLayout } from '../../../../constants/formLayout';
import { ServicesTabs } from '../../../../components/Tabs/ServicesTabs';
import { Permissions } from '../../../../enums/permissions';

export const ServicesEditPage = () => {
  const navigate = useNavigate();
  const param: any = useParams();
  const [form] = Form.useForm();

  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/services/${param.id}`);

  const isNew: boolean = !param.id;

  const subTitle: string = isNew
    ? 'Создание категории услуги'
    : 'Редактирование категории услуги';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleCancel = () => {
    navigate(Pages.SERVICES);
  };

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (isNew) {
        const requestedData = await apiService.POST('/services', body);

        navigate(`${Pages.SERVICES}/${requestedData.id}`);
      } else {
        await apiService.PATCH(`/services/${data.id}`, body);
      }
      notificationService.notifySuccess('Услуга успешно сохранена');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSaveBtnDisabled(true);
      await apiService.DELETE(`/services/${data.id}`);
      navigate(Pages.SERVICES);
      notificationService.notifySuccess('Услуга успешно удалена');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  return (
    <>
      <PageHeader
        className="page-header"
        title="Услуги"
        subTitle={subTitle}
        extra={[
          <Popconfirm
            key='delete-btn'
            title="Вы действительно хотите удалить услугу?"
            onConfirm={handleDelete}
            disabled={isNew || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              disabled={isNew || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
              danger>
              Удалить
            </Button>
          </Popconfirm>,
          <Button key="2" onClick={handleCancel}>
            {isNew ? 'Отмена' : 'Закрыть'}
          </Button>,
          <Button
            key="1"
            type="primary"
            disabled={isSaveBtnDisabled || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            onClick={handleSave}
          >
            Сохранить
          </Button>,
        ]}
      />
      <div className='page-content'>
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={data}
          layout="vertical"
          {...commonVerticalLayout}
          disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
        >
          <ServicesTabs generalTabProps={{ data, form }} />
        </Form>
      </div>
    </>
  );
};
