export const placeOptions = [
  {
    label: 'Ириновский',
    value: 'count',
  },
  {
    label: 'Богситогорская',
    value: 'additionalCount',
  },
  {
    label: 'Придорожная',
    value: 'secondAdditionalCount',
  },
];