import { Pages } from '1_shared';
import { IParsingCar, createParsingCar, patchParsingCar } from '2_entities';
import { Button, FormInstance } from 'antd';
import { Permissions } from 'enums/permissions';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationService, userService } from 'services';

interface IParsingCarSaveButton {
  form: FormInstance;
}

const ParsingCarSaveButton = ({ form }: IParsingCarSaveButton): JSX.Element => {
  const navigate = useNavigate();
  const param: any = useParams();

  const [isDisabled, setDisabled] = useState<boolean>(false);

  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    return <></>;
  }

  const handleSave = async () => {
    try {
      setDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (!param.id) {
        const createdParsingCar: IParsingCar = await createParsingCar<IParsingCar>(body);

        navigate(`${Pages.PARSING_CARS}/${createdParsingCar.id}`);
      } else {
        await patchParsingCar(param.id, body);
      }
      notificationService.notifySuccess('Запись успешно сохранена');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      type="primary"
      disabled={isDisabled}
      onClick={handleSave}
    >
      Сохранить
    </Button>
  );
};

export default ParsingCarSaveButton;