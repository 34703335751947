import logo from '../../logo.svg';

export const LoadingForm = () => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>Загрузка данных для работы</p>
    </header>
  </div>
);
