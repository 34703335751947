import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, PageHeader, Row } from 'antd';
import { Pages } from '../../1_shared/constants/pages';

export const CarServicePage: VFC = () => (
  <>
    <PageHeader
      className="page-header"
      title="Автосервис"
    />
    <Row gutter={[16, 16]} className="mg-t-16">
      <Col span={8}>
        <Link to={Pages.SERVICES}>
          <Card
            title="Услуги"
            bordered
            hoverable
          >
            Раздел, где можно создать, посмотреть, отредактировать услуги предоставляемые автосервисом
          </Card>
        </Link>
      </Col>
      <Col span={8}>
        <Link to={Pages.COMPLETED_WORKS}>
          <Card title="Выполненные работы" bordered hoverable>
            Раздел, где можно создать и посмотреть выполненные автосервисом работы
          </Card>
        </Link>
      </Col>
      <Col span={8}>
        <Card title="Заказ наряды" bordered style={{ color: 'grey' }}>
          Раздел, где можно создать и посмотреть заказ наряды
        </Card>
      </Col>
      <Col span={8}>
        <Link to={Pages.CAR_SERVICE_ORDERS}>
          <Card title="Онлайн записи" bordered hoverable>
            Раздел, где можно посмотреть олнайн записи на автосервис
          </Card>
        </Link>
      </Col>
    </Row>
  </>
);

