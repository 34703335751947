import { Form, Input, FormInstance, Select, Checkbox } from 'antd';
import { RichTextEditor } from 'components/RichTextEditor';
import { avitoCategories, avitoTypes } from '1_shared';

interface IAvitoTab {
  data: any;
  form: FormInstance;
}

const AvitoTab = ({ data }: IAvitoTab): JSX.Element => {
  const { avitoData = {} } = data;
  const { avitoDescription = '' } = avitoData;

  return (
    <>
      <Form.Item name="avitoExported" valuePropName="checked">
        <Checkbox>Выгружать на авито</Checkbox>
      </Form.Item>
      <Form.Item
        name={['avitoData', 'avitoTitle']}
        label="Заголовок для Авито"
      >
        <Input maxLength={50} />
      </Form.Item>
      <Form.Item
        name={['avitoData', 'avitoCategory']}
        label="Категория на Авито"
      >
        <Select
          optionFilterProp="label"
          options={avitoCategories}
          allowClear
          showSearch
        />
      </Form.Item>
      <Form.Item
        name={['avitoData', 'avitoTypeId']}
        label="Тип товара на Авито"
      >
        <Select
          optionFilterProp="label"
          options={avitoTypes}
          allowClear
          showSearch
        />
      </Form.Item>
      <Form.Item
        name={['avitoData', 'avitoId']}
        label="Авито ID (Внутренний авито айдишник товара. Если объявление создано в ручную)"
      >
        <Input />
      </Form.Item>
      <div>
        <h3>Дополнительное описание для авито</h3>
        <Form.Item name={['avitoData', 'avitoDescription']} noStyle>
          <RichTextEditor initialValue={avitoDescription} />
        </Form.Item>
      </div>
    </>
  );
};

export default AvitoTab;