import { VFC } from 'react';
import { Button, Form, FormInstance, InputNumber, Select } from 'antd';
import { OPERATION_TYPE_OPTIONS, TYPES } from '../../constants/operationTypeOptions';
import { placeOptions } from '../../constants/placeOptions';
import { numberField, positiveNumberField, requiredField } from '../../constants/formRules';
import { statusOptions } from '../../constants/statusOptions';
import { MathOperations } from '../../enums/mathOperations';

interface GroupProductsFormProps {
  onSubmit: () => void;
  form: FormInstance;
  isDisabled?: boolean;
}

export const GroupProductsForm: VFC<GroupProductsFormProps> = ({ form, onSubmit, isDisabled }) => {
  return (
    <Form
      form={form}
      style={{ marginBottom: 20 }}
      layout="inline"
    >
      {(values) => {
        return (
          <>
            <Form.Item name="operationType" label="Тип операции">
              <Select
                style={{ width: 220 }}
                options={OPERATION_TYPE_OPTIONS}
              />
            </Form.Item>
            {values.operationType === TYPES.move && (
              <>
                <Form.Item name={[TYPES.move, 'current']} label="Текущее место" rules={requiredField}>
                  <Select
                    style={{ width: 220 }}
                    options={placeOptions}
                  />
                </Form.Item>
                <Form.Item name={[TYPES.move, 'target']} label="Требуемое место" rules={requiredField}>
                  <Select
                    style={{ width: 220 }}
                    options={placeOptions}
                  />
                </Form.Item>
              </>
            )}
            {values.operationType === TYPES.changePrice && (
              <>
                <Form.Item name={[TYPES.changePrice, 'mathOperation']} label="Что сделать" rules={requiredField}>
                  <Select
                    style={{ width: 220 }}
                    options={[
                      {
                        label: 'Умножить на',
                        value: MathOperations.MULTIPLY,
                      },
                      {
                        label: 'Разделить на',
                        value: MathOperations.DIVIDE,
                      },
                      {
                        label: 'Прибавить',
                        value: MathOperations.ADD,
                      },
                      {
                        label: 'Вычесть',
                        value: MathOperations.SUBTRACT,
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name={[TYPES.changePrice, 'value']}
                  label="Значение"
                  rules={values[TYPES.changePrice]?.mathOperation === MathOperations.DIVIDE
                    ? positiveNumberField
                    : numberField
                  }
                  initialValue={0}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </>
            )}
            {values.operationType === TYPES.changeStatus && (
              <>
                <Form.Item name={[TYPES.changeStatus, 'status']} label="Статус" rules={requiredField}>
                  <Select
                    style={{ width: 220 }}
                    options={statusOptions}
                  />
                </Form.Item>
              </>
            )}
            {values.operationType === TYPES.changePublished && (
              <>
                <Form.Item name={[TYPES.changePublished, 'published']} label="Статус публикации" rules={requiredField}>
                  <Select
                    style={{ width: 220 }}
                    options={[
                      {
                        label: 'Опубликован',
                        value: 'true',
                      },
                      {
                        label: 'Не опубликован',
                        value: 'false',
                      },
                    ]}
                  />
                </Form.Item>
              </>
            )}
            <Button
              onClick={onSubmit}
              type="primary"
              htmlType="submit"
              disabled={isDisabled}
            >
              Выполнить
            </Button>
          </>
        );
      }}

    </Form>
  );
};