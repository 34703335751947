import Cookies from 'js-cookie';

import { ApiService } from './ApiService';
import { AuthService } from './AuthService';
import { NotificationService } from './NotificationService';
import { UserService } from './UserService';

import { userStore } from '../stores';

export const notificationService = new NotificationService();
export const apiService = new ApiService({ cookies: Cookies, notificationService });
export const authService = new AuthService({ notificationService, apiService, cookies: Cookies });

export const userService = new UserService({ apiService, notificationService, userStore });