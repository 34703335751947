import { useEffect, useState } from 'react';
import { PageHeader, Form, Progress } from 'antd';

import { apiService, notificationService } from '../../services';
import { useQuery } from '../../1_shared/hooks/useQuery';
import { ProductAdvanceSearch, ProductGroupTableColumns } from '4_widgets';
import { AnyObject, Product, ProductTableQuery } from '../../interfaces';
import { isObjectNotEmpty, omitObjectUndefined } from '../../utils/common';
import { VirtualTable } from '../../components/VirtualTable';
import { GroupProductsForm } from '../../components/GroupProductsForm';
import { TYPES } from '../../constants/operationTypeOptions';
import { changePrice, changePublished, changeStatus, moveProduct } from '../../utils/data';
import { Permissions } from '../../enums/permissions';
import { ACL } from '../../components/ACL';

const TYPES_OPERATION_PROCESSING = {
  [TYPES.move]: moveProduct,
  [TYPES.changePrice]: changePrice,
  [TYPES.changePublished]: changePublished,
  [TYPES.changeStatus]: changeStatus,
};

export const GroupProductsPage = () => {
  const [form] = Form.useForm();
  const { getQuery } = useQuery();

  const pageQuery: ProductTableQuery = getQuery();
  const {
    order,
    searchValue,
    searchType,
    car,
    type,
    condition,
    status,
    boxNumber,
  } = pageQuery;

  const [tableData, setTableData] = useState<Partial<Product>[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>(new Map());
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);

  const fetchTableData = async () => {
    setIsLoading(true);

    const requestQuery = {
      order: order ? [order] : ['changedAt DESC'],
    };

    const body = omitObjectUndefined({
      searchValue,
      searchType,
      car,
      type,
      condition,
      status,
      boxNumber,
    });

    const hasBody = isObjectNotEmpty(body);

    try {
      let data = [];

      if (hasBody) {
        const response = await apiService.PUT('/products/search', { ...requestQuery, ...body });
        data = response.data;
      }

      setTableData(data);
      setSelectedRows(new Map());
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [JSON.stringify(pageQuery)]);

  const handleCheck = (key: string, value: AnyObject) => {
    if (selectedRows.has(key)) {
      selectedRows.delete(key);
      setSelectedRows(new Map(selectedRows));
    } else {
      setSelectedRows(new Map(selectedRows.set(key, value)));
    }
  };

  const handleAllCheck = () => {
    if (selectedRows.size === tableData.length) {
      selectedRows.clear();
    } else {
      tableData.forEach(item => {
        selectedRows.set(item.id, item);
      });
    }

    setSelectedRows(new Map(selectedRows));
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const values = form.getFieldsValue();
      const operationType = values.operationType;

      setProcessing(true);
      const percent1 = Math.floor(selectedRows.size / 100);
      let count = 0;

      for (let product of selectedRows.values()) {
        const newProduct = TYPES_OPERATION_PROCESSING[operationType](product, values[operationType]);
        await apiService.PATCH(`/products/${product.id}`, newProduct);
        count += 1;

        if (count % percent1 === 0 || count === selectedRows.size) {
          setPercent(currentValue => currentValue + 1);
        }
      }

      setSelectedRows(new Map());
      await fetchTableData();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Фильтр товаров"
        />
        <ProductAdvanceSearch />
        <GroupProductsForm
          form={form}
          onSubmit={handleSubmit}
          isDisabled={isProcessing || !form.isFieldsTouched() || !selectedRows.size}
        />
        {isProcessing && (
          <div style={{ textAlign: 'center' }}>
            <h3>Операция выполняется, пожалуйста не закрывайте страницу</h3>
            <Progress type="circle" percent={percent} />
          </div>
        )}
        <div>Товаров в списке: {tableData.length}</div>
        <div>Выбрано товаров: {selectedRows.size}</div>
        <VirtualTable
          columns={ProductGroupTableColumns as any}
          dataSource={tableData}
          scroll={{ y: 500, x: 'false' }}
          loading={isLoading}
          rowKey="id"
          size='small'
          bordered
          // @ts-ignore
          onCheck={handleCheck}
          onCheckAll={handleAllCheck}
          selectedRows={selectedRows}
        />
      </>
    </ACL>
  );
};
