import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { Button, PageHeader, Table, Tag } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import { Pages } from '../../../1_shared/constants/pages';
import { useFetchData } from '../../../hooks/useFetchData';
import { Spinner } from '../../../components/Spinner';
import { ServiceOrderStatus } from '../../../enums/serviceOrderStatus';
import { serviceOrderStatusFilter } from '../../../constants/serviceOrderStatus';

import styles from './ServiceOrdersPage.module.scss';

type TableItem = {
  id: string;
  status: string;
  userName: string;
  date: string;
  phone: string;
  comment: string;
};

type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

const columns: Column[] = [
  {
    title: 'ФИО клиента',
    dataIndex: 'userName',
    key: 'userName',
    render: (userName: string, record: TableItem) => {
      return userName;
      const url = `${Pages.ORDERS}/${record.id}`;

      return <Link to={url}>{name}</Link>;
    },
    width: 350,
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    width: 170,
  },
  {
    title: 'Желаемая дата',
    dataIndex: 'date',
    key: 'date',
    width: 130,
    align: 'center',
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    filters: serviceOrderStatusFilter,
    onFilter: (value: string | number | boolean, record: TableItem) => record.status.includes(value as string),
    render: (status: string) => {
      const props = serviceOrderStatusFilter.find((item) => status === item.value) || {
        text: ServiceOrderStatus.NEW,
        value: ServiceOrderStatus.NEW,
        color: 'blue',
      };

      return (
        <Tag color={props.color} key={props.text}>
          {props?.text}
        </Tag>
      );
    },
    width: 200,
  },
];

export const ServiceOrdersPage: VFC = () => {
  const { isLoading, data = [] } = useFetchData('/service-orders');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        className="page-header"
        title="Онлайн записи"
        subTitle="Список записей"
      />
      <Table
        className={styles.table}
        columns={columns}
        dataSource={data}
        rowKey="id"
        size='small'
      />
    </>
  );
};