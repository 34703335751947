import { FC } from 'react';
import classNames from 'classnames';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  FormInstance,
  Button,
} from 'antd';

import { requiredField } from '../../../../constants/formRules';
import { RichTextEditor } from '../../../RichTextEditor';
import { translit } from '../../../../utils/translit';

import styles from './ServicesGeneralTab.module.scss';

type Props = {
  data: any;
  form: FormInstance;
};

export const ServicesGeneralTab: FC<Props> = ({ data, form }) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  return (
    <>
      <Form.Item
        name="name"
        label="Наименование категории услуг*"
        rules={requiredField}
      >
        <Input onChange={handleNameChange} />
      </Form.Item>
      <Form.Item name="alias" label="Url псевдоним*" rules={requiredField}>
        <Input />
      </Form.Item>
      <div className={styles.dinamicInputContainer}>
        <div className={styles.title}>
          <span>Прайс лист услуг</span>
        </div>
        <Form.List name="prices">
          {(fields, { add, remove }) => (
            <>
              <div className={classNames({ [styles.pricesTable]: !!fields.length })}>
                {fields.length > 0 && (
                  <>
                    <div className='mg-b-16'><i>Наименование</i></div>
                    <div><i>Прайс для легковых авто</i></div>
                    <div><i>Прайс для внедорожников</i></div>
                    <div></div>
                  </>
                )}
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      key={`${key}-name`}
                      name={[name, 'name']}
                    >
                      <Input placeholder="Наименование" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      key={`${key}-regular`}
                      name={[name, 'regular']}
                    >
                      <Input placeholder="от 1000 руб" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      key={`${key}-suv`}
                      name={[name, 'suv']}
                    >
                      <Input placeholder="от 1000 руб" />
                    </Form.Item>
                    <MinusCircleOutlined key={`${key}-minus`} onClick={() => remove(name)} />
                  </>
                ))}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Добавить прайс
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <div>
        <span>Описание</span>
        <Form.Item name="content" label="Контент" noStyle>
          <RichTextEditor initialValue={data.content} />
        </Form.Item>
      </div>
    </>
  );
};