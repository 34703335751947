import { Button, Col, PageHeader, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ItemCard } from '../../../components/ItemCard';
import { Spinner } from '../../../components/Spinner';
import { Permissions } from '../../../enums/permissions';
import { useFetchData } from '../../../hooks/useFetchData';
import { Item } from '../../../interfaces/item';
import { userService } from '../../../services';
import { Pages } from '../../../1_shared/constants/pages';

import styles from './CompletedWorksPage.module.scss';

export const CompletedWorksPage = () => {
  const query = {
    order: ['createdAt DESC'],
  };
  const { isLoading, data = [] } = useFetchData(`/completed-works?filter=${JSON.stringify(query)}`);
  const navigate = useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  const handleCreate = () => navigate(Pages.COMPLETED_WORK_CREATE);

  return (
    <>
      <PageHeader
        className="page-header"
        title="Выполненные работы"
        extra={userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) && [
          <Button
            key="1"
            type="primary"
            onClick={handleCreate}
          >
            Добавить
          </Button>,
        ]}
      />
      <Row className={styles.container} gutter={[16, 16]}>
        {data.map((completedWork: Item) => (
          <Col xs={24} md={12} xl={6} key={completedWork.id}>
            <ItemCard
              item={completedWork}
              basePath={Pages.COMPLETED_WORKS}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};