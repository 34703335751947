import { FC } from 'react';
import { Form, Input, FormInstance, Row, Col, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { RichTextEditor } from '../../RichTextEditor';

type Props = {
  data: any;
  form: FormInstance;
};

export const ManagerTab: FC<Props> = ({ data }) => {
  return (
    <>
      <h3>Места на складе</h3>

      <Form.List name="locations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={28} key={key} align='middle'>
                <Col lg={1} md={1} xs={1}>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Col>
                <Col lg={5} md={5} xs={9}>
                  <Form.Item
                    {...restField}
                    name={[name, 'row']}
                    label="Ряд"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={5} md={5} xs={12}>
                  <Form.Item
                    {...restField}
                    name={[name, 'rack']}
                    label="Стеллаж"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={5} md={5} xs={12}>
                  <Form.Item
                    {...restField}
                    name={[name, 'section']}
                    label="Секция"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={5} md={5} xs={12}>
                  <Form.Item
                    {...restField}
                    name={[name, 'boxNumber']}
                    label="№ Ящика"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            ))}


            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <div>
        <h3>Коментарий менеджера</h3>
        <Form.Item name="comment" noStyle>
          <RichTextEditor initialValue={data.comment} />
        </Form.Item>
      </div>
    </>
  );
};
