import { Cookies } from '../interfaces';
import { NotificationService } from './NotificationService';

type Params = {
  body: any;
  method: string;
  headers: any
};

export class ApiService {
  getBasePath = (): string | undefined => process.env.REACT_APP_API_BASE_PATH;

  baseFilePath: string = process.env.REACT_APP_FILE_BASE_PATH || '';

  cookies: Cookies;

  notificationService: NotificationService;

  constructor({ cookies, notificationService }: any) {
    this.cookies = cookies;
    this.notificationService = notificationService;
  }

  private fetch = async (endpoint: string, { body, method = 'GET', headers = {} }: Params) => {
    const data = {
      headers: {
        ...headers,
      },
      body,
      method,
    };

    if (body && !(body instanceof FormData)) {
      data.headers['Content-Type'] = 'application/json;charset=UTF-8';
      data.body = JSON.stringify(body);
    }

    const token = this.cookies.get('HttpAutorization');

    if (token) {
      data.headers.Authorization = `Bearer ${token}`;
    }

    const baseUrl = this.getBasePath();

    try {
      const url = baseUrl + endpoint;
      const response = await fetch(url, data);
      const text = await response.text();
      const result = text ? JSON.parse(text) : {};      

      if (result?.error?.statusCode > 300) {
        if (result?.error.statusCode === 401) {
          this.cookies.remove('HttpAutorization');
          document.location.reload();
        }
        return await Promise.reject(result.error);
      }

      return result;
    } catch (e) {
      console.error(e);
      this.notificationService.notifyError(e);
      throw e;
    }
  };

  POST = (endpoint: string, body: any, option?: any) =>
    this.fetch(endpoint, { method: 'POST', body, ...option });

  PUT = (endpoint: string, body: any, option?: any) =>
    this.fetch(endpoint, { method: 'PUT', body, ...option });

  PATCH = (endpoint: string, body: any, option?: any) =>
    this.fetch(endpoint, { method: 'PATCH', body, ...option });

  GET = (endpoint: string, option?: any) =>
    this.fetch(endpoint, { method: 'GET', ...option });

  DELETE = (endpoint: string, option?: any) =>
    this.fetch(endpoint, { method: 'DELETE', ...option });
}