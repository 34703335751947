import { Form, Button, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { requiredField } from '../../constants/formRules';

import styles from './DinamicInput.module.scss';

type Props = {
  formName: string | string[];
  title: string;
  btnText: string;
  placeholder?: string;
};

export const DinamicInput = ({
  formName,
  title,
  btnText,
  placeholder,
}: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{title}</span>
      </div>
      <Form.List name={formName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex' }} align="baseline">
                <Form.Item
                  {...restField}
                  name={name}
                  rules={requiredField}
                  style={{ width: '300px' }}
                >
                  <Input placeholder={placeholder} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                {btnText}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
