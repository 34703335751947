import { BaseDictionaryStore } from './BaseDictionaryStore';

export class ServicesDictionary extends BaseDictionaryStore {
  fetch = async () => {
    try {
      const query = { order: ['name ASC'] };

      const data: any = await this.apiService.GET(`/services?filter=${JSON.stringify(query)}`);

      if (data) {
        this.items = data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      }
    } catch (error) {
      this.notificationService.notifyError(error);
    }
  };
}