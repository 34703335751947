import { PageHeader } from 'antd';
import { notificationService } from '../../services';
import { Table } from '1_shared';
import { ParsingCarCreateButton, getParsingCarsWithProducts } from '2_entities';
import { SearchParsingCars } from '3_features';
import { ParsingCarTableColumns } from '4_widgets';

export const ParsingCarsPage = (): JSX.Element => (
  <>
    <PageHeader
      className="page-header"
      title="Автомобили в разборе"
      extra={[
        <ParsingCarCreateButton key="create-button" />,
      ]}
    />
    <div style={{ marginTop: '1em' }}>
      <SearchParsingCars />
      <Table
        columns={ParsingCarTableColumns}
        fetchTableData={getParsingCarsWithProducts}
        defaultSortOrder={['name ASC']}
        notificationService={notificationService}
        scroll={{ y: 750 }}
      />
    </div>
  </>
);