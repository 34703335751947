import { useSearchParams } from 'react-router-dom';
import { AnyObject } from '../../interfaces';

export const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQuery = (query: any) => {
    setSearchParams(query);
  };

  const clearQuery = () => {
    setQuery({});
  };

  const getQuery = (): AnyObject => {
    const result = {};

    for (const entry of searchParams.entries()) {
      const [param, value] = entry;

      if (value !== undefined) {
        result[param] = value;
      }
    }

    return result;
  };

  const updateQuery = (query: any) => {
    const currentQuery = getQuery();
    if (currentQuery) {
      setSearchParams({ ...currentQuery, ...query });
    } else {
      setSearchParams(query);
    }
  };

  const removeFromQuery = (name: string) => {
    const currentQuery = getQuery();

    if (currentQuery) {
      delete currentQuery[name];
      setSearchParams(currentQuery);
    }

    return currentQuery;
  };

  return {
    getQuery,
    setQuery,
    clearQuery,
    updateQuery,
    removeFromQuery,
  };
};