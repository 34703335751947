import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { Pages } from '1_shared';

const ParsingCarCancelButton = (): JSX.Element => {
  const navigate = useNavigate();
  const param = useParams();

  const handleCancel = () => {
    navigate(Pages.PARSING_CARS);
  };

  return (
    <Button key="2" onClick={handleCancel}>
      {param.id ? 'Закрыть' : 'Отмена'}
    </Button>
  );
};

export default ParsingCarCancelButton;