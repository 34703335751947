import { IProduct, ISaleProduct } from '1_shared';
import { ProductTableQuery } from 'interfaces';

export const getProductSimpleSearchQuery = ({
  limit,
  offset,
  order,
  simpleSearchValue,
}: Partial<ProductTableQuery>) => {
  const where = {
    or: [
      { name: { like: simpleSearchValue, options: 'i' } },
      { comment: { like: simpleSearchValue, options: 'i' } },
      { code: simpleSearchValue },
      { originalNumbers: { like: simpleSearchValue, options: 'i' } },
      { oldOriginalNumbers: { like: simpleSearchValue, options: 'i' } },
    ],
  };

  return {
    limit,
    offset,
    order,
    where,
  };
};

export const getProductParsingCarQuery = ({
  limit,
  offset,
  order,
  id,
}: Partial<ProductTableQuery>) => ({
  limit,
  offset,
  order,
  where: {
    sparePartFrom: id,
  },
});

export const getProductAdvanceSearchQuery = ({
  limit,
  offset,
  order,
}: Partial<ProductTableQuery>) => ({
  limit,
  offset,
  order,
});

export const getProductDescription = (product: IProduct | ISaleProduct): string => {
  const { code, originalNumbers } = product;

  const productCode = code ? `Код товара: ${code}. ` : '';
  const originalNumber = originalNumbers?.length ? `Оригинальный номер ${originalNumbers.join(', ')}. ` : '';

  let description = `${productCode}${originalNumber}`;

  return description;
};

export const getSaleProduct = (product: IProduct): ISaleProduct => {
  const {
    id,
    name,
    image = '',
    gallery = [],
    code,
    originalNumbers = [],
    price = 0,
    count = 0,
    additionalCount = 0,
    secondAdditionalCount = 0,
    condition,
    types = [],
  } = product;

  return {
    id,
    name,
    image,
    gallery,
    code,
    originalNumbers,
    condition,
    price,
    count,
    additionalCount,
    secondAdditionalCount,
    salePrice: price,
    saleCount: 0,
    saleAdditionalCount: 0,
    saleSecondAdditionalCount: 0,
    types,
  };
};