import { useState } from 'react';
import { Alert, Button, Checkbox, DatePicker, Form, Input, Modal, Select } from 'antd';
import { Permissions } from 'enums/permissions';
import { notificationService, userService } from 'services';
import { IRefund, ISaleManualProduct, ISaleProduct, getMomentDate } from '1_shared';
import { useFetchData } from 'hooks/useFetchData';
import { commonVerticalLayout } from 'constants/formLayout';
import { requiredArrayField } from 'constants/formRules';
import { updateSale } from '2_entities';

interface IRefundCreateButton {
  id?: string
}

const RefundCreateButton = ({ id }: IRefundCreateButton): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [form] = Form.useForm();

  const { isLoading, data = {} } = useFetchData(`/sales/${id}`);

  if (!id || !data?.products?.length || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    return <></>;
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    form.resetFields();
  };

  const saveSale = async () => {
    try {
      await form.validateFields();

      const formValues = form.getFieldsValue();
      if (formValues.createdAt) {
        formValues.createdAt = formValues.createdAt.format();
      }
      formValues.products = formValues.products.map((productIndex: number) => {
        return data.products[productIndex];
      });

      const { refunds = [] } = data;
      refunds.push(formValues);

      await updateSale({ refunds }, id);

      notificationService.notifySuccess('Возврат успешно проведен');

      setIsModalOpen(false);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const { products = [], refunds = [] } = data;

  const productsOptions = products
    .filter(({ id: producId, name }) => !refunds.some((refund: IRefund) =>
      refund.products.some((refundedProduct) =>
        refundedProduct.id === producId || refundedProduct.name === name)))
    .map((product: ISaleManualProduct | ISaleProduct, index: number) => ({
      label: `${product.code && `(Код товара: ${product.code}) `}${product.name}`,
      value: index,
    }));

  return (
    <>
      <Button type="dashed" onClick={toggleModal}>
        Произвести возврат
      </Button>
      <Modal
        title="Произвести возврат товаров"
        open={isModalOpen}
        onOk={saveSale}
        okText="Произвести возврат"
        okButtonProps={{
          disabled: isLoading,
        }}
        onCancel={toggleModal}
        confirmLoading={isSaving}
        width={800}
      >
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={{
            products: [],
            reason: '',
            returnToStore: true,
            createdAt: getMomentDate(data.createdAt),
          }}
          layout="vertical"
          {...commonVerticalLayout}
          disabled={isLoading}
        >
          <Form.Item name="products" label="Товары к возврату" rules={requiredArrayField}>
            <Select
              optionFilterProp="label"
              mode="multiple"
              placeholder="Выберите товары из списка"
              allowClear
              options={productsOptions}
            />
          </Form.Item>
          <Form.Item name="reason" label="Причина возврата">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name='createdAt'
            label="Дата возврата"
          >
            <DatePicker format='D.M.yyyy' />
          </Form.Item>
          <Form.Item name="returnToStore" valuePropName="checked">
            <Checkbox>Восстановить выбранные товары на сайте?</Checkbox>
          </Form.Item>
          <Alert message="Восстановлены будут только существующие на сайте товары" type="info" showIcon />
        </Form>
      </Modal>
    </>
  );
};

export default RefundCreateButton;
