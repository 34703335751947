import { useState } from 'react';
import { Button, Modal, DatePicker, Space, Switch, Alert, Select } from 'antd';
import { Moment } from 'moment';
import { notificationService } from 'services';
import { ISale, SaleReportPdfCreator, getCsvSaleOrderData, getSales } from '2_entities';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { downloadCsv } from '1_shared';

const { RangePicker } = DatePicker;

const CreateSalesReportModal = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportCreating, setIsReportCreating] = useState(false);
  const [filename, setFilename] = useState('Отчет продаж');
  const [startEndDate, setStartEndDates] = useState<string[]>([]);
  const [hasStartersAndGenerators, setHasStartersAndGenerators] = useState(false);
  const [isShort, setIsShort] = useState(false);
  const [saleOrder, setSaleOrder] = useState('createdAt DESC');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getRequestQuery = () => ({
    order: [saleOrder],
    where: {
      hasStartersAndGenerators,
      and: [{
        createdAt: {
          gte: startEndDate[0],
        },
      }, {
        createdAt: {
          lt: startEndDate[1],
        },
      }],
    },
  });

  const downloadCsvReport = async () => {
    try {
      setIsReportCreating(true);
      const query = getRequestQuery();
      const sales = await getSales<ISale>(query);
      const csvData = getCsvSaleOrderData(sales);
      downloadCsv(csvData, `${filename}.csv`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsReportCreating(false);
    }
  };

  const downloadPdfReport = async () => {
    try {
      setIsReportCreating(true);
      const query = getRequestQuery();
      const sales = await getSales<ISale>(query);

      const pdfReport = new SaleReportPdfCreator().addMainHeader(filename);

      if (isShort) {
        pdfReport.addSalesShort(sales);
      } else {
        pdfReport.addSalesFull(sales);
      }

      pdfReport.save(`${filename}.pdf`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setIsReportCreating(false);
    }
  };

  const handleRangeDateChange = (dates: Moment[], stringDates: string[]): void => {
    if (!dates) {
      return;
    }

    const [startDate, endDate] = dates;

    if (startDate && endDate) {
      const newDates = [
        startDate.utcOffset(0).startOf('day').toISOString(),
        endDate.utcOffset(0).endOf('day').toISOString(),
      ];

      setStartEndDates(newDates);
      const newFileName = `Отчет продаж с ${stringDates[0]} по ${stringDates[1]}`;
      setFilename(newFileName);
    }
  };

  return (
    <>
      <Button type="dashed" onClick={toggleModal}>
        Сформировать отчет по продажам
      </Button>
      <Modal
        title="Сформировать отчет по продажам"
        open={isModalOpen}
        confirmLoading={isReportCreating}
        onCancel={toggleModal}
        width={600}
        footer={[
          <Button key='close-modal' onClick={toggleModal}>
            Закрыть
          </Button>,
          <Button
            key='download-csv'
            type='primary'
            onClick={downloadCsvReport}
          >
            Скачать CSV отчет
          </Button>,
          <Button
            key='download-pdf'
            type='primary'
            onClick={downloadPdfReport}
          >
            Скачать PDF отчет
          </Button>,
        ]}
      >
        <Space>
          <div>Период отчета</div>
          <RangePicker
            // @ts-ignore
            onChange={handleRangeDateChange}
            format='DD.MM.yyyy'
            showTime={false}
          />
        </Space>
        <Space size='large' style={{ margin: '16px 0' }}>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            style={{ marginRight: '14px' }}
            checked={hasStartersAndGenerators}
            onChange={setHasStartersAndGenerators}
          />
          <span>Только стартеры и генераторы</span>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            style={{ marginRight: '14px' }}
            checked={isShort}
            onChange={setIsShort}
          />
          <span>Сокращенный отчет</span>
        </Space>
        <Space style={{ margin: '12px 0' }}>
          <span>Сортировка продаж</span>
          <Select
            optionFilterProp="label"
            style={{ width: '200px' }}
            value={saleOrder}
            onChange={setSaleOrder}
            options={[
              {
                label: 'По убыванию',
                value: 'createdAt DESC',
              },
              {
                label: 'По возврастанию',
                value: 'createdAt ASC',
              },
            ]}
          />
        </Space>
        <Alert message="По умолчанию отчет формируется без учета продаж по стартерам и генераторам" type="info" />
      </Modal>
    </>
  );
};

export default CreateSalesReportModal;