import { apiService } from 'services';

export const getProducts = <T>(query: unknown): Promise<T[]>=> apiService.GET(`/products?filter=${JSON.stringify(query)}`);

interface ICount {
  count: number
}

export const getProductsCount = (query?: unknown): Promise<ICount> => {
  if (query) {
    return apiService.GET(`/products/count?where=${JSON.stringify(query)}`);
  }

  return apiService.GET('/products/count');
};


interface IProductSearchReturn<T> {
  data: T[];
  total: ICount
}
export const searchProducts = <T>(body: unknown): Promise<IProductSearchReturn<T>> => apiService.PUT('/products/search', body);

export const patchProduct = <T>(id: string, body: Partial<T>) => apiService.PATCH(`/products/${id}`, body);