import { FC } from 'react';
import { Tabs } from 'antd';

import { SeoTab } from '../SeoTab';
import { CategoryGeneralTab } from './CategoryGeneralTab';

type Props = {
  generalTabProps: { data: any; form: any };
};

export const CategoryTabs: FC<Props> = ({ generalTabProps }) => (
  <Tabs
    defaultActiveKey="1"
    type="card"
    tabBarGutter={5}
    items={[
      {
        key: 'generalTab',
        label: 'Основное',
        children: <CategoryGeneralTab {...generalTabProps} />,
      },
      {
        key: 'seoTab',
        label: 'SEO',
        children: <SeoTab />,
      },
    ]}
  />
);
