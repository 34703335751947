import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ISaleManualProduct } from '1_shared';
import { useState } from 'react';
import ProductManuallyModal from '../ProductManuallyModal';

interface IAddProductManuallyButton {
  type?: 'dashed' | 'link' | 'text' | 'ghost' | 'default' | 'primary';
  onAdd: (product: ISaleManualProduct) => void
}

const AddProductManuallyButton = ({ type = 'dashed', onAdd }: IAddProductManuallyButton): JSX.Element => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);

  const toggleModal = () => {
    setModalOpened(!isModalOpened);
  };

  const handleOk = (product: ISaleManualProduct) => {
    onAdd(product);
    toggleModal();
  };

  return (
    <>
      <Button
        type={type}
        onClick={toggleModal}
        icon={<PlusOutlined />}
      >
        Добавить товар в ручную
      </Button>
      <ProductManuallyModal
        isOpened={isModalOpened}
        onCancel={toggleModal}
        onOk={handleOk}
      />
    </>
  );
};

export default AddProductManuallyButton;