import { useNavigate, useParams } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { Permissions } from 'enums/permissions';
import { notificationService, userService } from 'services';
import { Pages } from '1_shared';
import { deleteParsingCar } from '2_entities';

const ParsingCarDeleteButton = (): JSX.Element => {
  const navigate = useNavigate();
  const param: any = useParams();

  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) || !param.id) {
    return <></>;
  }

  const handleDelete = async () => {
    try {
      await deleteParsingCar(param.id);
      navigate(Pages.PARSING_CARS);
      notificationService.notifySuccess('Автомобиль успешно удален');
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  return (
    <Popconfirm
      key='delete-btn'
      title="Вы действительно хотите удалить запись?"
      onConfirm={handleDelete}
      okText="Да"
      cancelText="Нет"
    >
      <Button danger>
        Удалить
      </Button>
    </Popconfirm>
  );
};

export default ParsingCarDeleteButton;