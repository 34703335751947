import { useEffect, useState } from 'react';
import {
  Form,
  FormInstance,
  Input,
  Row,
  Col,
  Select,
  Typography,
  Button,
  InputNumber,
  Space,
  DatePicker,
  Checkbox,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { LabelField } from 'components/LabelField';
import { commonVerticalLayout } from 'constants/formLayout';
import { Permissions } from 'enums/permissions';
import { useParams } from 'react-router-dom';
import { notificationService, userService } from 'services';
import { useFetchData } from 'hooks/useFetchData';
import { Spinner } from 'components/Spinner';
import { requiredField } from 'constants/formRules';
import {
  ISaleManualProduct,
  ISaleProduct,
  SalePaymentMethod,
  SaleSourceEnum,
  getDate,
  getMomentDate,
  transformEnumToOptions,
} from '1_shared';
import { ProductSaleList, ProductSelectSearch, RefundList, getSaleProduct } from '2_entities';
import { AddProductManuallyButton } from '3_features/AddProductManually';

import styles from './CreateEditSaleForm.module.scss';
import moment from 'moment';

const { TextArea } = Input;
const { Title } = Typography;

interface ICreateEditSaleForm {
  form: FormInstance;
  isNew: boolean
}

const CreateEditSaleForm = ({ form, isNew }: ICreateEditSaleForm): JSX.Element => {
  const param: any = useParams();

  const [selectedProducts, setSelectedProducs] = useState<ISaleProduct[] | ISaleManualProduct[]>([]);

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/sales/${param.id}`);

  const isFormDisabled = !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]);

  useEffect(() => {
    form.setFieldValue('products', selectedProducts);

  }, [selectedProducts]);

  useEffect(() => {
    setSelectedProducs(data.products ?? []);
  }, [data.products]);

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleSelect = (productId: string | undefined, { product }: any) => {
    const isExist: boolean = selectedProducts.findIndex(({ id }) => id === productId) !== -1;

    if (isExist) {
      notificationService.notifyInfo('Товар уже добавлен');

      return;
    }

    if (product) {
      const {
        count = 0,
        additionalCount = 0,
        secondAdditionalCount = 0,
        types = [],
      } = product;

      if (count + additionalCount + secondAdditionalCount <= 0) {
        notificationService.notifyInfo('Товар недоступен к продаже. Кол-во: 0');

        return;
      }

      const hasStartersAndGenerators = types.includes('20674');

      if (hasStartersAndGenerators) {
        form.setFieldValue('hasStartersAndGenerators', true);
      }

      const saleProduct: ISaleProduct = getSaleProduct(product);

      setSelectedProducs([...selectedProducts, saleProduct]);
    }
  };

  const handleManualAddProduct = (product: ISaleManualProduct) => {
    setSelectedProducs([...selectedProducts, product]);
  };

  const validateProducts = () => ({
    validator: (_, products: ISaleProduct[] | ISaleManualProduct[]) => {
      const hasInvalidData = products.some(({
        saleCount = 0,
        saleAdditionalCount = 0,
        saleSecondAdditionalCount = 0,
      }) => {
        return saleCount + saleAdditionalCount + saleSecondAdditionalCount === 0;
      });

      if (hasInvalidData) {
        return Promise.reject(new Error('Количество обязательно у всех товаров'));
      } else {
        return Promise.resolve();
      }
    },
  });

  const { refunds = [] } = data;

  return (
    <Form
      className="mg-t-16"
      form={form}
      labelAlign="left"
      requiredMark={false}
      initialValues={{
        source: '',
        paymentMethod: '',
        comment: '',
        client: {
          name: '',
          car: '',
          vin: '',
          phone: '',
          email: '',
        },
        products: [],
        ...data,
        createdAt: getMomentDate(data.createdAt),
      }}
      layout="vertical"
      {...commonVerticalLayout}
      disabled={isFormDisabled}
    >
      <Row gutter={40}>
        <Col md={16} xs={24}>
          <Row gutter={40}>
            <Col md={12} xs={24}>
              <Form.Item name="source" label="Источник продажи*" rules={requiredField}>
                <Select
                  optionFilterProp="label"
                  allowClear
                  options={transformEnumToOptions(SaleSourceEnum)}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name="paymentMethod" label="Вид оплаты*" rules={requiredField}>
                <Select
                  optionFilterProp="label"
                  allowClear
                  options={transformEnumToOptions(SalePaymentMethod)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40}>
            <Col md={24} xs={24}>
              <Form.Item name={['client', 'name']} label="ФИО клиента">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name={['client', 'car']} label="Автомобиль клиента">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name={['client', 'vin']} label="VIN номер автомобиля клиента">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name={['client', 'phone']} label="Телефон клиента">
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item name={['client', 'email']} label="Email клиента">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='comment'
            label="Комментарий"
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name='createdAt'
            label="Дата продажи"
          >
            <DatePicker
              style={{ width: '200px' }}
              format='D.M.yyyy, H:mm'
              showTime={{
                defaultValue: moment(),
              }}
            />
          </Form.Item>
          <Form.Item
            name="hasStartersAndGenerators"
            valuePropName="checked"
            noStyle
          >
            <Checkbox>Продажа из категории стартеры и генераторы</Checkbox>
          </Form.Item>
        </Col>
        {!isNew && (
          <Col md={8} xs={24}>
            <LabelField label="Дата создания" value={getDate(data.createdAt)} />
            <LabelField label="Кем создана" value={data.createdBy?.name} />
            <RefundList refunds={refunds} />
          </Col>
        )}
      </Row>
      <Row gutter={40}>
        <Col md={24}>
          <Title level={5}>Список товаров</Title>
          <Row gutter={40}>
            <Col md={16}>
              <ProductSelectSearch className={styles.productSearch} onSelect={handleSelect} />
            </Col>
            <Col md={8}>
              <AddProductManuallyButton onAdd={handleManualAddProduct} />
            </Col>
          </Row>
          <Form.Item
            name='products'
            className={styles.productList}
            rules={[validateProducts]}
          >
            <ProductSaleList
              products={selectedProducts}
              setProducts={setSelectedProducs}
              refunds={refunds}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col md={24}>
          <Title level={5}>Список услуг</Title>
          <Form.List name="services">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} size="large" wrap style={{ display: 'flex' }}>
                    <Form.Item
                      {...restField}
                      label="Наименование услуги"
                      key={`${key}-name`}
                      name={[name, 'name']}
                      style={{ width: '500px' }}
                      required
                    >
                      <Input placeholder="Наименование услуги" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Цена"
                      key={`${key}-price`}
                      name={[name, 'price']}
                      initialValue={0}
                    >
                      <InputNumber min={0} defaultValue={0} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Кол-во"
                      key={`${key}-count`}
                      name={[name, 'count']}
                      initialValue={1}
                    >
                      <InputNumber min={1} defaultValue={1} />
                    </Form.Item>
                    <DeleteOutlined key={`${key}-minus`} onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Добавить услугу
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateEditSaleForm;
