import { useEffect, useState, VFC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Select, List, Typography, Popconfirm, PageHeader, Button, InputNumber, Form } from 'antd';
import classNames from 'classnames';

import { apiService, notificationService } from '../../../services';
import { Product } from '../../../interfaces';
import { Pages } from '../../../1_shared/constants/pages';
import { ImagePreview } from '../../../1_shared/ui/ImagePreview';
import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useFetchData } from '../../../hooks/useFetchData';
import { Spinner } from '../../../components/Spinner';
import { orderStatusOptions } from '../../../constants/orderStatus';
import { OrderStatus } from '../../../enums/orderStatus';
import { OrderAdditionalInfo } from '../../../components/OrderAdditionalInfo/OrderAdditionalInfo';
import { Permissions } from '../../../enums/permissions';
import { ACL } from '../../../components/ACL';
import { ProductSelectSearch } from '2_entities';

import styles from './OrderEditPage.module.scss';

const { Title } = Typography;

interface SelectedProduct extends Partial<Product> {
  id: string,
  count: number,
  maxCount: number
}

const getProductDescription = (product: SelectedProduct): string => {
  const { code, originalNumbers } = product;

  const productCode = code ? `Код товара: ${code}. ` : '';
  const originalNumber = originalNumbers?.length ? `Оригинальный номер ${originalNumbers.join(', ')}. ` : '';

  let description = `${productCode}${originalNumber}`;

  return description;
};

export const OrderEditPage: VFC = () => {
  const navigate = useNavigate();
  const param: any = useParams();

  const [form] = Form.useForm();

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/orders/${param.id}`);

  const [selectedProducts, setSelectedProducs] = useState<SelectedProduct[]>([]);
  const [orderStatus, setOrderStatus] = useState<string>(OrderStatus.NEW);
  const [discount, setDiscount] = useState<number | null>(0);

  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(true);

  const isNew = !param.id;

  const subTitle = isNew
    ? 'Создание заказа'
    : `Редактирование ${data.name}`;

  useEffect(() => {
    if (data.products) {
      setSelectedProducs(data.products.slice());
    }

    if (data.status) {
      setOrderStatus(data.status);
    }

    if (data.discount) {
      setDiscount(data.discount);
    }
  }, [data]);

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const enableSaveBtn = () => {
    if (isSaveBtnDisabled) {
      setSaveBtnDisabled(false);
    }
  };

  const handleDiscount = (value: number | null): void => {
    setDiscount(value);
  };

  const handleSelect = (productId: string | undefined, { product }: any) => {
    const isExist: boolean = selectedProducts.findIndex(({ id }) => id === productId) !== -1;

    if (isExist) {
      notificationService.notifyInfo('Товар уже добавлен в заказ');

      return;
    }

    if (product) {
      const { count, additionalCount, ...rest } = product;

      if (count + additionalCount <= 0) {
        notificationService.notifyInfo('Товар недоступен. Кол-во: 0');

        return;
      }

      const processedSelectedValue = {
        ...rest,
        count: 1,
      };

      setSelectedProducs([...selectedProducts, processedSelectedValue]);
      enableSaveBtn();
    }
  };

  const incriment = (item: SelectedProduct) => {
    item.count = item.count + 1;

    // because item is from selectedProducts
    setSelectedProducs(selectedProducts.slice());
    enableSaveBtn();
  };

  const decriment = (item: SelectedProduct) => {
    if (item.count > 1) {
      item.count = item.count - 1;

      // because item is from selectedProducts
      setSelectedProducs(selectedProducts.slice());
      enableSaveBtn();
    }
  };

  const handleDelete = (id: string) => {
    const currentProductIndex = selectedProducts.findIndex(product => product.id === id);
    selectedProducts.splice(currentProductIndex, 1);
    setSelectedProducs(selectedProducts.slice());

    enableSaveBtn();
  };

  const handleCancel = () => {
    navigate(Pages.ORDERS);
  };

  const getProductsCount = () => {
    return selectedProducts.reduce((acc: number, product: SelectedProduct) => {
      return acc + product.count;
    }, 0);
  };

  const getTotalPrice = () => {
    const totalPrice = selectedProducts.reduce((acc: number, product: SelectedProduct) => {
      const price = product.price ?? 0;
      return acc + (product.count * price);
    }, 0);

    if (discount) {
      const priceWithDiscount = totalPrice - (totalPrice / 100 * discount);

      return priceWithDiscount;
    }

    return totalPrice;
  };

  const getNotFormedBody = () => {
    return {
      products: selectedProducts,
      productsCount: getProductsCount(),
      totalPrice: getTotalPrice(),
      status: orderStatus,
      discount,
    };
  };

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      const body = form.getFieldsValue();
      Object.assign(body, getNotFormedBody());

      if (isNew) {
        const requestedData = await apiService.POST('/orders', body);

        navigate(`${Pages.ORDERS}/${requestedData.id}`);

        notificationService.notifySuccess('Заказ успешно создан');
      } else {
        await apiService.PATCH(`/orders/${data.id}`, body);

        notificationService.notifySuccess('Заказ успешно сохранен');
      }
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleStatusChange = (value: string) => {
    setOrderStatus(value);
    enableSaveBtn();
  };

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Заказ"
          subTitle={subTitle}
          extra={[
            <Select
              key="order-status"
              className={styles.statusSelect}
              onChange={handleStatusChange}
              options={orderStatusOptions}
              defaultValue={OrderStatus.NEW}
              value={orderStatus}
            />,
            <Popconfirm
              key='cancel-btn'
              title="Вы действительно хотите выйти из заказа?"
              onConfirm={handleCancel}
              okText="Да"
              cancelText="Нет"
            >
              <Button>{isNew ? 'Отмена' : 'Закрыть'}</Button>
            </Popconfirm>,
            <Button
              key="1"
              type="primary"
              disabled={isSaveBtnDisabled}
              onClick={handleSave}
            >
              Сохранить
            </Button>,
          ]}
        />
        <div className='page-content'>
          <OrderAdditionalInfo data={data} form={form} onFieldsChange={() => setSaveBtnDisabled(false)} />
          <ProductSelectSearch className={styles.search} onSelect={handleSelect} />
          <div className={styles.productsContainer}>
            <Title level={5}>Список товаров в заказе <b>{data?.name}</b></Title>
            <List
              itemLayout="horizontal"
              dataSource={selectedProducts}
              locale={{ emptyText: 'Нет товаров в заказе' }}
              rowKey="id"
              renderItem={item => (
                <List.Item
                  actions={
                    [
                      <div className={styles.counter} key={`counter-${item.id}`}>
                        <PlusOutlined
                          className={classNames(styles.actionBtn, styles.plus)}
                          key={`incrimen-${item.id}`}
                          disabled={item.maxCount === item.count}
                          onClick={() => incriment(item)}
                        />
                        <span>{item.count}</span>
                        <MinusOutlined
                          className={styles.actionBtn}
                          key={`decrimentt-${item.id}`}
                          disabled={item.count === 1}
                          onClick={() => decriment(item)}
                        />
                      </div>,
                      <Popconfirm
                        key={`delete-${item.id}`}
                        title="Вы действительно хотите удалить товар из заказа?"
                        onConfirm={() => handleDelete(item.id)}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <DeleteOutlined
                          className={styles.actionBtn}
                        />
                      </Popconfirm>
                      ,
                    ]
                  }
                >
                  <List.Item.Meta
                    avatar={<ImagePreview image={item.image} gallery={item.gallery} />}
                    title={<Link target="_blank" to={`${Pages.PRODUCTS}/${item.id}`}>{item.name}</Link>}
                    description={getProductDescription(item)}
                  />
                  <div>{item.price} руб.</div>
                </List.Item>
              )}
            />
            <div className={styles.summary}>
              <div>Общее кол-во товаров: {getProductsCount()}</div>
              <div>
                <div>
                  Скидка:
                  <InputNumber
                    className={styles.discount}
                    value={discount}
                    addonAfter="%"
                    onChange={handleDiscount}
                    min={0}
                    max={100}
                  />
                </div>
                <div>Итоговая сумма: <b>{getTotalPrice()} руб.</b></div>
              </div>
            </div>
          </div>
        </div>
      </>
    </ACL>
  );
};