import { Form, Input, Select, FormInstance } from 'antd';

import { requiredField } from '../../../../constants/formRules';
import { dictionaries } from '../../../../dictionaries';
import { translit } from '../../../../utils/translit';

type Props = {
  data: any;
  form: FormInstance;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CategoryGeneralTab = ({ data, form }: Props) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  const handleParentChange = (value: string, option: any) => {
    form.setFields([{ name: 'parentAlias', value: option.alias }]);
  };

  return (
    <>
      <Form.Item hidden name="parentAlias" />
      <Form.Item
        name="parentId"
        label="Родительский тип*"
        rules={requiredField}
      >
        <Select
          onChange={handleParentChange}
          options={dictionaries.protuctTypesDictionary.types}
        />
      </Form.Item>
      <Form.Item
        name="name"
        label="Наименование категории*"
        rules={requiredField}
      >
        <Input onChange={handleNameChange} />
      </Form.Item>
      <Form.Item
        name="alias"
        label="Url псевдоним*"
        rules={requiredField}
      >
        <Input />
      </Form.Item>
    </>
  );
};
