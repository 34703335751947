import { VFC } from 'react';
import { Collapse, Form, FormInstance, Select, Input, InputNumber, Row, Col } from 'antd';

import { PaymentMethod } from '../../enums/paymentMethod';
import { ShipmentMethod } from '../../enums/shipmentMethod';
import { commonVerticalLayout } from '../../constants/formLayout';
import { paymentMethodOptions } from '../../constants/paymentMethodOptions';
import { shipmentCompanyOptions, shipmentMethodOptions } from '../../constants/shipmentMethodOptions';

import styles from './OrderAdditionalInfo.module.scss';

const { Panel } = Collapse;
const { TextArea } = Input;

interface OrderAdditionalInfoProps {
  data: any,
  form: FormInstance;
  onFieldsChange: () => void;
}

export const OrderAdditionalInfo: VFC<OrderAdditionalInfoProps> = ({ data, form, onFieldsChange }) => {
  return (
    <div className={styles.additionalInfo}>
      <Form
        form={form}
        labelAlign="left"
        requiredMark={false}
        initialValues={data}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        {...commonVerticalLayout}
      >
        <Collapse defaultActiveKey="client-info">
          <Panel header="Данные клиента" key="client-info">
            <Row gutter={64}>
              <Col md={8} xs={24}>
                <Form.Item name="userName" label="ФИО">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item name="phone" label="Телефон">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="Доставка и оплата" key="shipment-payment">
            <Row gutter={64}>
              <Col md={8} xs={24}>
                <Form.Item
                  name="paymentMethod"
                  label="Способ оплаты"
                  initialValue={PaymentMethod.CASH}
                >
                  <Select options={paymentMethodOptions} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name="shipmentMethod"
                  label="Способ доставки"
                  initialValue={ShipmentMethod.PICKUP}
                >
                  <Select options={shipmentMethodOptions} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name="shipmentCompany"
                  label="Транспортная компания"
                >
                  <Select options={shipmentCompanyOptions} />
                </Form.Item>
              </Col>
              <Col md={16} xs={48}>
                <Form.Item
                  name="shipmentComment"
                  label="Адрес и дополнительные данные для доставки"
                >
                  <TextArea rows={5} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item name="shipmentPrice" label="Стоимость доставки, руб.">
                  <InputNumber defaultValue={0} min={0} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="Комментарий" key="comments">
            <Form.Item name="comment" label="">
              <TextArea rows={5} />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};