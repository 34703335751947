import { Permissions } from 'enums/permissions';
import { Product } from 'interfaces';
import { useState } from 'react';
import { userService } from 'services';
import { ProductTableCountCellForm, ProductTableCountCellView } from '2_entities';

type Counts = Pick<Product, 'count' | 'additionalCount' | 'secondAdditionalCount'>;

interface IProductTableCountCell {
  product: Partial<Product>
}

export const ProductTableCountCell = ({ product }: IProductTableCountCell): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [counts, setCounts] = useState<Counts>({
    count: product.count ?? 0,
    additionalCount: product.additionalCount ?? 0,
    secondAdditionalCount: product.secondAdditionalCount ?? 0,
  });

  const hasAccess = userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]);

  const toggleEdit = () => {
    if (hasAccess) {
      setIsEditing(!isEditing);
    }
  };

  const handleSave = (data: Counts) => {
    toggleEdit();
    setCounts(data);
  };

  if (isEditing) {
    return (
    <ProductTableCountCellForm 
      id={product.id as string} 
      counts={counts}
      onSave={handleSave}
      />
    );
  }

  return (<ProductTableCountCellView counts={counts} toggleEdit={toggleEdit} />);
};