import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { InputNumber, List, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { IRefund, ISaleManualProduct, ISaleProduct, ImagePreview, Pages } from '1_shared';
import { getProductDescription } from '2_entities';

import styles from './ProductSaleList.module.scss';

interface IProductSaleList {
  products: ISaleProduct[] | ISaleManualProduct[];
  setProducts: (products: ISaleProduct[] | ISaleManualProduct[]) => void;
  refunds: IRefund[]
}

const ProductSaleList = ({ products, setProducts, refunds }: IProductSaleList) => {
  // because all action mutate current product
  const handleProductsChange = () => {
    setProducts(products.slice());
  };

  const handleDelete = (index: number) => {
    products.splice(index, 1);
    handleProductsChange();
  };

  const handleChangeNumberInput = (value: number | null, fieldKey: string, product: ISaleProduct | ISaleManualProduct[]): void => {
    product[fieldKey] = value ?? 0;
    handleProductsChange();
  };

  const getDisabled = (product: ISaleProduct | ISaleManualProduct) => {
    if (!refunds.length) {
      return false;
    }

    return refunds.some((refund) => {
      return refund.products.some((refundedProduct) => {
        return refundedProduct.id === product?.id || refundedProduct.name === product.name;
      });
    });
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={products}
      locale={{ emptyText: 'Нет товаров в списке' }}
      rowKey="id"
      renderItem={(product, index) => {
        const isDisabled = getDisabled(product);

        return (
          <List.Item
            actions={
              [
                <div className={styles.counterContainer} key={`counter-${product.id}`}>
                  {Boolean(product.count) && (
                    <div className={styles.counter}>
                      <span className={styles.countTitle}>
                        Доступное кол-во на Ириновском ({product.count} шт)
                      </span>
                      <InputNumber
                        value={product.saleCount}
                        className={styles.inputCount}
                        onChange={(value: number | null) => handleChangeNumberInput(value, 'saleCount', product)}
                        min={0}
                        max={product.count}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                  {Boolean(product.additionalCount) && (
                    <div className={styles.counter}>
                      <span className={styles.countTitle}>
                        Доступное кол-во на Бокситогорской ({product.additionalCount} шт)
                      </span>
                      <InputNumber
                        value={product.saleAdditionalCount}
                        className={styles.inputCount}
                        onChange={(value: number | null) => handleChangeNumberInput(value, 'saleAdditionalCount', product)}
                        min={0}
                        max={product.additionalCount}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                  {Boolean(product.secondAdditionalCount) && (
                    <div className={styles.counter}>
                      <span className={styles.countTitle}>
                        Доступное кол-во на Придорожной ({product.secondAdditionalCount} шт)
                      </span>
                      <InputNumber
                        value={product.saleSecondAdditionalCount}
                        className={styles.inputCount}
                        onChange={(value: number | null) => handleChangeNumberInput(value, 'saleSecondAdditionalCount', product)}
                        min={0}
                        max={product.secondAdditionalCount}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                  {!product.id && (
                    <div className={styles.manualCounter}>
                      <InputNumber
                        value={product.saleCount}
                        className={styles.inputCount}
                        onChange={(value: number | null) => handleChangeNumberInput(value, 'saleCount', product)}
                        min={0}
                        max={product.count}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                </div>,
                <Popconfirm
                  key={`delete-${product.id}`}
                  placement="left"
                  title="Вы действительно хотите удалить товар?"
                  onConfirm={() => handleDelete(index)}
                  okText="Да"
                  cancelText="Нет"
                  disabled={isDisabled}
                >
                  <Tooltip 
                  title={isDisabled ? 'Товар был возвращен, удаление невозможно' : 'Удалить товар из списка?'}
                  placement="bottomLeft"
                  >
                    <DeleteOutlined
                      className={classNames(styles.actionBtn, {
                        [styles.disabled]: isDisabled,
                      })}
                      disabled={isDisabled}
                    />
                  </Tooltip>
                </Popconfirm>,
              ]
            }
          >
            <List.Item.Meta
              avatar={<ImagePreview image={product.image} gallery={product.gallery} />}
              title={<Link target="_blank" to={`${Pages.PRODUCTS}/${product.id}`}>{product.name}</Link>}
              description={getProductDescription(product)}
              style={{ marginRight: '16px' }}
            />
            <div>
              <InputNumber
                value={product.salePrice}
                onChange={(value: number | null) => handleChangeNumberInput(value, 'salePrice', product)}
                min={0}
                addonAfter='руб'
                disabled={getDisabled(product)}
              />
            </div>
          </List.Item>
        );
      }}
    />
  );
};

export default ProductSaleList;