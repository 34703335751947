import { Product } from '../interfaces';
import { ProductStatus } from '../enums/status';
import { MathOperations } from '../enums/mathOperations';

export const getSearchValue = (value: string): string => {
  const result = value.trim();
  const splitedValue: string[] = result.split(' ');

  if (splitedValue.length === 1) {
    return result;
  }

  const isNumerableValue = splitedValue.every(item => !Number.isNaN(Number(item)));

  if (isNumerableValue) {
    return splitedValue.join('');
  }

  return result;
};

interface MoveOptions {
  current: string;
  target: string;
}

export const moveProduct = (product: Product, moveOptions: MoveOptions): Product => {
  const currentKey: string = moveOptions.current;
  const targetkey: string = moveOptions.target;
  const currentValue = product[currentKey] ?? 0;
  const targetValue = product[targetkey] ?? 0;

  const sumValue = currentValue + targetValue;

  return {
    ...product,
    [currentKey]: 0,
    [targetkey]: sumValue,
  };
};

interface ChangePriceOptions {
  mathOperation: MathOperations.MULTIPLY | MathOperations.DIVIDE | MathOperations.ADD | MathOperations.SUBTRACT;
  value: number;
}

export const changePrice = (product: Product, { mathOperation, value }: ChangePriceOptions): Product => {
  let price = product.price ?? 0;

  switch (mathOperation) {
    case MathOperations.MULTIPLY:
      price = price * value;
      break;
    case MathOperations.DIVIDE:
      price = Math.floor(price / value);
      break;
    case MathOperations.ADD:
      price = price + value;
      break;
    case MathOperations.SUBTRACT:
      price = price - value >= 0 ? price - value : 0;
      break;
    default:
      break;
  }

  return {
    ...product,
    price,
  };
};

interface ChangePublishedOptions {
  published: 'true' | 'false'
}

export const changePublished = (product: Product, { published }: ChangePublishedOptions): Product => ({
  ...product,
  published: published === 'true' ? true : false,
});

interface ChangeStatusOptions {
  status: ProductStatus.STOCK | ProductStatus.NOT_AVAILABLE | ProductStatus.ORDER
}

export const changeStatus = (product: Product, { status }: ChangeStatusOptions): Product => ({
  ...product,
  status,
});