import { message } from 'antd';

export class NotificationService {
  notifyError = (e: any, duration = 0): void => {
    if (e.errorFields) {
      return;
    }

    let content = e.message || e;

    if (e.code === 'ENTITY_NOT_FOUND') {
      content = 'Ошибка 404. Запрашиваемый контент не найдет.';
    }

    const key = `${content}_error`;

    message.error({
      content,
      key,
      duration,
      onClick: () => {
        message.destroy(key);
      },
    });
  };

  notifySuccess = (content: string, duration = 5): void => {
    message.success({
      content,
      duration,
    });
  };

  notifyInfo = (content: string, duration = 3): void => {
    message.info({
      content,
      duration,
    });
  };
}