import { apiService, notificationService } from '../services';
import { ProtuctTypesDictionary } from './ProtuctTypesDictionary';
import { CarDictionary } from './CarDictionary';
import { ServicesDictionary } from './ServicesDictionary';

const protuctTypesDictionary = new ProtuctTypesDictionary({ apiService, notificationService });
const carDictionary = new CarDictionary({ apiService, notificationService });
const servicesDictionary = new ServicesDictionary({ apiService, notificationService });

export const dictionaries = {
  protuctTypesDictionary,
  carDictionary,
  servicesDictionary,
};