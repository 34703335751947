import { ServiceOrderStatus } from '../enums/serviceOrderStatus';
import { transformEnumToOptions } from '1_shared';

export const serviceOrderStatusOptions = transformEnumToOptions(ServiceOrderStatus);

export const serviceOrderStatusFilter = [
  {
    text: ServiceOrderStatus.NEW,
    value: ServiceOrderStatus.NEW,
    color: 'blue',
  },

  {
    text: ServiceOrderStatus.DONE,
    value: ServiceOrderStatus.DONE,
    color: 'green',
  },
  {
    text: ServiceOrderStatus.CANCELED,
    value: ServiceOrderStatus.CANCELED,
    color: 'red',
  },
  {
    text: ServiceOrderStatus.ACCEPTED,
    value: ServiceOrderStatus.ACCEPTED,
    color: 'cyan',
  },
];