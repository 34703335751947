import { Tag } from 'antd';
import { productStatusFilter } from 'constants/statusOptions';

export const ProductGroupTableColumns = [
  {
    title: '',
    dataIndex: 'checkbox',
    key: 'checkbox',
    width: 50,
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    width: 450,
  },
  {
    title: 'Производитель',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    width: 150,
  },
  {
    title: 'Код товара',
    dataIndex: 'code',
    key: 'code',
    width: 150,
  },
  {
    title: 'Оригинальный номер',
    dataIndex: 'originalNumbers',
    key: 'originalNumbers',
    width: 200,
    render: (originalNumbers: string[]) => {
      return <span>{originalNumbers?.join(', ')}</span>;
    },
  },
  {
    title: 'Цена, руб',
    dataIndex: 'price',
    key: 'price',
    width: 120,
    align: 'center',
  },
  {
    title: 'Кол-во на Ириновском',
    dataIndex: 'count',
    key: 'count',
    width: 150,
  },
  {
    title: 'Кол-во на Бокситогорской',
    dataIndex: 'additionalCount',
    key: 'additionalCount',
    width: 150,
  },
  {
    title: 'Кол-во на Придорожной',
    dataIndex: 'secondAdditionalCount',
    key: 'secondAdditionalCount',
    width: 150,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (status: string) => {
      const props = productStatusFilter.find((item) => status === item.value);
      return (
        <Tag color={props?.color} key={props?.text}>
          {props?.text}
        </Tag>
      );
    },
    width: 150,
  },
];