import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { notificationService } from 'services';
import { getProducts } from '1_shared';
import { ProductStatus } from 'enums/status';
import { Product } from 'interfaces';

interface IParsingCarStatisticsCards {
  id: string;
}

interface StatisticsCardData {
  count: number;
  totalPrice: number;
}

const setTotalData = (product: Product, data: StatisticsCardData) => {
  const productPrice = product.price ?? 0;
  data.count = data.count + 1;
  data.totalPrice = data.totalPrice + productPrice;
};

const getDataForCard = (data: StatisticsCardData): string => `${data.count} шт (на сумму: ${data.totalPrice} руб)`;

export const ParsingCarStatisticsCards = ({ id }: IParsingCarStatisticsCards): JSX.Element => {
  const [totalProductData, setTotalProductData] = useState<StatisticsCardData>({ count: 0, totalPrice: 0 });
  const [availableProductData, setAvailableProductData] = useState<StatisticsCardData>({ count: 0, totalPrice: 0 });
  const [notAvailableProductData, setNotAvailableProductData] = useState<StatisticsCardData>({ count: 0, totalPrice: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsQuery = {
          where: {
            sparePartFrom: id,
          },
        };
        const products: Product[] = await getProducts(productsQuery);

        const tempAvailableProductData = {
          count: 0,
          totalPrice: 0,
        };
        const tempNotAvailableProductData = {
          count: 0,
          totalPrice: 0,
        };

        products.forEach((product: Product) => {
          if (product.status === ProductStatus.STOCK) {
            setTotalData(product, tempAvailableProductData);
          }

          if (product.status === ProductStatus.NOT_AVAILABLE) {
            setTotalData(product, tempNotAvailableProductData);
          }
        });

        setTotalProductData({
          count: products.length ?? 0,
          totalPrice: tempAvailableProductData.totalPrice + tempNotAvailableProductData.totalPrice,
        });
        setAvailableProductData(tempAvailableProductData);
        setNotAvailableProductData(tempNotAvailableProductData);
      } catch (error) {
        notificationService.notifyError(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <Row gutter={[16, 16]} justify='center' style={{ marginBottom: '24px' }}>
      <Col xs={24} md={12} xl={6}>
        <span>Всего товаров: {getDataForCard(totalProductData)}</span>
      </Col>
      <Col xs={24} md={12} xl={6}>
        <span style={{ color: 'green' }}>Активных товаров: {getDataForCard(availableProductData)}</span>
      </Col>
      <Col xs={24} md={12} xl={6}>
        <span style={{ color: 'red' }}>Проданных товаров: {getDataForCard(notAvailableProductData)}</span>
      </Col>
    </Row>
  );
};