import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IRefund, ISaleManualProduct, ISaleProduct, Pages } from '1_shared';

import styles from './ProductSaleCell.module.scss';
import classNames from 'classnames';

interface IProductSaleCell {
  products: ISaleProduct[] | ISaleManualProduct[];
  refunds?: IRefund[];
}

const ProductSaleCell = ({ products, refunds = [] }: IProductSaleCell): JSX.Element => {
  if (!products || !products.length) {
    return <></>;
  }

  let totalPrice = 0;

  const getIsRefunded = (product: ISaleProduct | ISaleManualProduct) => {
    if (!refunds.length) {
      return false;
    }

    return refunds.some((refund) => {
      return refund.products.some((refundedProduct) => {
        return refundedProduct.id === product?.id || refundedProduct.name === product.name;
      });
    });
  };

  const productsList = products.map((product, key) => {
    const {
      saleCount = 0,
      saleAdditionalCount = 0,
      saleSecondAdditionalCount = 0,
      salePrice = 0,
      name,
      code,
      id,
    } = product;

    const isRefunded = getIsRefunded(product);

    const totalCount = saleCount + saleAdditionalCount + saleSecondAdditionalCount;

    if (!isRefunded) {
      totalPrice += totalCount * salePrice;
    }

    return (
      <div
        key={id || key}
        className={classNames(styles.productList, {
          [styles.refundedProduct]: isRefunded,
        })}
      >
        {id ? (
          <Link to={`${Pages.PRODUCTS}/${id}`}>
            <span>{name}</span>
          </Link>
        ) : (
          <span>{name}</span>
        )}
        <br />
        {code && (
          <>
            <span>Код товара: <b>{code}</b></span>
            <span> &mdash; </span>
          </>
        )}
        <span>Цена: <b>{salePrice} руб</b></span>
        <span> &mdash; </span>
        <span>Кол-во: <b>{totalCount} шт</b></span>
      </div>
    );
  });

  return (
    <>
      {productsList}
      <br />
      <span>На сумму: <b>{totalPrice} руб</b></span>
      {!!refunds.length && (
        <Tooltip title={`Есть ${refunds.length} возврат(а)`}>
          <InfoCircleOutlined
            style={{
              marginLeft: '14px',
              color: '#1890ff',
              cursor: 'pointer',
              fontSize: '18px',
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ProductSaleCell;