import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { ImagePreview, Pages } from '1_shared';
import { IParsingCar } from '2_entities/parsing-car';
import { Product } from 'interfaces';
import { ProductStatus } from 'enums/status';

type TableItem = Partial<IParsingCar>;
type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

interface ICountCell {
  data: Product[];
  color?: string;
}

const CountCell = ({ data, color = 'black' }: ICountCell): JSX.Element => {
  const count = data.length;
  const totalPrice = data.reduce((acc: number, product: Product) => {
    const price = product?.price ?? 0;

    return acc + price;
  }, 0);

  return (<span style={{ color }}>{count} шт.<br />На сумму {totalPrice} руб.</span>);
};

export const ParsingCarTableColumns: Column[] = [
  {
    title: 'Изображение',
    dataIndex: 'image',
    key: 'image',
    width: 200,
    align: 'center',
    render: (image: string, record: TableItem) => (
      <ImagePreview image={image} gallery={record.gallery} width={150} height={100} />
    ),
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, record: TableItem) => {
      const url = `${Pages.PARSING_CARS}/${record.id}`;
      const status = record.published ? 'success' : 'error';

      return (
        <>
          <div>
            <Link to={url}><Badge status={status} /> {name}</Link>
          </div>
          <span>{record.introtext}</span>
        </>
      );
    },
    sorter: true,
  },
  {
    title: 'Цена покупки',
    dataIndex: 'price',
    key: 'price',
    width: 100,
    align: 'center',
    sorter: true,
  },
  {
    title: 'Количество товаров',
    dataIndex: 'products',
    key: 'products',
    width: 200,
    align: 'center',
    render: (products) => <CountCell data={products} />,
  },
  {
    title: 'Количество активных товаров',
    dataIndex: 'products',
    key: 'products',
    width: 200,
    align: 'center',
    render: (products) => (
      <CountCell
        data={products.filter((product: Product) =>
          product.status === ProductStatus.STOCK)
        }
        color='green'
      />
    ),
  },
  {
    title: 'Количество проданных товаров',
    dataIndex: 'products',
    key: 'products',
    width: 200,
    align: 'center',
    render: (products) => (
      <CountCell
        data={products.filter((product: Product) =>
          product.status === ProductStatus.NOT_AVAILABLE)
        }
        color='red'
      />
    ),
  },
];