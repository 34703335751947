import { getProducts } from '1_shared';
import { Select } from 'antd';
import { Product } from 'interfaces';
import { useState } from 'react';
import { notificationService } from 'services';

const { Option } = Select;

interface IProductSelectSearch {
  className?: string;
  onSelect: (productId: string | undefined, { product }: any) => void
}

let timeout: ReturnType<typeof setTimeout>;

const ProductSelectSearch = ({ className, onSelect }: IProductSelectSearch): JSX.Element => {
  const [options, setOptions] = useState<Product[]>([]);

  const fetch = (searchValue: string) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    const query = {
      where: {
        published: true,
        or: [
          { name: { like: searchValue, options: 'i' } },
          { code: searchValue },
          { originalNumbers: searchValue },
          { oldOriginalNumbers: searchValue },
        ],
      },
    };

    const search = async (): Promise<void> => {
      try {
        const searchData = await getProducts<Product>(query);
        setOptions(searchData);
      } catch (error) {
        notificationService.notifyError(error);
        setOptions([]);
      }
    };

    timeout = setTimeout(search, 300);
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setOptions([]);
    }

    if (value.length > 2) {
      fetch(value);
    }
  };

  return (
    <Select
      showSearch
      value="Поиск по товарам (Наименование, код товара, оригинальный номер)"
      className={className}
      placeholder="Поиск по товарам (Наименование, код товара, оригинальный номер)"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onSelect={onSelect}
    >
      {options.map((product: Product) => (
        <Option
          key={product.id}
          value={product.id}
          product={product}
        >
          {product.name}. Код товара: {product.code}. Оргинальный номер: {product.originalNumbers?.join(', ')}. Цена: {product.price} руб.
        </Option>
      ))}
    </Select>
  );
};

export default ProductSelectSearch;