import { FC, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import logo from '../../logo.svg';
import { emailRules, passwordRules } from '../../constants/formRules';
import { authService, notificationService } from '../../services';

import '../../App.css';

type Props = {
  loading?: boolean;
};

export const LoginForm: FC<Props> = ({ loading = false }) => {
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleLogin = async () => {
    setLoading(true);
    try {
      await form.validateFields();

      const body = form.getFieldsValue();
      await authService.login(body);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  const title = loading ? 'Загрузка...' : 'Bumervod Admin Panel';

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>{title}</p>
        {!loading && (
          <Form form={form}>
            <Form.Item name="email" rules={emailRules}>
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" rules={passwordRules}>
              <Input.Password placeholder="Пароль" />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleLogin}
              disabled={isLoading}
            >
              {isLoading ? <LoadingOutlined /> : 'Войти'}
            </Button>
          </Form>
        )}
      </header>
    </div>
  );
};
