import { ApiService } from './ApiService';
import { NotificationService } from './NotificationService';
import { Cookies } from '../interfaces';

type LoginBody = {
  email: string;
  password: string;
};

const years10 = 3650;

export class AuthService {
  notificationService: NotificationService;

  apiService: ApiService;

  cookies: Cookies;

  constructor({ notificationService, apiService, cookies }: any) {
    this.notificationService = notificationService;
    this.apiService = apiService;
    this.cookies = cookies;
  }

  login = async (body: LoginBody) => {
    try {
      const { token } = await this.apiService.POST('/users/login', body);

      // WARN - add 'secure: true' when switch to https
      // this.cookies.set('HttpAutorization', token, { secure: true, expires: years10 });
      this.cookies.set('HttpAutorization', token, { secure: false, expires: years10 });

      document.location.reload();
    } catch (error) {
      this.notificationService.notifyError(error);
    }
  };

  logout = () => {
    this.cookies.remove('HttpAutorization');
    document.location.reload();
  };

  isAuth = () => !!this.cookies.get('HttpAutorization');

  getToken = () => this.cookies.get('HttpAutorization');
}