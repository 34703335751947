import { useState } from 'react';
import { Button, Checkbox, Form, Input, PageHeader, Popconfirm, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { ACL } from '../../../components/ACL';
import { Spinner } from '../../../components/Spinner';
import { Permissions } from '../../../enums/permissions';
import { useFetchData } from '../../../hooks/useFetchData';
import { apiService, notificationService } from '../../../services';
import { commonVerticalLayout } from '../../../constants/formLayout';
import { requiredField, emailRules, passwordRules } from '../../../constants/formRules';
import { rolesOptions } from '../../../constants/rolesOprions';
import { LabelField } from '../../../components/LabelField';
import { getDate, Pages } from '1_shared';

export const UserPage = () => {
  const navigate = useNavigate();
  const param: any = useParams();
  const [form] = Form.useForm();

  const isNew: boolean = !param.id;

  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/users/${param.id}`);

  const subTitle: string = isNew ? 'Создание пользователя' : 'Редактирование пользователя';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (isNew) {
        const requestedData = await apiService.POST('/users', body);

        navigate(`${Pages.USERS}/${requestedData.id}`);
      } else {
        await apiService.PATCH(`/users/${data.id}`, body);
      }
      notificationService.notifySuccess('Пользователь успешно сохранен');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSaveBtnDisabled(true);
      await apiService.DELETE(`/users/${data.id}`);
      navigate(Pages.USERS);
      notificationService.notifySuccess('Пользователь успешно удален');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleCancel = () => {
    navigate(Pages.USERS);
  };

  return (
    <ACL roles={[Permissions.SUPER_ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Пользователи"
          subTitle={subTitle}
          extra={[
            <Popconfirm
              key='delete-btn'
              title="Вы действительно хотите удалить пользователя?"
              onConfirm={handleDelete}
              disabled={isNew}
              okText="Да"
              cancelText="Нет"
            >
              <Button disabled={isNew} danger>Удалить</Button>
            </Popconfirm>,
            <Button key="2" onClick={handleCancel}>
              {isNew ? 'Отмена' : 'Закрыть'}
            </Button>,
            <Button
              key="1"
              type="primary"
              disabled={isSaveBtnDisabled}
              onClick={handleSave}
            >
              Сохранить
            </Button>,
          ]}
        />
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={data}
          layout="vertical"
          {...commonVerticalLayout}
        >
          <Form.Item
            name="username"
            label="Ник пользователя*"
            rules={requiredField}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email пользователя*"
            rules={emailRules}
          >
            <Input />
          </Form.Item>
          {isNew && (
            <Form.Item
              name="password"
              label="Пароль пользователя*"
              rules={passwordRules}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="roles"
            label="Роли пользователя"
          >
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              options={rolesOptions}
            />
          </Form.Item>
        </Form>
        {!isNew && (
          <>
            <Form.Item name="deleted" valuePropName="checked" noStyle>
              <Checkbox disabled={isNew}>Soft Delete</Checkbox>
            </Form.Item>
            <div style={{ width: '300px' }}>
              <LabelField label="Дата создания" value={getDate(data.createdAt)} />
            </div>
          </>
        )}
      </>
    </ACL>
  );
};