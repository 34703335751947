import { IRefund, getDate } from '1_shared';
import { List } from 'antd';

import styles from './RefundList.module.scss';

interface IRefundList {
  refunds: IRefund[];
}

const RefundList = ({ refunds }: IRefundList): JSX.Element => {
  return (
    <div className={styles.container}>
      <h3>Возвраты</h3>
      <List
        bordered
        dataSource={refunds}
        renderItem={item => (
          <List.Item>
            <div>
              {getDate(item.createdAt)}
              {item.reason && (
                <div>
                  <span>Причина возврата: </span>
                  <span>{item.reason}</span>
                </div>
              )}
              {item.products.map(({ name, code }, index) => (
                <div key={index}>
                  <span>{name} - </span>
                  {code && (
                    <span>Код товара: {code}</span>
                  )}
                </div>
              ))}
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default RefundList;