import classNames from 'classnames';

import styles from './LabelField.module.scss';

type Props = {
  label: string;
  value: any;
  className?: string;
};

export const LabelField = ({ label, value, className }: Props) => {
  return (
    <div className={classNames(styles.labelField, className)}>
      <label>{label}:</label>
      <span>{value}</span>
    </div>
  );
};
