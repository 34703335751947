import { Conditions } from 'enums/conditions';
import { ISaleManualProduct, ISaleProduct, getDate } from '1_shared';
import { ISale } from '2_entities';

const PRODUCTS_COLUMNS = [
  'Товары',
  '',
  'Код товара',
  'Наименование',
  'Кол-во',
  'Цена',
  'Состояние',
];

const REFUNDS_COLUMNS = [
  'Возвраты',
];

const SERVICES_COLUMNS = [
  'Услуги сервиса',
  '',
  '',
  'Наименование',
  'Кол-во',
  'Цена',
];

const INCRIMENT = 'increment';
const DECRIMENT = 'decriment';

export const getCsvSaleOrderData = (sales: ISale[]): Array<Array<string | number>> => {
  const total = ['ОБЩИЙ ИТОГ:', 0];
  const totalNewProducts = ['НОВЫХ ЗАПЧАСТЕЙ:', 0];
  const totalBuProducts = ['Б/У ЗАПЧАСТЕЙ:', 0];
  const totalServices = ['РАБОТЫ:', 0];

  const result = [
    total,
    [],
    totalNewProducts,
    totalBuProducts,
    totalServices,
    [],
    ['СПИСОК ПРОДАЖ'],
  ];

  sales.forEach((sale) => {
    // Пустая строка перед каждой продажей
    result.push([]);

    const {
      createdAt,
      paymentMethod,
      comment,
      source,
      client,
      products = [],
      refunds = [],
      services = [],
    } = sale;

    result.push([sale.name]);
    result.push(['Дата продажи', getDate(createdAt)]);
    result.push(['Метод оплаты', paymentMethod]);
    result.push(['Источник', source]);
    result.push(['Автомобиль клиента', client?.car ?? '']);
    result.push(['Комментарий', comment ?? '']);

    const processProducts = (prods: ISaleProduct[] | ISaleManualProduct[], type = INCRIMENT): void => {
      prods.forEach((product) => {
        const {
          name,
          code = '',
          condition,
          salePrice = 0,
          saleCount = 0,
          saleAdditionalCount = 0,
          saleSecondAdditionalCount = 0,
        } = product;

        const totalProductCount = saleCount + saleAdditionalCount + saleSecondAdditionalCount;
        const price = salePrice * totalProductCount;

        total[1] = Number(total[1]) + price;
        
        if (condition === Conditions.BU) {
          if (type === INCRIMENT) {
            totalBuProducts[1] = Number(totalBuProducts[1]) + price;
          }

          if (type === DECRIMENT) {
            totalBuProducts[1] = Number(totalBuProducts[1]) - price;
          }
        }

        if (condition === Conditions.NEW) {
          if (type === INCRIMENT) {
            totalNewProducts[1] = Number(totalNewProducts[1]) + price;
          }

          if (type === DECRIMENT) {
            totalNewProducts[1] = Number(totalNewProducts[1]) - price;
          }
        }

        result.push([
          '',
          '',
          code,
          name,
          totalProductCount,
          salePrice,
          condition,
        ]);
      });
    };

    if (products.length) {
      result.push(PRODUCTS_COLUMNS);

      processProducts(products);
    }

    if (refunds.length) {
      result.push(REFUNDS_COLUMNS);

      refunds.forEach((refund) => {
        const {
          createdAt: refundCreatedAt,
          reason = '',
          products: refundProducts,
        } = refund;

        result.push(['Дата возврата', getDate(refundCreatedAt)], ['Причина возврата', reason]);
        result.push(PRODUCTS_COLUMNS);
        processProducts(refundProducts);
      });

      result.push([]);
    }

    if (services.length) {
      result.push(SERVICES_COLUMNS);

      services.forEach((serv) => {
        const {
          name,
          count,
          price,
        } = serv;


        total[1] = Number(total[1]) + (count * price);
        totalServices[1] = Number(totalServices[1]) + (count * price);

        result.push([
          '',
          '',
          '',
          name,
          count,
          price,
        ]);
      });
    }
  });

  return result;
};