import { notificationService } from 'services';
import { Table, useResize } from '1_shared';
import { ParsingCarStatisticsCards } from '2_entities';
import { getProductsParsingCarTableData } from '3_features';
import { ProductTableParsingCarColumns } from '4_widgets';

interface IParsingCarProductsTab {
  id: string
}

const ParsingCarProductsTab = ({ id }: IParsingCarProductsTab): JSX.Element => {
  const { height } = useResize();

  return (
    <>
      <ParsingCarStatisticsCards id={id} />
      <Table
        columns={ProductTableParsingCarColumns}
        fetchTableData={getProductsParsingCarTableData}
        notificationService={notificationService}
        defaultSortOrder={['changedAt DESC']}
        scroll={{ y: height - 350 }}
      />
    </>
  );
};

export default ParsingCarProductsTab;