import { Tree, PageHeader, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { Pages } from '../../1_shared/constants/pages';
import { dictionaries } from '../../dictionaries';
import { userService } from '../../services';
import { Permissions } from '../../enums/permissions';

export const CarsPage = () => {
  const navigate = useNavigate();

  const handleSelect = (selectedKeys: any, e: any) => {
    const position: Array<string> = e.node.pos.split('-');

    if (position.length === 2) {
      navigate(`${Pages.BRANDS}/${selectedKeys}`);
    }

    if (position.length === 3) {
      navigate(`${Pages.SERIESS}/${selectedKeys}`);
    }

    if (position.length === 4) {
      navigate(`${Pages.MODELS}/${selectedKeys}`);
    }
  };

  return (
    <>
      <PageHeader
        className="page-header"
        title="Автомобили"
        subTitle="Список автомобилей"
        extra={userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) && [
          <Button key="create-brand" type="primary">
            <Link to={Pages.BRAND_CREATE}>Создать марку</Link>
          </Button>,
          <Button key="create-series" type="primary">
            <Link to={Pages.SERIES_CREATE}>Создать серию</Link>
          </Button>,
          <Button key="creat-model" type="primary">
            <Link to={Pages.MODEL_CREATE}>Создать модель</Link>
          </Button>,
        ]}
      />
      <div className='page-content'>
        <Tree
          showLine={{ showLeafIcon: false }}
          treeData={dictionaries.carDictionary.dictionaryForTree}
          onSelect={handleSelect}
        />
      </div>
    </>
  );
};