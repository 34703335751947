import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { userService } from 'services';
import { Permissions } from 'enums/permissions';
import { Pages } from '1_shared';

const ParsingCarCreateButton = (): JSX.Element => {
  const navigate = useNavigate();

  const handleCreate = () => navigate(Pages.PARSING_CAR_CREATE);

  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    return <></>;
  }

  return (
    <Button
      type="primary"
      onClick={handleCreate}
    >
      Добавить
    </Button>
  );
};

export default ParsingCarCreateButton;