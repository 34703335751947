import { Input, Form, TreeSelect, Button, Select, Row, Col } from 'antd';
import { dictionaries } from '../../dictionaries';
import { conditionOptions } from '../../constants/conditionOptions';
import { ProductTableQuery } from '../../interfaces';
import { omitObjectUndefined } from '../../utils/common';
import { statusOptions } from '../../constants/statusOptions';
import { useQuery } from '1_shared';

import styles from './ProductAdvanceSearch.module.scss';

const { Option } = Select;

export const ProductAdvanceSearch = (): JSX.Element => {
  const [form] = Form.useForm();
  const { getQuery, setQuery } = useQuery();

  const handleSearch = () => {
    const { limit = 10, order = ['changedAt DESC'] } = getQuery();

    const newQuery: ProductTableQuery = omitObjectUndefined(form.getFieldsValue());

    if (!newQuery.searchValue) {
      delete newQuery.searchValue;
      delete newQuery.searchType;
    }

    setQuery({
      limit,
      page: 1,
      order,
      ...newQuery,
    });
  };

  const handleCancel = () => {
    const { limit = 10 } = getQuery();
    setQuery({ limit, page: 1 });
  };

  const getInitialValue = () => {
    const { searchValue, searchType, car, type, condition, status, boxNumber } = getQuery();

    return {
      searchValue,
      searchType: searchType ?? 'name',
      car,
      type,
      condition,
      status,
      boxNumber,
    };
  };

  return (
    <Form form={form} initialValues={getInitialValue()} className={styles.advanceSearchForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 12 }}>
        <Col md={24}>
          <Form.Item name="searchValue">
            <Input
              allowClear
              addonBefore={
                <Form.Item name="searchType" noStyle>
                  <Select
                    className={styles.selectBefore}
                  >
                    <Option value="name">По наименованию</Option>
                    <Option value="code">По коду товара</Option>
                    <Option value="originalNumbers">По оригинальному номеру</Option>
                    <Option value="oldOriginalNumbers">По старому оригинальному номеру</Option>
                  </Select>
                </Form.Item>
              }
              placeholder="Поиск по товарам"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 6, sm: 16, md: 24, lg: 12 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item name="car">
            <TreeSelect
              showSearch
              allowClear
              dropdownStyle={{ zIndex: 5 }}
              placeholder="Выберите автомобиль"
              fieldNames={{ label: 'title', value: 'key' }}
              filterTreeNode
              treeNodeFilterProp="title"
              treeData={dictionaries.carDictionary.dictionaryForTree}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6}>
          <Form.Item name="type">
            <TreeSelect
              showSearch
              allowClear
              dropdownStyle={{ zIndex: 5 }}
              placeholder="Выберите категорию"
              fieldNames={{ label: 'title', value: 'key' }}
              filterTreeNode
              treeNodeFilterProp="title"
              treeData={dictionaries.protuctTypesDictionary.dictionaryForTree}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={8} md={6} lg={6} xl={2}>
          <Form.Item name="condition">
            <Select
              allowClear
              placeholder="Состояние"
              options={conditionOptions}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={8} md={6} lg={6} xl={2}>
          <Form.Item name="status">
            <Select
              allowClear
              placeholder="Статус"
              options={statusOptions}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={8} md={6} lg={6} xl={3}>
          <Form.Item name="boxNumber">
            <Input
              allowClear
              placeholder="Номер коробки"
            />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} xl={2} className={styles.actionButton}>
          <Button onClick={handleCancel}>Сбросить</Button>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} xl={2} className={styles.actionButton}>
          <Button type='primary' onClick={handleSearch}>Поиск</Button>
        </Col>
      </Row>
    </Form>
  );
};