const elementOrEmpty = (element: any) =>
  (typeof element === 'undefined' || element === null) ? '' : element;

const joiner = ((data: Array<Array<string | number>>) => {
  return data
    .filter(e => e)
    .map(
      row => row
        .map((element) => elementOrEmpty(element))
        .map(column => `"${column}"`)
        .join(';'),
    )
    .join('\n');
});

export const downloadCsv = (data: Array<Array<string | number>>, filename: string) => {
  const csvDataJoined = joiner(data);
      
  const url = window.URL.createObjectURL(new Blob([csvDataJoined], { type: 'text/csv;charset=utf-8' }));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};