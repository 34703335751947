import { useQuery } from '1_shared';
import { Form, Input } from 'antd';
import { getSearchValue } from 'utils/data';

const { Search } = Input;

let timeout: ReturnType<typeof setTimeout>;

const SearchParsingCars = () => {
  const [form] = Form.useForm();
  const { getQuery, setQuery } = useQuery();

  const handleSearch = (event: any) => {
    const { target: { value } } = event;
    const { limit = 10, order = ['name ASC'] } = getQuery();

    if (!value) {
      setQuery({ limit, page: 1 });

      return;
    }

    if (value.length > 2) {
      if (timeout) {
        clearTimeout(timeout);
      }

      const resultValue = getSearchValue(value);

      timeout = setTimeout(() => setQuery({
        limit,
        page: 1,
        order,
        searchValue: resultValue,
      }), 300);
    }
  };

  const getInitialValue = () => {
    const query = getQuery();

    return { searchValue: query.searchValue ?? '' };
  };

  return (
    <Form form={form} initialValues={getInitialValue()}>
      <Form.Item style={{ width: '100%' }} name="searchValue">
        <Search
          onChange={handleSearch}
          placeholder="Поиск по наименованию"
          allowClear
        />
      </Form.Item>
    </Form>
  );
};

export default SearchParsingCars;