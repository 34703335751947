import { Form, PageHeader } from 'antd';
import { useParams } from 'react-router-dom';
import { SaleCancelButton, SaleCreateInvoiceButton } from '2_entities';
import { CreateEditSaleForm, RefundCreateButton, SaveSaleButton } from '3_features';

const SaleCreateEditPage = () => {
  const param: any = useParams();
  const [form] = Form.useForm();
  const isNew: boolean = !param.id;
  const subTitle: string = isNew ? 'Создание продажи' : 'Просмотр продажи';  

  return (
    <>
      <PageHeader
        className="page-header"
        title="Продажи"
        subTitle={subTitle}
        extra={[
          <SaleCancelButton key='cancel-sale-button' />,
          <SaleCreateInvoiceButton key='create-invoice-biutton' id={param.id} />,
          <RefundCreateButton key='create-refund-button' id={param.id} />,
          <SaveSaleButton key='save-sale-button' form={form} id={param.id} />,
        ]}
      />
      <div className='page-content'>
        <CreateEditSaleForm form={form} isNew={isNew} />
      </div>
    </>
  );
};

export default SaleCreateEditPage;