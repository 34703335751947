import { Editor } from '@tinymce/tinymce-react';
import { Permissions } from '../../enums/permissions';
import { userService } from '../../services';

type Props = {
  initialValue: string;
  height?: number;
  onChange?: (content: string) => void;
};

export const RichTextEditor = ({
  initialValue = '',
  height = 400,
  onChange = () => { },
}: Props) => {
  const handleEditorChange = (e: any) => {
    const content = e.target.getContent();
    onChange(content);
  };

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
      initialValue={initialValue}
      licenseKey='gpl'
      // apiKey="0bvstkquzg051b00ab682x548mezh7k5dobsuxvv81io0lr3"
      init={{
        language_url: '/tinymce/langs/ru.js',
        language: 'ru',
        height,
        plugins:
          'advlist autolink lists link image charmap preview anchor help searchreplace visualblocks code insertdatetime media table wordcount',
        toolbar:
          'undo redo | formatselect | bold italic | fontfamily fontsize | alignleft aligncenter alignright | bullist numlist outdent indent | help code table',
      }}
      onChange={handleEditorChange}
    />
  );
};
