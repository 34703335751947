import { Button, PageHeader, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';

import { Spinner } from '../../components/Spinner';
import { useFetchData } from '../../hooks/useFetchData';
import { getDate, Pages } from '1_shared';
import { userService } from '../../services';
import { Permissions } from '../../enums/permissions';
import { ACL } from '../../components/ACL';

type TableItem = {
  createdAt: string;
  deleted: boolean;
  email: string;
  emailVerified: boolean;
  id: string;
  roles: string[];
  username: string;
};

type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

const roleFilters = [
  {
    text: 'Супер Админ',
    value: 'super_admin',
    color: 'red',
  },
  {
    text: 'Администратор',
    value: 'admin',
    color: 'red',
  },
  {
    text: 'Менеджер',
    value: 'manager',
    color: 'blue',
  },
  {
    text: 'Механик',
    value: 'mechanic',
    color: 'gray',
  },
  {
    text: 'Пользователь',
    value: 'user',
    color: 'green',
  },
];

const columns: Column[] = [
  {
    title: 'Имя',
    dataIndex: 'username',
    key: 'username',
    render: (username: string, record: TableItem) => {
      const url = `${Pages.USERS}/${record.id}`;

      return <Link to={url}>{username}</Link>;
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Роль',
    dataIndex: 'roles',
    key: 'roles',
    filters: roleFilters,
    onFilter: (value: string | number | boolean, record: TableItem) => record.roles.includes(value as string),
    render: (roles: string[]) => {
      const tags = roles.map(role => roleFilters.find(item => item.value === role) || {
        text: 'Администратор',
        value: 'admin',
        color: 'red',
      });

      return tags.map(tag => (
        <Tag color={tag.color} key={tag.text}>
          {tag?.text}
        </Tag>
      ));
    },
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 200,
    render: (createdAt: string) => getDate(createdAt),
  },
];

export const UsersPage = () => {
  const { isLoading, data = [] } = useFetchData('/users');

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Список пользователей"
          extra={userService.hasAccess([Permissions.SUPER_ADMIN])
            && [
              <Button
                key="createUser"
                type="primary"
              >
                <Link to={Pages.USER_CREATE}>
                  Создать пользователя
                </Link>
              </Button>,
            ]}
        />
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
        />
      </>
    </ACL>
  );
};
