export const modalFormLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};

export const commonLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const commonVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

