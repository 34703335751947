import { useState } from 'react';
import { Button, Spin } from 'antd';
import moment from 'moment';
import { notificationService } from 'services';
import { getSale, InvoicePdfCreator, ISale } from '2_entities';

interface ISaleCreateInvoiceButton {
  id?: string;
}

const SaleCreateInvoiceButton = ({ id }: ISaleCreateInvoiceButton): JSX.Element => {
  const [disabled, setDisabled] = useState(false);

  if (!id) {
    return <></>;
  }

  const handlePdfCreate = async () => {
    setDisabled(true);

    try {
      const data = await getSale<ISale>(id);

      const {
        name,
        createdAt,
        products = [],
        services = [],
      } = data;

      const date = moment(createdAt).format('DD.MM.YYYY');

      const invoiceName = `Накладная № ${name.slice(1)} от ${date}`;

      const invoice = new InvoicePdfCreator()
        .addQRCode(data.id)
        .addMainHeader(invoiceName);

      if (products.length) {
        invoice
          .addProductsTable(products)
          .addWarranty()
          .addSignatures();
      }

      if (services.length) {
        invoice.addServicesTable(services);
      }
       
      invoice.save(`${invoiceName}.pdf`);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      type="dashed"
      onClick={handlePdfCreate}
      disabled={disabled}
    >
      {disabled && (<Spin />)}
      Сформировать накладную
    </Button>
  );
};

export default SaleCreateInvoiceButton;