import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Product } from 'interfaces';
import { Pages } from '1_shared';

interface IProductTableCopyButton {
  product: Partial<Product>
}

export const ProductTableCopyButton = ({ product }: IProductTableCopyButton): JSX.Element => (
  <Tooltip placement="bottom" title="Копировать товар">
    <Button type="dashed" size="small" style={{ marginTop: 12 }}>
      <Link to={Pages.PRODUCT_CREATE} state={{ product }}>
        <CopyOutlined />
      </Link>
    </Button>
  </Tooltip>
);