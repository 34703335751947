import { ITableFetchResponse } from '1_shared';
import { apiService } from 'services';

interface ICount {
  count: number
}

export const getSales = <T>(query: any): Promise<T[]> =>
  apiService.GET(`/sales?filter=${JSON.stringify({ order: ['createdAt DESC'], ...query })}`);

export const getSale = <T>(id: string): Promise<T> =>
  apiService.GET(`/sales/${id}`);

export const createSale = <T>(body: any): Promise<T> => apiService.POST('/sales', body);

export const updateSale = <T>(body: any, id: string): Promise<T> =>
  apiService.PATCH(`/sales/${id}`, body);

export const getSalesCount = (query?: unknown): Promise<ICount> => {
  if (query) {
    return apiService.GET(`/sales/count?where=${JSON.stringify(query)}`);
  }

  return apiService.GET('/sales/count');
};

export const getSalesTableData = async <T>(query: any): Promise<ITableFetchResponse<T>> => {
  const { 
    limit, 
    offset, 
    order, 
    hasStartersAndGenerators,
    startDate,
    endDate,
  } = query;

  const where: any = {
    hasStartersAndGenerators,
  };

  if (startDate && endDate) {
    where.and = [{
      createdAt: {
        gte: startDate,
      },
    }, {
      createdAt: {
        lt: endDate,
      },
    }];
  }

  try {
    const data = await getSales({ limit, offset, order, where });
    const totalData = await getSalesCount(where);

    return {
      data: data as T[],
      totalCount: totalData.count,
    };
  } catch (error) {
    throw error;
  }
};