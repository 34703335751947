import { BaseDictionaryStore } from './BaseDictionaryStore';
import { mapToTreeFromData } from '../utils/tree';
import { sortByTitle } from '../utils/sort';

export class CarDictionary extends BaseDictionaryStore {
  name: string = 'cars';

  brands: Array<any> = [];

  series: Array<any> = [];

  models: Array<any> = [];

  applicabilityOptions: Array<any> = [];

  dictionaryForTree: Array<any> = [];

  afterLoad = (data: any): void => {
    this.brands.length = 0;
    this.series.length = 0;
    this.models.length = 0;
    this.applicabilityOptions.length = 0;
    this.dictionaryForTree.length = 0;

    data.items.forEach((item: any) => {
      this.brands.push({ value: item.id, label: item.name, alias: item.alias });

      item.items.forEach((element: any) => {
        if (element.items && !element.items.length) {
          this.applicabilityOptions.push({
            value: element.id,
            label: element.name,
            alias: element.alias,
            parentId: item.id,
          });
        }

        this.series.push({
          value: element.id,
          label: element.name,
          alias: element.alias,
          parentId: item.id,
        });

        if (element.items) {
          const elementsModels = element.items.map((e: any) => ({
            value: e.id,
            label: e.name,
            alias: e.alias,
            parentId: item.id,
          }));

          this.models.push(...elementsModels);

          this.applicabilityOptions.push(...elementsModels);
        }
      });

      this.dictionaryForTree.push({
        title: item.name,
        key: item.id,
        children: item.items && item.items.map(mapToTreeFromData),
      });
    });

    this.dictionaryForTree.sort(sortByTitle);
  };
}