import { FC } from 'react';
import { Tabs } from 'antd';

import { SeoTab } from '../SeoTab';
import { TypeGeneralTab } from './TypeGeneralTab';

type Props = {
  generalTabProps: { data: any; form: any };
};

export const TypeTabs: FC<Props> = ({ generalTabProps }) => (
  <Tabs
  defaultActiveKey="1"
  type="card"
  tabBarGutter={5}
  items={[
    {
      key: 'generalTab',
      label: 'Основное',
      children: <TypeGeneralTab {...generalTabProps} />,
    },
    {
      key: 'seoTab',
      label: 'SEO',
      children: <SeoTab />,
    },
  ]}
/>
);
