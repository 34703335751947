export enum SaleSourceEnum {
  AVITO = 'Avito',
  DROM = 'Drom',
  SITE = 'Сайт',
  OFFLINE = 'Оффлайн',
}

export enum SalePaymentMethod {
  CASH = 'Наличными',
  BANK = 'Переводом на карту',
  BANK_TERMINAL = 'Картой через терминал',
  AVITO = 'Через Авито',
}