import { rubles } from 'rubles';
import autoTable, { RowInput, Styles } from 'jspdf-autotable';
import QRCode from 'qrcode';
import { PdfCreator, ISaleManualProduct, ISaleProduct } from '1_shared';
import { ISaleService } from '2_entities';

export class InvoicePdfCreator extends PdfCreator {
  public addQRCode = (id: string) => {
    let qrCode: string = '';
    QRCode.toDataURL(id, function (err, string) {
      if (err) throw err;
      qrCode = string;
    });

    this.document.addImage(qrCode, 'JPEG', 175, 3, 25, 25);

    return this;
  };

  public addProductsTable = (products: ISaleProduct[] | ISaleManualProduct[]) => {
    const head: RowInput[] = [
      [
        { content: '№' },
        { content: 'Товар', styles: { halign: 'center' } },
        { content: 'Кол-во' },
        { content: 'Ед.' },
        { content: 'Цена (руб)', styles: { minCellWidth: 20 } },
        { content: 'Сумма (руб)', styles: { minCellWidth: 22 } },
      ],
    ];

    const headStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fillColor: undefined,
      textColor: 20,
      lineWidth: 0.1,
    };

    let totalPrice = 0;

    const body: RowInput[] = products.map((product, index) => {
      const {
        name,
        code,
        salePrice,
        saleCount = 0,
        saleAdditionalCount = 0,
        saleSecondAdditionalCount = 0,
      } = product;

      const totalProductCount = saleCount + saleAdditionalCount + saleSecondAdditionalCount;
      const totalProductPrice = salePrice * totalProductCount;
      totalPrice += totalProductPrice;

      return [
        { content: index + 1 },
        { content: `${name} \n${code ? `(Код товара: ${code})` : ''}` },
        { content: totalProductCount, styles: { halign: 'center' } },
        { content: 'шт' },
        { content: salePrice, styles: { halign: 'right' } },
        { content: totalProductPrice, styles: { halign: 'right' } },
      ];
    });

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fillColor: undefined,
      textColor: 20,
    };

    const foot: RowInput[] = [
      [
        {
          content: 'Итого:',
          colSpan: 5,
          styles: { halign: 'right' },
        },
        {
          content: `${totalPrice} руб.`,
          styles: { halign: 'right' },
        },
      ],
      [
        {
          content: `Всего наименований ${products.length}, на сумму ${totalPrice} руб. (${rubles(totalPrice)})`,
          colSpan: 6,
        },
      ],
    ];

    const footStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fillColor: undefined,
      textColor: 20,
    };

    autoTable(this.document, {
      head,
      headStyles,
      body,
      bodyStyles,
      foot,
      footStyles,
      theme: 'grid',
    });

    return this;
  };

  public addServicesTable = (services: ISaleService[]) => {
    const head: RowInput[] = [
      [
        { content: '№' },
        { content: 'Услуга', styles: { halign: 'center' } },
        { content: 'Кол-во' },
        { content: 'Ед.' },
        { content: 'Цена (руб)', styles: { minCellWidth: 20 } },
        { content: 'Сумма (руб)', styles: { minCellWidth: 22 } },
      ],
    ];

    const headStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fillColor: undefined,
      textColor: 20,
      lineWidth: 0.1,
    };

    let totalPrice = 0;

    const body: RowInput[] = services.map((serv, index) => {
      const {
        name,
        count = 0,
        price = 0,
      } = serv;

      const totalServicesPrice = count * price;
      totalPrice += totalServicesPrice;

      return [
        { content: index + 1 },
        { content: name },
        { content: count, styles: { halign: 'center' } },
        { content: 'шт' },
        { content: price, styles: { halign: 'right' } },
        { content: totalServicesPrice, styles: { halign: 'right' } },
      ];
    });

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fillColor: undefined,
      textColor: 20,
    };

    const foot: RowInput[] = [
      [
        {
          content: 'Итого:',
          colSpan: 5,
          styles: { halign: 'right' },
        },
        {
          content: `${totalPrice} руб.`,
          styles: { halign: 'right' },
        },
      ],
      [
        {
          content: `Всего услуг ${services.length}, на сумму ${totalPrice} руб. (${rubles(totalPrice)})`,
          colSpan: 6,
        },
      ],
    ];

    const footStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fillColor: undefined,
      textColor: 20,
    };

    autoTable(this.document, {
      head,
      headStyles,
      body,
      bodyStyles,
      foot,
      footStyles,
      theme: 'grid',
    });

    return this;
  };

  public addWarranty = () => {
    const warrantyTextBu = `ВОЗВРАТ ТОВАРА, БЫВШЕГО В УПОТРЕБЛЕНИИ:
    На товар, бывший в употреблении, гарантии не распространяются. Товар передается потребителю "как есть".
    Товар может содержать неисправности, которые возможно определить с использованием специального инструмента или при установке на автомобиль.
    Потребитель своими силами и за свой счет осуществляет установку и проверку товара.
    Возврат товара осуществляется в срок до 7 дней при условии сохранения всех наклеек и отметок на товаре, сохранения товарного вида и комплектности товара в момент продажи.`;

    const warrantyTextNew = `ВОЗВРАТ НОВОГО ТОВАРА:
    Статья 25 закона "О защите прав потребителей" пункт 1.
    Обмен непродовольственных товаров производится в течение 14 дней, если указанный товар не был в употреблении, сохранены его товарный вид, потребительские свойства, фабричные свойства, фабричные ярлыки, а так же товарный чек или кассовый чек, выданные потребителю вместе с проданным указанным товаром.
    Установка товаров должна производиться только на станциях тех. обслуживания, имеющих лицензию на производство данных работ.
    Неисполнение этого пункта рассматривается как нарушение правил использования товара и не позволяет магазину выполнить гарантийные обязательства.`;

    const body: RowInput[] = [
      [
        { content: warrantyTextBu, styles: { halign: 'left' } },
      ],
      [
        { content: warrantyTextNew, styles: { halign: 'left' } },
      ],
    ];

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fontSize: 8,
      fillColor: undefined,
      textColor: 20,
    };

    autoTable(this.document, {
      body,
      bodyStyles,
      theme: 'plain',
    });

    return this;
  };

  public addSignatures = () => {
    const body: RowInput[] = [
      [{ content: 'Претензий к внешнему виду и комплектности товара не имею', styles: { fontStyle: 'bold' } }],
      [{ content: '' }],
      [{ content: 'Подпись клиента' }],
    ];

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fillColor: undefined,
      textColor: 20,
    };

    autoTable(this.document, {
      body,
      bodyStyles,
    });

    return this;
  };
}