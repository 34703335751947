import { PageHeader } from 'antd';
import { notificationService } from 'services';
import { Table, useResize } from '1_shared';
import {
  SaleCreateButton,
  SaleFilters,
  getSalesTableData,
} from '2_entities';
import { SaleTableColumns } from '4_widgets';
import { CreateSalesReportModal } from '3_features';

import styles from './SalesPage.module.scss';

const SalesPage = () => {
  const { width, height, isScreenLg } = useResize();

  return (
    <>
      <PageHeader
        className="page-header"
        title="Продажи"
        subTitle="Список продаж"
        extra={[
          <SaleCreateButton key="sale-create" />,
          <CreateSalesReportModal key="create-report" />,
        ]}
      />
      <SaleFilters />
      <div className={styles.salesTable}>
        <Table
          columns={SaleTableColumns}
          fetchTableData={getSalesTableData}
          defaultSortOrder={['createdAt DESC']}
          notificationService={notificationService}
          rowClassName={(_, index) => (index + 1) % 2 === 0 ? 'even-row' : ''}
          scroll={isScreenLg ? { y: height - 250 } : { x: width, y: 630 }}
        />
      </div>
    </>
  );
};

export default SalesPage;