import { makeAutoObservable } from 'mobx';

export class UserStore {
  id: string = '';

  name: string = '';

  email: string = '';

  phone: string = '';

  roles: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fillStore = (data: any): void => {
    // @ts-ignore: Unreachable code error
    Object.keys(data).forEach((key: string) => { this[key] = data[key]; });
  };
}