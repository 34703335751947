import { HomeOutlined } from '@ant-design/icons';

import { Pages } from './1_shared/constants/pages';
import { HomePage } from './5_pages/HomePage';
import { UsersPage } from './5_pages/UsersPage';
import { UserPage } from './5_pages/UsersPage/UserPage';
import { ProductsPage } from './5_pages/ProductsPage';
import { ProductEditPage } from './5_pages/ProductsPage/ProductEditPage/ProductEditPage';
import { TypesPage } from './5_pages/TypesPage';
import { CategoryEditPage } from './5_pages/TypesPage/CategoryEditPage';
import { TypeEditPage } from './5_pages/TypesPage/TypeEditPage';
import { CarsPage } from './5_pages/CarsPage';
import { BrandEditPage } from './5_pages/CarsPage/BrandEditPage';
import { ModelEditPage } from './5_pages/CarsPage/ModelEditPage';
import { SeriesEditPage } from './5_pages/CarsPage/SeriesEditPage';
import { OrdersPage } from './5_pages/OrdersPage';
import { OrderEditPage } from './5_pages/OrdersPage/OrderEditPage';
import { PagesPage } from './5_pages/PagesPage';
import { CarServicePage } from './5_pages/CarServicePage';
import { ServicesPage } from './5_pages/CarServicePage/ServicesPage';
import { CompletedWorksPage } from './5_pages/CarServicePage/CompletedWorksPage';
import { ServicesEditPage } from './5_pages/CarServicePage/ServicesPage/ServicesEditPage';
import { CompletedWorkEditPage } from './5_pages/CarServicePage/CompletedWorksPage/CompletedWorkEditPage';
import { BlogsPage } from './5_pages/BlogsPage';
import { BlogEditPage } from './5_pages/BlogsPage/BlogEditPage';
import { ServiceOrdersPage } from './5_pages/CarServicePage/ServiceOrdersPage';
import { ParsingCarsPage } from './5_pages/ParsingCarsPage';
import { ParsingCarsEditPage } from './5_pages/ParsingCarsPage/ParsingCarsEditPage';
import { GroupProductsPage } from './5_pages/GroupProductsPage';
import { SaleCreateEditPage, SalesPage } from '5_pages';

export const routes = [
  {
    path: Pages.HOME,
    element: <HomePage />,
    breadcrumb: HomeOutlined,
  },
  {
    path: Pages.PAGES,
    element: <PagesPage />,
    breadcrumb: 'Статические страницы',
  },
  // {
  //   path: Pages.PAGE,
  //   element: <PagesEditPage />,
  //   breadcrumb: 'Редактирование статической страницы',
  // },
  // USERS PAGE
  {
    path: Pages.USERS,
    element: <UsersPage />,
    breadcrumb: 'Пользователи',
  },
  {
    path: Pages.USER_CREATE,
    element: <UserPage />,
    breadcrumb: 'Создание пользователя',
  },
  {
    path: Pages.USER,
    element: <UserPage />,
    breadcrumb: 'Редактивароние пользователя',
  },
  // PRODUCTS PAGE
  {
    path: Pages.PRODUCTS,
    element: <ProductsPage />,
    breadcrumb: 'Товары',
  },
  {
    path: Pages.PRODUCT_CREATE,
    element: <ProductEditPage />,
    breadcrumb: 'Создание товара',
  },
  {
    path: Pages.PRODUCT,
    element: <ProductEditPage />,
    breadcrumb: 'Редактирование товара',
  },
  // TYPES PAGE
  {
    path: Pages.TYPES,
    element: <TypesPage />,
    breadcrumb: 'Типы товаров',
  },
  {
    path: Pages.TYPE_CREATE,
    element: <TypeEditPage />,
    breadcrumb: 'Создание типа товаров',
  },
  {
    path: Pages.TYPE,
    element: <TypeEditPage />,
    breadcrumb: 'Редактирование типа товаров',
  },
  {
    path: Pages.CATEGORY_CREATE,
    element: <CategoryEditPage />,
    breadcrumb: 'Создание категории товаров',
  },
  {
    path: Pages.CATEGORY,
    element: <CategoryEditPage />,
    breadcrumb: 'Редактирование категории товаров',
  },
  // CARS PAGE
  {
    path: Pages.CARS,
    element: <CarsPage />,
    breadcrumb: 'Автомобили',
  },
  {
    path: Pages.BRAND_CREATE,
    element: <BrandEditPage />,
    breadcrumb: 'Создание марки автомобиля',
  },
  {
    path: Pages.BRAND,
    element: <BrandEditPage />,
    breadcrumb: 'Редактирование марки автомобиля',
  },
  {
    path: Pages.SERIES_CREATE,
    element: <SeriesEditPage />,
    breadcrumb: 'Создание серии автомобиля',
  },
  {
    path: Pages.SERIES,
    element: <SeriesEditPage />,
    breadcrumb: 'Редактирование серии автомобиля',
  },
  {
    path: Pages.MODEL_CREATE,
    element: <ModelEditPage />,
    breadcrumb: 'Создание модели автомобиля',
  },
  {
    path: Pages.MODEL,
    element: <ModelEditPage />,
    breadcrumb: 'Редактирование модели автомобиля',
  },
  // ORDERS PAGE
  {
    path: Pages.ORDERS,
    element: <OrdersPage />,
    breadcrumb: 'Заказы',
  },
  {
    path: Pages.ORDER_CREATE,
    element: <OrderEditPage />,
    breadcrumb: 'Создание заказа',
  },
  {
    path: Pages.ORDER,
    element: <OrderEditPage />,
    breadcrumb: 'Редактирование заказа',
  },
  // CAR SERVICE PAGE
  {
    path: Pages.CAR_SERVICE,
    element: <CarServicePage />,
    breadcrumb: 'Автосервис',
  },
  {
    path: Pages.SERVICES,
    element: <ServicesPage />,
    breadcrumb: 'Услуги',
  },
  {
    path: Pages.SERVICES_CREATE,
    element: <ServicesEditPage />,
    breadcrumb: 'Создание услуги',
  },
  {
    path: Pages.SERVICES_ITEM,
    element: <ServicesEditPage />,
    breadcrumb: 'Редактирование услуги',
  },
  {
    path: Pages.COMPLETED_WORKS,
    element: <CompletedWorksPage />,
    breadcrumb: 'Выполненные работы',
  },
  {
    path: Pages.COMPLETED_WORK_CREATE,
    element: <CompletedWorkEditPage />,
    breadcrumb: 'Создание работы',
  },
  {
    path: Pages.COMPLETED_WORK,
    element: <CompletedWorkEditPage />,
    breadcrumb: 'Редактирование работы',
  },
  {
    path: Pages.CAR_SERVICE_ORDERS,
    element: <ServiceOrdersPage />,
    breadcrumb: 'Онлайн записи',
  },
  {
    path: Pages.CAR_SERVICE_ORDER,
    element: <OrderEditPage />,
    breadcrumb: 'Просмотр онлайн записи',
  },
  // BLOG PAGE
  {
    path: Pages.BLOGS,
    element: <BlogsPage />,
    breadcrumb: 'Блог',
  },
  {
    path: Pages.BLOG_CREATE,
    element: <BlogEditPage />,
    breadcrumb: 'Создание записи',
  },
  {
    path: Pages.BLOG,
    element: <BlogEditPage />,
    breadcrumb: 'Редактирование записи',
  },
  // PARSING CARS PAGE
  {
    path: Pages.PARSING_CARS,
    element: <ParsingCarsPage />,
    breadcrumb: 'Автомобили в разборе',
  },
  {
    path: Pages.PARSING_CAR_CREATE,
    element: <ParsingCarsEditPage />,
    breadcrumb: 'Создание записи',
  },
  {
    path: Pages.PARSING_CAR,
    element: <ParsingCarsEditPage />,
    breadcrumb: 'Редактирование записи',
  },
  // Group Products
  {
    path: Pages.GROUP_PRODUCTS,
    element: <GroupProductsPage />,
    breadcrumb: 'Груповая работа с товаром',
  },
  // SALES
  {
    path: Pages.SALES,
    element: <SalesPage />,
    breadcrumb: 'Продажи',
  },
  {
    path: Pages.SALE_CREATE,
    element: <SaleCreateEditPage />,
    breadcrumb: 'Создание продажи',
  },
  {
    path: Pages.SALE,
    element: <SaleCreateEditPage />,
    breadcrumb: 'Просмотр продажи',
  },
];