import { Link } from 'react-router-dom';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { ISaleManualProduct, ISaleProduct, Pages, getDate } from '1_shared';
import { ISale, ISaleClient, ISaleService, ProductSaleCell } from '2_entities';

type TableItem = Partial<ISale>;
type Column = ColumnGroupType<TableItem> | ColumnType<TableItem>;

export const SaleTableColumns: Column[] = [
  {
    title: '#',
    dataIndex: 'name',
    key: 'name',
    width: 70,
    render: (name: string, record: TableItem) => {
      const url = `${Pages.SALES}/${record.id}`;

      return (
        <>
          <div>
            <Link to={url}> {name}</Link>
          </div>
        </>
      );
    },
    sorter: true,
  },
  {
    title: 'Список товаров',
    dataIndex: 'products',
    key: 'products',
    width: 400,
    render: (products: ISaleProduct[] | ISaleManualProduct[], record: Partial<ISale>) => 
      <ProductSaleCell products={products} refunds={record.refunds} />,
    sorter: false,

  },
  {
    title: 'Список услуг',
    dataIndex: 'services',
    key: 'services',
    width: 250,
    render: (services: ISaleService[]) => {
      if (!services || !services.length) {
        return <></>;
      }

      let totalPrice = 0;

      const servicesList = services.map((product, key) => {
        const {
          count = 0,
          price = 0,
          name,
        } = product;

        totalPrice += price * count;

        return (
          <div
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderBottom: '1px solid lightgray',
            }}
          >
            <div>{name}</div>
            <div>
              <span>Цена: <b>{price}</b></span>
              <span> &mdash; </span>
              <span>Кол-во: <b>{count} шт</b></span>
            </div>
          </div>
        );
      });

      return (
        <>
          {servicesList}
          <br />
          <span>На сумму: <b>{totalPrice} руб</b></span>
        </>
      );
    },
    sorter: false,
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    width: 230,
    render: (client: ISaleClient) => {
      return (
        <div>
          {client.name && <div>ФИО: <b>{client.name}</b></div>}
          {client.car && <div>Авто: <b>{client.car}</b></div>}
          {client.vin && <div>VIN: <b>{client.vin}</b></div>}
          {client.phone && <div>Телефон: <b>{client.phone}</b></div>}
          {client.email && <div>Email: <b>{client.email}</b></div>}
        </div>
      );
    },
  },
  {
    title: 'Источник продажи',
    dataIndex: 'source',
    key: 'source',
    width: 100,
    align: 'center',
  },
  {
    title: 'Метод оплаты',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    width: 130,
    align: 'center',
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
    width: 230,
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string, record: TableItem) => {
      const { createdBy } = record;
      const name = createdBy ? `(${createdBy?.name})` : '';

      return <span>{getDate(createdAt)} {name}</span>;
    },
    width: 100,
    responsive: ['lg', 'xl', 'xxl'],
    defaultSortOrder: 'descend',
    sorter: true,
  },
];