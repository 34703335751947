type Option = {
  label: string,
  value: string | number
};

export const transformEnumToOptions = (enumarable: any): Option[] => {
  const keys = Object.keys(enumarable);

  return keys.map((key) => {
    const value = enumarable[key];

    return { label: value, value };
  });
};