export const getParsingCarQuery = ({
  limit,
  offset,
  order,
  searchValue,
}: any) => {
  const query: any = {
    limit,
    offset,
  };

  if (order) {
    query.order = order;
  }

  if (searchValue) {
    query.where = {
      name: { like: searchValue, options: 'i' },
    };
  }

  return query;
};