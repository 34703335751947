import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Pages } from '1_shared';

const SaleCancelButton = () => {
  return (
    <Button type="default" danger>
      <Link to={Pages.SALES}>
        Закрыть
      </Link>
    </Button>
  );
};

export default SaleCancelButton;