import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormInstance } from 'antd';
import { Permissions } from 'enums/permissions';
import { notificationService, userService } from 'services';
import { Pages } from '1_shared';
import { ISale, createSale, updateSale } from '2_entities';

interface ISaveSaleButton {
  form: FormInstance;
  id: string;
}

const SaveSaleButton = ({ form, id }: ISaveSaleButton): JSX.Element => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  if (!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])) {
    return <></>;
  }

  const handleSave = async () => {
    setDisabled(true);

    try {
      await form.validateFields();

      const body = form.getFieldsValue();

      const { createdAt } = body;

      if (createdAt && !id) {
        body.createdAt = createdAt.format();
      }

      if (!id) {
        const createdSale = await createSale<ISale>(body);

        navigate(`${Pages.SALES}/${createdSale.id}`);

        notificationService.notifySuccess('Продажа успешно создана');
      } else {
        await updateSale(body, id);

        notificationService.notifySuccess('Продажа успешно сохранена');
      }
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setDisabled(false);
    }
  };

  const buttonTitle = id ? 'Обновить продажу' : 'Провести продажу';

  return (
    <Button
      type="primary"
      onClick={handleSave}
      disabled={disabled}
    >
      {buttonTitle}
    </Button>
  );
};

export default SaveSaleButton;