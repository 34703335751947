import logo from '../../logo.svg';

import styles from './Spinner.module.scss';

export const Spinner = () => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <img src={logo} className="App-logo" alt="logo" />
    </div>
    <div>
      <h3>Загрузка...</h3>
    </div>
  </div>
);
