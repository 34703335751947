import { AnyObject } from '../interfaces';

export const omitObjectUndefined = (object: AnyObject) => {
  const result = {};

  for (const [key, value] of Object.entries(object)) {
    if (value !== undefined) {
      result[key] = value;
    }
  }

  return result;
};

export const isObjectNotEmpty = (object: AnyObject) => {
  const keys = Object.keys(object);

  return keys.length > 0;
};