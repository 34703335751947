import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Pages } from '1_shared';
import { Product } from 'interfaces';

interface IProductTableEditButton {
  product: Partial<Product>
}

export const ProductTableEditButton = ({ product }: IProductTableEditButton) => (
  <Tooltip placement="top" title="Редактировать товар">
    <Button type="dashed" size="small">
      <Link to={`${Pages.PRODUCTS}/${product.id}`} state={{ product }}>
        <EditOutlined />
      </Link>
    </Button>
  </Tooltip>
);