import { Card } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../1_shared/constants/pages';

import styles from './ServicesCard.module.scss';

type Services = {
  id: string;
  name: string;
  [key: string]: any;
};

type Props = {
  services: Services
};

export const ServicesCard: FC<Props> = ({ services }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${Pages.SERVICES}/${services.id}`);
  };

  return (
    <Card className={styles.card} onClick={handleClick} hoverable>
      {services.name}
    </Card>
  );
};