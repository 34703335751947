export const Pages = {
  HOME: '/',
  // USERS PAGES
  USERS: '/users',
  USER_CREATE: '/users/create',
  USER: '/users/:id',
  // PAGES PAGE
  PAGES: '/pages',
  PAGE_CREATE: '/pages/create',
  PAGE: '/pages/:id',
  // PRODUCTS PAGE
  PRODUCTS: '/products',
  PRODUCT_CREATE: '/products/create',
  PRODUCT: '/products/:id',
  // TYPES PAGE
  TYPES: '/product-types',
  TYPE_CREATE: '/product-types/create',
  TYPE: '/product-types/:id',
  CATEGORIES: '/product-categories',
  CATEGORY_CREATE: '/product-categories/create',
  CATEGORY: '/product-categories/:id',
  // CARS PAGE
  CARS: '/cars',
  BRANDS: '/cars/brands',
  BRAND_CREATE: '/cars/brands/create',
  BRAND: '/cars/brands/:id',
  SERIESS: '/cars/series',
  SERIES_CREATE: '/cars/series/create',
  SERIES: '/cars/series/:id',
  MODELS: '/cars/models',
  MODEL_CREATE: '/cars/models/create',
  MODEL: '/cars/models/:id',
  // ORDES PAGE
  ORDERS: '/orders',
  ORDER_CREATE: '/orders/create',
  ORDER: '/orders/:id',
  // CAR SERVICE
  CAR_SERVICE: '/car-service',
  SERVICES: '/car-service/services',
  SERVICES_CREATE: '/car-service/services/create',
  SERVICES_ITEM: '/car-service/services/:id',
  COMPLETED_WORKS: '/car-service/completed-works',
  COMPLETED_WORK_CREATE: '/car-service/completed-works/create',
  COMPLETED_WORK: '/car-service/completed-works/:id',
  CAR_SERVICE_ORDERS: '/car-service/service-orders',
  CAR_SERVICE_ORDER: '/car-service/service-orders/:id',
  // Blog
  BLOGS: '/blogs',
  BLOG: '/blogs/:id',
  BLOG_CREATE: '/blogs/create',
  // Parsing Car
  PARSING_CARS: '/parsing-cars',
  PARSING_CAR: '/parsing-cars/:id',
  PARSING_CAR_CREATE: '/parsing-cars/create',
  // Group Products
  GROUP_PRODUCTS: '/group-products',
  // Sales
  SALES: '/sales',
  SALE: '/sales/:id',
  SALE_CREATE: '/sales/create',
};