import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PTSans, PTSansBold } from '1_shared/constants';

class PdfCreator {
  constructor() {
    this.document.addFileToVFS('PTSans-Regular.ttf', PTSans);
    this.document.addFileToVFS('PTSans-Regular-Bold.ttf', PTSansBold);
    this.document.addFont('PTSans-Regular.ttf', 'PTSans', 'normal');
    this.document.addFont('PTSans-Regular-Bold.ttf', 'PTSans', 'bold');

    this.document.setFont('PTSans', 'normal');
  }

  document = new jsPDF();

  public addMainHeader = (title: string) => {
    autoTable(this.document, {
      body: [
        [{
          content: title,
          styles: {
            font: 'PTSans',
            fontStyle: 'bold',
            fontSize: 16,
            halign: 'center',
          },
        }],
      ],
      theme: 'plain',
    });

    return this;
  };

  public save = (filename: string) => {
    this.document.save(filename);
  };
}

export default PdfCreator;