import { Product } from 'interfaces';

import styles from './ProductTableCountCellView.module.scss';


interface IProductTableCountCellView {
  counts: Pick<Product, 'count' | 'additionalCount' | 'secondAdditionalCount'>
  toggleEdit: () => void
}

export const ProductTableCountCellView = ({ counts, toggleEdit }: IProductTableCountCellView): JSX.Element => (
  <div className={styles.countContainer} onClick={toggleEdit}>
    <div>На Ириновском: {counts.count ?? 0}</div>
    <div>На Бокситогорской: {counts.additionalCount ?? 0}</div>
    <div>На Придорожной: {counts.secondAdditionalCount ?? 0}</div>
  </div>
);