import { OrderStatus } from '../enums/orderStatus';
import { transformEnumToOptions } from '1_shared';

export const orderStatusOptions = transformEnumToOptions(OrderStatus);

export const orderStatusFilter = [
  {
    text: OrderStatus.NEW,
    value: OrderStatus.NEW,
    color: 'blue',
  },

  {
    text: OrderStatus.DONE,
    value: OrderStatus.DONE,
    color: 'green',
  },
  {
    text: OrderStatus.CANCELED,
    value: OrderStatus.CANCELED,
    color: 'red',
  },
  {
    text: OrderStatus.SENT,
    value: OrderStatus.SENT,
    color: 'gold',
  },
  {
    text: OrderStatus.ACCEPTED,
    value: OrderStatus.ACCEPTED,
    color: 'cyan',
  },
  {
    text: OrderStatus.WAITING,
    value: OrderStatus.WAITING,
    color: 'purple',
  },
  {
    text: OrderStatus.PAYMENT_RECEIVED,
    value: OrderStatus.PAYMENT_RECEIVED,
    color: 'orange',
  },
];