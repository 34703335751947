import { Button, Col, PageHeader, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ServicesCard } from '../../../components/ServicesCard';
import { Spinner } from '../../../components/Spinner';
import { Permissions } from '../../../enums/permissions';
import { useFetchData } from '../../../hooks/useFetchData';
import { userService } from '../../../services';
import { Pages } from '../../../1_shared/constants/pages';

import styles from './ServicesPage.module.scss';

export const ServicesPage = () => {
  const { isLoading, data = [] } = useFetchData('/services');
  const navigate = useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  const handleCreate = () => navigate(Pages.SERVICES_CREATE);

  return (
    <>
      <PageHeader
        className="page-header"
        title="Услуги"
        extra={userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]) && [
          <Button
            key="1"
            type="primary"
            onClick={handleCreate}
          >
            Создать категорию услуг
          </Button>,
        ]}
      />
      <Row className={styles.container} gutter={[16, 16]} justify='center'>
        {data.map((item) => (
          <Col xs={24} md={12} xl={6} key={item.id}>
            <ServicesCard services={item} />
          </Col>
        ))}
      </Row>
    </>
  );
};