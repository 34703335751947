import { useState } from 'react';
import { Tabs, FormInstance } from 'antd';

import { ParsingCarGeneralTab } from './ParsingCarGeneralTab';
import { ImagesVideoTab } from '../ImagesVideoTab';
import { SeoTab } from '../SeoTab';
import { useQuery } from '1_shared';
import { ParsingCarProductsTab } from '4_widgets';

interface IParsingCarTabs {
  generalTabProps: { data: any; form: FormInstance };
}

export const ParsingCarTabs = ({ generalTabProps }: IParsingCarTabs): JSX.Element => {
  const { updateQuery } = useQuery();

  const [activeTabKey, setActiveTabKey] = useState('generalTab');

  return (
    <Tabs
      defaultActiveKey="generalTab"
      activeKey={activeTabKey}
      type="card"
      tabBarGutter={5}
      onChange={(activeKey: string) => {
        setActiveTabKey(activeKey);

        if (activeKey === 'products') {
          updateQuery({
            id: generalTabProps?.data?.id as string,
          });
        }
      }}
      items={[
        {
          key: 'generalTab',
          label: 'Основное',
          children: <ParsingCarGeneralTab {...generalTabProps} />,
        },
        {
          key: 'imagesVideoTab',
          label: 'Изображения и видео',
          children: <ImagesVideoTab {...generalTabProps} hasVideoGallery />,
        },
        {
          key: 'seoTab',
          label: 'SEO',
          children: <SeoTab />,
        },
        {
          key: 'products',
          label: 'Товары',
          children: (<ParsingCarProductsTab id={generalTabProps?.data?.id as string} />),
        },
      ]}
    />
  );
};
