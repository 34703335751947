import { FC } from 'react';
import { Form, FormInstance } from 'antd';

import { DinamicInput } from '../../DinamicInput';
import { UploadImage } from '../../Upload/UploadImage';
import { userService } from '../../../services';
import { Permissions } from '../../../enums/permissions';

type Props = {
  data: any;
  form: FormInstance;
  hasImageGallery?: boolean;
  hasVideoGallery?: boolean;
};

export const ImagesVideoTab: FC<Props> = ({
  data,
  form,
  hasImageGallery = true,
  hasVideoGallery,
}) => {
  const handleGalleryUploadChange = (value: string) => {
    const gallery = form.getFieldValue('gallery');
    gallery.push(value);
    form.setFieldsValue({ gallery });
  };

  const handleImageRemove = () => {
    form.setFieldsValue({ image: '' });
  };

  const handleGalleryRemove = (value: string) => {
    const gallery: string[] = form.getFieldValue('gallery');

    const index = gallery.findIndex((item: string) => item === value);

    if (index !== -1) {
      gallery.splice(index, 1);
    }

    form.setFieldsValue({ gallery });
  };

  return (
    <>
      <Form.Item name="image" noStyle>
        <UploadImage
          title="Основное изображение"
          images={data.image ? [data.image] : []}
          onRemove={handleImageRemove}
          disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
        />
      </Form.Item>
      {hasImageGallery && (
        <Form.Item
          initialValue={data.gallery ?? []}
          trigger=""
          name="gallery"
          valuePropName="fileList"
          noStyle
        >
          <UploadImage
            title="Галлерея изображений"
            images={data.gallery}
            onChange={handleGalleryUploadChange}
            onRemove={handleGalleryRemove}
            disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            multiple
          />
        </Form.Item>
      )}
      {hasVideoGallery && (
        <DinamicInput
          formName="videoGallery"
          title="Галерея видео"
          btnText="Добавить видео"
          placeholder="URL"
        />
      )}
    </>
  );
};
