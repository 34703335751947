import { ApiService } from '../services/ApiService';
import { NotificationService } from '../services/NotificationService';

export class BaseDictionaryStore {
  name: string = '';

  basePath: string = '/dictionaries';

  items: Array<any> = [];

  apiService: ApiService;

  notificationService: NotificationService;

  constructor({ apiService, notificationService }) {
    this.apiService = apiService;
    this.notificationService = notificationService;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  afterLoad = (data: Array<any>) => { };

  fetch = async () => {
    try {
      const data: any = await this.apiService.GET(`${this.basePath}/${this.name}`);

      if (data) {
        this.items = data.items.map((item: any) => ({
          label: item.name,
          value: item.id,
          alias: item.alias || '',
        }));

        this.afterLoad(data);
      }
    } catch (error) {
      this.notificationService.notifyError(error);
    }
  };
}