import { FC } from 'react';
import {
  Form,
  Input,
  FormInstance,
  Select,
} from 'antd';

import { introtextField, requiredField } from '../../../../constants/formRules';
import { RichTextEditor } from '../../../RichTextEditor';
import { translit } from '../../../../utils/translit';
import { dictionaries } from '../../../../dictionaries';

type Props = {
  data: any;
  form: FormInstance;
};

export const CompletedWorkGeneralTab: FC<Props> = ({ data, form }) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  return (
    <>
      <Form.Item
        name="name"
        label="Наименование выполненных работ*"
        rules={requiredField}
      >
        <Input onChange={handleNameChange} />
      </Form.Item>
      <Form.Item name="alias" label="Url псевдоним*" rules={requiredField}>
        <Input />
      </Form.Item>
      <Form.Item name="services" label="Оказываемые услуги">
        <Select
          optionFilterProp="label"
          mode="multiple"
          allowClear
          options={dictionaries.servicesDictionary.items}
        />
      </Form.Item>
      <Form.Item name="introtext" label="Краткое описание" rules={introtextField}>
        <Input maxLength={100} />
      </Form.Item>
      <div>
        <span>Описание</span>
        <Form.Item name="content" label="Контент" noStyle>
          <RichTextEditor initialValue={data.content} />
        </Form.Item>
      </div>
    </>
  );
};