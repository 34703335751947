import { FC } from 'react';
import { Tabs, FormInstance } from 'antd';

import { ProductGeneralTab } from './ProductGeneralTab';
import { ImagesVideoTab } from '../ImagesVideoTab';
import { SeoTab } from '../SeoTab';
import { ManagerTab } from '../ManagerTab';
import { AvitoTab } from '2_entities';

type Props = {
  generalTabProps: { data: Object; form: FormInstance };
};

export const ProductTabs: FC<Props> = ({ generalTabProps }) => (
  <Tabs
    defaultActiveKey="1"
    type="card"
    tabBarGutter={5}
    items={[
      {
        key: 'generalTab',
        label: 'Основное',
        children: <ProductGeneralTab {...generalTabProps} />,
      },
      {
        key: 'imagesVideoTab',
        label: 'Изображения и видео',
        children: <ImagesVideoTab {...generalTabProps} hasVideoGallery />,
      },
      {
        key: 'seoTab',
        label: 'SEO',
        children: <SeoTab />,
      },
      {
        key: 'managerTab',
        label: 'Для менеджера',
        children: <ManagerTab {...generalTabProps} />,
      },
      {
        key: 'avitoTab',
        label: 'Авито',
        children: <AvitoTab {...generalTabProps} />,
      },
    ]}
  />
);
