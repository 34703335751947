import { Button, PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ACL } from '../../components/ACL';
import { ItemCard } from '../../components/ItemCard';
import { Spinner } from '../../components/Spinner';
import { Permissions } from '../../enums/permissions';
import { useFetchData } from '../../hooks/useFetchData';
import { Item } from '../../interfaces/item';
import { Pages } from '../../1_shared/constants/pages';

import styles from './BlogsPage.module.scss';

export const BlogsPage = () => {
  const query = {
    order: ['createdAt DESC'],
  };
  const { isLoading, data = [] } = useFetchData(`/blogs?filter=${JSON.stringify(query)}`);
  const navigate = useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  const handleCreate = () => navigate(Pages.BLOG_CREATE);

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Блог компании"
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={handleCreate}
            >
              Добавить
            </Button>,
          ]}
        />
        <div className={styles.container}>
          {data.map((blog: Item) => (
            <ItemCard
              key={blog.id}
              item={blog}
              basePath={Pages.BLOGS}
            />
          ))}
        </div>
      </>
    </ACL>
  );
};