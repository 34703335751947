import { useState } from 'react';
import { Form, Button, PageHeader } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { commonVerticalLayout } from '../../../constants/formLayout';
import { apiService, notificationService, userService } from '../../../services';
import { Spinner } from '../../../components/Spinner';
import { dictionaries } from '../../../dictionaries';
import { CarTabs } from '../../../components/Tabs/CarTabs';
import { useFetchData } from '../../../hooks/useFetchData';
import { ACL } from '../../../components/ACL';
import { Permissions } from '../../../enums/permissions';
import { DeleteButton, Pages } from '1_shared';

const INITIAL_VALUES = {
  alias: '',
  name: '',
  parentAlias: '',
  parentId: '',
  published: false,
};

export const ModelEditPage = () => {
  const navigate = useNavigate();
  const param: any = useParams();
  const [form] = Form.useForm();

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/car-models/${param.id}`);
  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const hasAccess = userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN]);

  const isNew = !param.id;

  const subTitle = isNew
    ? 'Создание модели автомобиля'
    : 'Редактирование модели автомобиля';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleCancel = () => {
    navigate(Pages.CARS);
  };

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (isNew) {
        const requestedData = await apiService.POST('/car-models', body);

        navigate(`${Pages.MODELS}/${requestedData.id}`);
      } else {
        await apiService.PATCH(`/car-models/${data.id}`, body);
      }
      notificationService.notifySuccess('Модель автомобиля успешно сохранена');
      dictionaries.carDictionary.fetch();
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const deleteModel = async () => {
    try {
      await apiService.DELETE(`/car-models/${data.id}`);
      navigate(Pages.CARS);
      notificationService.notifySuccess('Модель успешно удалена');
      dictionaries.carDictionary.fetch();
    } catch (error) {
      notificationService.notifyError(error);
    }
  };

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Модель"
          subTitle={subTitle}
          extra={[
            <DeleteButton
              key='delete-btn'
              confirmTitle='Вы действительно хотите удалить модель'
              onDelete={deleteModel}
              disabled={isNew || !hasAccess}
            />,
            <Button key="2" onClick={handleCancel}>
              {isNew ? 'Отмена' : 'Закрыть'}
            </Button>,
            <Button
              key="1"
              type="primary"
              disabled={isSaveBtnDisabled}
              onClick={handleSave}
            >
              Сохранить
            </Button>,
          ]}
        />
        <div className='page-content'>
          <Form
            className="mg-t-16"
            form={form}
            labelAlign="left"
            requiredMark={false}
            initialValues={{ ...INITIAL_VALUES, ...data }}
            layout="vertical"
            {...commonVerticalLayout}
          >
            <CarTabs
              withParent
              parentOptions={dictionaries.carDictionary.series}
              parentLabel="Родительская серия*"
              generalTabProps={{ data, form }}
            />
          </Form>
        </div>
      </>
    </ACL>
  );
};
