import autoTable, { RowInput, Styles } from 'jspdf-autotable';
import {
  IRefund,
  ISaleManualProduct,
  ISaleProduct,
  PdfCreator,
  getDate,
} from '1_shared';
import { ISale, ISaleService } from '../interfaces';
import { Conditions } from 'enums/conditions';

const INCRIMENT: string = 'increment';
const DECRIMENT: string = 'decriment';

const PRODUCTS_TABLE_HEADER: RowInput = ['Код товара', 'Наименование', 'Кол-во', 'Цена', 'Состояние'].map((item => ({
  content: item,
  styles: { fontStyle: 'bold' },
})));

export class SaleReportPdfCreator extends PdfCreator {
  private total: number = 0;

  private totalNewProducts: number = 0;

  private totalBuProducts: number = 0;

  private totalServices: number = 0;

  private totalOneSalePrice: number = 0;

  private currentCreatedAt: string = '';

  private addTotal = () => {
    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      textColor: 20,
    };

    const body: RowInput[] = [
      [
        {
          content: `ОБЩИЙ ИТОГ: ${this.total} руб.`,
          colSpan: 3,
          styles: {
            fillColor: 'lightgreen',
          },
        },
      ],
      [
        {
          content: 'Новых запчастей',
          styles: {
            fillColor: 'lightyellow',
          },
        },
        {
          content: 'Б/У запчастей',
          styles: {
            fillColor: 'orange',
          },
        },
        {
          content: 'Работы',
          styles: {
            fillColor: 'gray',
          },
        },
      ],
      [
        { content: `${this.totalNewProducts} руб.` },
        { content: `${this.totalBuProducts} руб.` },
        { content: `${this.totalServices} руб.` },
      ],
    ];

    autoTable(this.document, {
      body,
      bodyStyles,
      theme: 'grid',
    });
  };

  private addProducts = (
    products: ISaleProduct[] | ISaleManualProduct[],
    body: RowInput[],
    { type = INCRIMENT, withHeader = true },
  ) => {
    if (products.length) {
      if (withHeader) {
        body.push(PRODUCTS_TABLE_HEADER);
      }

      products.forEach((product) => {
        const {
          code = '',
          name: productName,
          saleCount = 0,
          saleAdditionalCount = 0,
          saleSecondAdditionalCount = 0,
          salePrice = 0,
          condition,
        } = product;

        const totalProductCount = saleCount + saleAdditionalCount + saleSecondAdditionalCount;
        const price = salePrice * totalProductCount;

        if (type === INCRIMENT) {
          this.totalOneSalePrice += price;
          this.total += price;

          if (condition === Conditions.BU) {
            this.totalBuProducts += price;
          }

          if (condition === Conditions.NEW) {
            this.totalNewProducts += price;
          }
        }

        if (type === DECRIMENT) {
          this.totalOneSalePrice -= price;
          this.total -= price;

          if (condition === Conditions.BU) {
            this.totalBuProducts -= price;
          }

          if (condition === Conditions.NEW) {
            this.totalNewProducts -= price;
          }
        }

        body.push(
          [code, productName, totalProductCount, price, condition].map((item) => ({
            content: item,
            styles: { fillColor: type === INCRIMENT ? 'lightgreen' : 'red' },
          })),
        );
      });
    }
  };

  private addRefunds = (refunds: IRefund[], body: RowInput[]) => {
    if (refunds.length) {
      refunds.forEach((refund) => {
        const {
          createdAt,
          reason = '',
          products,
        } = refund;

        body.push(
          [
            { content: 'Дата возврата', styles: { fontStyle: 'bold', cellWidth: 45 } },
            { content: getDate(createdAt), colSpan: 4, styles: { fontStyle: 'normal' } },
          ],
          [
            { content: 'Причина возврата', styles: { fontStyle: 'bold', cellWidth: 45 } },
            { content: reason, colSpan: 4, styles: { fontStyle: 'normal' } },
          ],
        );

        this.addProducts(products, body, { type: DECRIMENT });
      });
    }
  };

  private addServices = (
    services: ISaleService[],
    body: RowInput[],
    { withHeader = true },
  ) => {
    if (services.length) {
      if (withHeader) {
        body.push(
          [
            { content: 'Наименование услуги', colSpan: 2 },
            'Кол-во',
            'Цена',
            '',
          ],
        );
      }

      services.forEach((serv) => {
        const {
          name,
          count,
          price,
        } = serv;

        const servicesTotalPrice = count * price;

        this.total += servicesTotalPrice;
        this.totalServices += servicesTotalPrice;
        this.totalOneSalePrice += servicesTotalPrice;

        body.push([
          { content: name, colSpan: 2, styles: { fillColor: 'gray' } },
          { content: count, styles: { fillColor: 'gray' } },
          { content: price, styles: { fillColor: 'gray' } },
          { content: '', styles: { fillColor: 'gray' } },
        ]);
      });
    }
  };

  private processSaleFull = (sale: ISale): RowInput[] => {
    const {
      name,
      createdAt,
      paymentMethod,
      source,
      client,
      comment,
      products = [],
      refunds = [],
      services = [],
    } = sale;

    // reset for each sale
    this.totalOneSalePrice = 0;

    const body: RowInput[] = [
      [{ content: name, colSpan: 5, styles: { fontStyle: 'bold', cellPadding: 5 } }],
      [
        { content: 'Дата продажи', styles: { fontStyle: 'bold', cellWidth: 45 } },
        { content: getDate(createdAt), colSpan: 4, styles: { fontStyle: 'normal', fillColor: 'yellow' } },
      ],
      [
        { content: 'Метод оплаты', styles: { fontStyle: 'bold', cellWidth: 45 } },
        { content: paymentMethod, colSpan: 4, styles: { fontStyle: 'normal' } },
      ],
      [
        { content: 'Источник', styles: { fontStyle: 'bold', cellWidth: 45 } },
        { content: source, colSpan: 4, styles: { fontStyle: 'normal' } },
      ],
      [
        { content: 'Автомобиль клиента', styles: { fontStyle: 'bold', cellWidth: 45 } },
        { content: client?.car, colSpan: 4, styles: { fontStyle: 'normal' } },
      ],
      [
        { content: 'Комментарий', styles: { fontStyle: 'bold', cellWidth: 45 } },
        { content: comment, colSpan: 4, styles: { fontStyle: 'normal' } },
      ],
    ];

    this.addProducts(products, body, { type: INCRIMENT });
    this.addServices(services, body, { withHeader: true });

    body.push([{
      content: `ИТОГО: ${this.totalOneSalePrice} руб.`,
      colSpan: 5,
      styles: { halign: 'right', fontStyle: 'bold' },
    }]);

    this.addRefunds(refunds, body);

    return body;
  };

  private processSaleShort = (sale: ISale, isLast: boolean = false) => {
    const {
      createdAt = '',
      products = [],
      refunds = [],
      services = [],
    } = sale;

    const body: RowInput[] = [];

    const date = getDate(createdAt).split(',')[0];

    if (this.currentCreatedAt !== date) {
      if (this.currentCreatedAt) {
        body.push([{
          content: `ИТОГ ДНЯ: ${this.totalOneSalePrice} руб.`,
          colSpan: 5,
          styles: { halign: 'right', fontStyle: 'bold' },
        }]);
      }

      this.currentCreatedAt = date;

      body.push(
        [{ content: '', colSpan: 5, styles: { lineWidth: 0 } }],
        [{
          content: date,
          colSpan: 5,
          styles: { fillColor: 'yellow', halign: 'center', fontStyle: 'bold' },
        }],
        PRODUCTS_TABLE_HEADER,
      );

      // reset for each days
      this.totalOneSalePrice = 0;
    }

    this.addProducts(products, body, { type: INCRIMENT, withHeader: false });
    this.addServices(services, body, { withHeader: false });
    refunds.forEach((refund) => {
      this.addProducts(refund.products || [], body, { type: DECRIMENT, withHeader: false });
    });

    if (isLast) {
      body.push([{
        content: `ИТОГ ДНЯ: ${this.totalOneSalePrice} руб.`,
        colSpan: 5,
        styles: { halign: 'right', fontStyle: 'bold' },
      }]);
    }

    return body;
  };

  public addSalesFull = (sales: ISale[]) => {
    const head: RowInput[] = [['Список продаж']];
    const headStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'bold',
      fontSize: 16,
      halign: 'left',
    };

    const salesBody = sales.map(this.processSaleFull);

    this.addTotal();

    autoTable(this.document, {
      head,
      headStyles,
      theme: 'plain',
    });

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fontSize: 12,
      fillColor: undefined,
      textColor: 15,
    };

    salesBody.forEach((body) => {
      autoTable(this.document, {
        body,
        bodyStyles,
        theme: 'grid',
      });
    });

    return this;
  };

  public addSalesShort = (sales: ISale[]) => {
    const salesBody = sales.map((sale, index) => this.processSaleShort(sale, index === sales.length - 1));

    this.addTotal();

    const bodyStyles: Partial<Styles> = {
      font: 'PTSans',
      fontStyle: 'normal',
      fontSize: 12,
      fillColor: undefined,
      textColor: 15,
    };

    autoTable(this.document, {
      body: salesBody.flat(),
      bodyStyles,
      theme: 'grid',
    });

    return this;
  };
}