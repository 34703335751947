import { Button, Popconfirm } from 'antd';

interface IDeleteButtonProps {
  onDelete: () => Promise<void> | void
  disabled?: boolean
  confirmTitle: string
}

export const DeleteButton = ({ onDelete, disabled, confirmTitle }: IDeleteButtonProps) => {
  return (
    <Popconfirm
      title={confirmTitle}
      onConfirm={onDelete}
      disabled={disabled}
      okText="Да"
      cancelText="Нет"
    >
      <Button
        disabled={disabled}
        danger
      >
        Удалить
      </Button>
    </Popconfirm>
  );
};
