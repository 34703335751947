import { Form, Input, FormInstance } from 'antd';

import { requiredField } from '../../../../constants/formRules';
import { translit } from '../../../../utils/translit';

type Props = {
  data: any;
  form: FormInstance;
};

export const TypeGeneralTab = ({ form }: Props) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  return (
    <>
      <Form.Item
        name="name"
        label="Наименование типа*"
        rules={requiredField}
      >
        <Input onChange={handleNameChange} />
      </Form.Item>
      <Form.Item
        name="alias"
        label="Url псевдоним*"
        rules={requiredField}
      >
        <Input />
      </Form.Item>
    </>
  );
};
