export const TYPES = {
  move: 'move',
  changePrice: 'changePrice',
  changePublished: 'changePublished',
  changeStatus: 'changeStatus',
};

export const OPERATION_TYPE_OPTIONS = [
  {
    label: 'Перемещение товара',
    value: TYPES.move,
  },
  {
    label: 'Изменение цены',
    value: TYPES.changePrice,
  },
  {
    label: 'Изменение публикации',
    value: TYPES.changePublished,
  },
  {
    label: 'Изменение статуса',
    value: TYPES.changeStatus,
  },
];