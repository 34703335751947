export const avitoCategories = [
  {
    label: 'Запчасти и аксессуары',
    value: 'Запчасти и аксессуары',
  },
  {
    label: 'Грузовики и спецтехника',
    value: 'Грузовики и спецтехника',
  },
];

export const avitoTypes = [
  {
    label: 'Автосвет',
    value: '11-618',
  },
  {
    label: 'Автомобиль на запчасти',
    value: '19-2855',
  },
  {
    label: 'Аккумуляторы',
    value: '11-619',
  },
  {
    label: 'Двигатель / Блок цилиндров, головка, картер',
    value: '16-827',
  },
  {
    label: 'Двигатель / Вакуумная система',
    value: '16-828',
  },
  {
    label: 'Двигатель / Генераторы, стартеры',
    value: '16-829',
  },
  {
    label: 'Двигатель / Двигатель в сборе',
    value: '16-830',
  },
  {
    label: 'Двигатель / Катушка зажигания, свечи, электрика',
    value: '16-831',
  },
  {
    label: 'Двигатель / Клапанная крышка',
    value: '16-832',
  },
  {
    label: 'Двигатель / Коленвал, маховик',
    value: '16-833',
  },
  {
    label: 'Двигатель / Коллекторы',
    value: '16-834',
  },
  {
    label: 'Двигатель / Крепление двигателя',
    value: '16-835',
  },
  {
    label: 'Двигатель / Масляный насос, система смазки',
    value: '16-836',
  },
  {
    label: 'Двигатель / Патрубки вентиляции',
    value: '16-837',
  },
  {
    label: 'Двигатель / Поршни, шатуны, кольца',
    value: '16-838',
  },
  {
    label: 'Двигатель / Приводные ремни, натяжители',
    value: '16-839',
  },
  {
    label: 'Двигатель / Прокладки и ремкомплекты',
    value: '16-840',
  },
  {
    label: 'Двигатель / Ремни, цепи, элементы ГРМ',
    value: '16-841',
  },
  {
    label: 'Двигатель / Турбины, компрессоры',
    value: '16-842',
  },
  {
    label: 'Двигатель / Электродвигатели и компоненты',
    value: '16-843',
  },
  {
    label: 'Двигатель / Впускная система',
    value: '16-844',
  },
  {
    label: 'Кузов / Балки, лонжероны',
    value: '16-805',
  },
  {
    label: 'Кузов / Бамперы',
    value: '16-806',
  },
  {
    label: 'Кузов / Брызговики',
    value: '16-807',
  },
  {
    label: 'Кузов / Двери',
    value: '16-808',
  },
  {
    label: 'Кузов / Заглушки',
    value: '16-809',
  },
  {
    label: 'Кузов / Замки',
    value: '16-810',
  },
  {
    label: 'Кузов / Защита',
    value: '16-811',
  },
  {
    label: 'Кузов / Зеркала',
    value: '16-812',
  },
  {
    label: 'Кузов / Кабина',
    value: '16-813',
  },
  {
    label: 'Кузов / Капот',
    value: '16-814',
  },
  {
    label: 'Кузов / Крепления',
    value: '16-815',
  },
  {
    label: 'Кузов / Крылья',
    value: '16-816',
  },
  {
    label: 'Кузов / Крыша',
    value: '16-817',
  },
  {
    label: 'Кузов / Крышка, дверь багажника',
    value: '16-818',
  },
  {
    label: 'Кузов / Кузов по частям',
    value: '16-819',
  },
  {
    label: 'Кузов / Кузов целиком',
    value: '16-820',
  },
  {
    label: 'Кузов / Лючок бензобака',
    value: '16-821',
  },
  {
    label: 'Кузов / Молдинги, накладки',
    value: '16-822',
  },
  {
    label: 'Кузов / Пороги',
    value: '16-823',
  },
  {
    label: 'Кузов / Рама',
    value: '16-824',
  },
  {
    label: 'Кузов / Решетка радиатора',
    value: '16-825',
  },
  {
    label: 'Кузов / Стойка кузова',
    value: '16-826',
  },
  {
    label: 'Подвеска',
    value: '11-623',
  },
  {
    label: 'Рулевое управление',
    value: '11-624',
  },
  {
    label: 'Салон',
    value: '11-625',
  },
  {
    label: 'Система охлаждения',
    value: '16-521',
  },
  {
    label: 'Стекла',
    value: '11-626',
  },
  {
    label: 'Топливная и выхлопная системы',
    value: '11-627',
  },
  {
    label: 'Тормозная система',
    value: '11-628',
  },
  {
    label: 'Трансмиссия и привод',
    value: '11-629',
  },
  {
    label: 'Электрооборудование',
    value: '11-630',
  },
];
