import { useEffect, useState } from 'react';
import { apiService, notificationService } from '../services';

interface UseFetchDataReturnedValue {
  isLoading: boolean,
  data: any,
  refetch: () => Promise<void>
}

export const useFetchData = (url: string): UseFetchDataReturnedValue => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();

  const fetch = async (): Promise<void> => {
    if (url.includes('undefined')) {
      return;
    }

    try {
      setLoading(true);

      const requestedData = await apiService.GET(url);

      setData(requestedData);
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [url]);

  return {
    isLoading,
    data,
    refetch: fetch,
  };
}; 