import { FC } from 'react';
import { Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getThumbUrl } from '../../utils/thumb';
import { Item } from '../../interfaces/item';
import { WithoutImg } from '1_shared';

const { Meta } = Card;

type ItemCardProps = {
  item: Item
  basePath: string
  metaClassName?: string
};

export const ItemCard: FC<ItemCardProps> = ({ item, basePath, metaClassName }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${basePath}/${item.id}`);
  };

  const date = item.createdAt
    ? new Date(item.createdAt).toLocaleString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' })
    : '';
  
  const image = item.image || item.gallery && item.gallery[0];

  return (
    <Card
      onClick={handleClick}
      cover={
        <img
          alt={item.name}
          src={getThumbUrl(image) || WithoutImg}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = WithoutImg;
          }}
        />
      }
      hoverable
    >
      <Meta title={item.name} description={date} className={metaClassName} />
    </Card>
  );
};