import { ITableFetchResponse } from '1_shared';
import { apiService } from 'services';
import { getParsingCarQuery } from '../utils';

export const getParsingCars = <T>(query: any): Promise<T[]> =>
  apiService.GET(`/parsing-cars?filter=${JSON.stringify({ order: ['createdAt DESC', 'name ASC'], ...query })}`);


export const getParsingCarsWithProducts = <T>(query: any): Promise<ITableFetchResponse<T>> => {
  const parsingCarsQuery = getParsingCarQuery(query);

  return apiService.GET(`/parsing-cars-products?filter=${JSON.stringify({ order: ['createdAt DESC', 'name ASC'], ...parsingCarsQuery })}`);
};

export const createParsingCar = <T>(body: T): Promise<T> => 
  apiService.POST('/parsing-cars', body);

export const patchParsingCar = (id: string, body: unknown): Promise<void> =>
  apiService.PATCH(`/parsing-cars/${id}`, body);

export const deleteParsingCar = (id: string): Promise<void> =>
  apiService.DELETE(`/parsing-cars/${id}`);