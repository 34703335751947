import { Form, Input, FormInstance, Select, Checkbox, Divider } from 'antd';

import { requiredField, requiredNumberField } from '../../../../constants/formRules';
import { translit } from '../../../../utils/translit';
import { RichTextEditor } from '../../../RichTextEditor';

type Props = {
  data: any;
  form: FormInstance;
  withParent?: boolean;
  parentOptions?: Array<any>;
  parentLabel?: string
};

export const CarGeneralTab = ({ form, data, withParent = false, parentOptions, parentLabel }: Props) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  const handleParentChange = (value: string, option: any) => {
    form.setFields([{ name: 'parentAlias', value: option.alias }]);
  };

  return (
    <>
    {withParent && (
      <>
      <Form.Item hidden name="parentAlias" />
      <Form.Item
        name="parentId"
        label={parentLabel}
        rules={requiredNumberField}
      >
        <Select
          onChange={handleParentChange}
          options={parentOptions}
          optionFilterProp="label"
          showSearch
        />
      </Form.Item>
      </>
    )}
      <Form.Item name="name" label="Наименование*" rules={requiredField}>
        <Input onChange={handleNameChange} />
      </Form.Item>
      <Form.Item name="alias" label="Url псевдоним*" rules={requiredField}>
        <Input />
      </Form.Item>
      <Form.Item name="published" valuePropName="checked" noStyle>
        <Checkbox defaultChecked>Опубликован</Checkbox>
      </Form.Item>
      <Divider />
      <div>
        <h3>Контент</h3>
        <Form.Item name="content" label="Контент" noStyle>
          <RichTextEditor initialValue={data.content} />
        </Form.Item>
      </div>
    </>
  );
};
