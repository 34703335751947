import { FC } from 'react';
import {
  Form,
  Input,
  Checkbox,
  Divider,
  Select,
  Row,
  Col,
  FormInstance,
  InputNumber,
} from 'antd';

import { translit } from '../../../../utils/translit';
import { LabelField } from '../../../LabelField';
import { RichTextEditor } from '../../../RichTextEditor';
import { getDate } from '1_shared';
import { dictionaries } from '../../../../dictionaries';
import { introtextField, numberField, requiredField } from '../../../../constants/formRules';

type Props = {
  data: any;
  form: FormInstance;
};

export const ParsingCarGeneralTab: FC<Props> = ({ data, form }) => {
  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  return (
    <>
      <Row gutter={[32, 8]} >
        <Col md={16} xs={24}>
          <Form.Item
            name="name"
            label="Наименование товара*"
            rules={requiredField}
          >
            <Input onChange={handleNameChange} />
          </Form.Item>
          <Form.Item name="alias" label="Url псевдоним*" rules={requiredField}>
            <Input />
          </Form.Item>
          <Form.Item name="introtext" label="Краткое описание" rules={introtextField}>
            <Input maxLength={100} />
          </Form.Item>
          <Row gutter={20}>
            <Col md={8} xs={8}>
              <Form.Item name="mark" label="Марка">
                <Select
                  optionFilterProp="label"
                  allowClear
                  options={dictionaries.carDictionary.brands}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={8}>
              <Form.Item
                name="model"
                label="Модель"
              >
                <Select
                  optionFilterProp="label"
                  allowClear
                  options={dictionaries.carDictionary.applicabilityOptions}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={8}>
              <Form.Item name="price" label="Цена покупки" rules={numberField}>
                <InputNumber min={0} addonAfter="₽" step={100} />
              </Form.Item>
            </Col>
          </Row>


        </Col>
        <Col md={8} xs={24}>
          <LabelField label="Дата создания" value={getDate(data.createdAt)} />
          <LabelField label="Кем создан" value={data.createdBy?.name} />
          <Divider />
          <Form.Item name="published" valuePropName="checked" noStyle>
            <Checkbox defaultChecked>Опубликован</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div>
        <h3>Контент</h3>
        <Form.Item name="content" label="Контент" noStyle>
          <RichTextEditor initialValue={data.content} />
        </Form.Item>
      </div>
    </>
  );
};
