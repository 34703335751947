import { FC } from 'react';
import { Form, Input } from 'antd';

const { TextArea } = Input;

const labelColRight = { span: 7 };

export const SeoTab: FC = () => (
  <>
    <Form.Item
      name={['seo', 'description']}
      label="Description"
      labelCol={labelColRight}
    >
      <TextArea />
    </Form.Item>
    <Form.Item
      name={['seo', 'keywords']}
      label="Keywords"
      labelCol={labelColRight}
    >
      <TextArea />
    </Form.Item>
    <Form.Item name={['seo', 'h1']} label="Заголовок" labelCol={labelColRight}>
      <Input />
    </Form.Item>
    <Form.Item
      name={['seo', 'title']}
      label="Title страницы"
      labelCol={labelColRight}
    >
      <Input />
    </Form.Item>
  </>
);
