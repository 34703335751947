import { FC } from 'react';
import { userService } from '../../services';

interface AclProps {
  roles: string[];
  children: React.ReactElement;
}

export const ACL: FC<AclProps> = ({ roles, children }) => {
  if (!userService.hasAccess(roles)) {
    return <h3 style={{ textAlign: 'center' }}>У Вас нет прав для просмотра этой страницы</h3>;
  }

  return children;
};