import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { conditionOptions } from 'constants/conditionOptions';
import { commonVerticalLayout } from 'constants/formLayout';
import { numberField, requiredField } from 'constants/formRules';
import { ISaleManualProduct } from '1_shared';

interface IProductManuallyModal {
  isOpened: boolean;
  onOk: (product: ISaleManualProduct) => void;
  onCancel: () => void;
}

const ProductManuallyModal = ({ isOpened, onOk, onCancel }: IProductManuallyModal): JSX.Element => {
  const [form] = Form.useForm();

  const handleOk = async () => {
    try {
      await form.validateFields();

      const product = form.getFieldsValue();
      onOk(product);
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="Добавить товар в ручную"
      open={isOpened}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Добавить"
      cancelText="Отмета"
    >
      <Form
        form={form}
        labelAlign="left"
        requiredMark={false}
        initialValues={{
          name: '',
          condition: '',
          salePrice: 0,
          saleCount: 1,
        }}
        layout="vertical"
        {...commonVerticalLayout}
      >
        <Form.Item
          name="name"
          label="Наименование товара*"
          rules={requiredField}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="condition"
          label="Состояние*"
          rules={requiredField}
        >
          <Select options={conditionOptions} />
        </Form.Item>
        <Form.Item name="salePrice" label="Цена" rules={numberField}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="saleCount" label="Кол-во" rules={numberField}>
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductManuallyModal;