import { useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Popconfirm,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchData } from '../../../hooks/useFetchData';
import { Spinner } from '../../../components/Spinner';
import { Pages } from '../../../1_shared/constants/pages';
import { apiService, notificationService } from '../../../services';
import { commonVerticalLayout } from '../../../constants/formLayout';
import { BlogTabs } from '../../../components/Tabs/BlogTabs';
import { ACL } from '../../../components/ACL';
import { Permissions } from '../../../enums/permissions';

export const BlogEditPage = () => {
  const navigate = useNavigate();
  const param: any = useParams();
  const [form] = Form.useForm();

  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/blogs/${param.id}`);

  const isNew: boolean = !param.id;

  const subTitle: string = isNew
    ? 'Создание'
    : 'Редактирование';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleCancel = () => {
    navigate(Pages.BLOGS);
  };

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (isNew) {
        const requestedData = await apiService.POST('/blogs', body);

        navigate(`${Pages.BLOGS}/${requestedData.id}`);
      } else {
        await apiService.PATCH(`/blogs/${data.id}`, body);
      }
      notificationService.notifySuccess('Успешно сохранено');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSaveBtnDisabled(true);
      await apiService.DELETE(`/blogs/${data.id}`);
      navigate(Pages.SERVICES);
      notificationService.notifySuccess('Успешно удалено');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  return (
    <ACL roles={[Permissions.SUPER_ADMIN, Permissions.ADMIN]}>
      <>
        <PageHeader
          className="page-header"
          title="Блог"
          subTitle={subTitle}
          extra={[
            <Popconfirm
              key='delete-btn'
              title="Вы действительно хотите удалить?"
              onConfirm={handleDelete}
              disabled={isNew}
              okText="Да"
              cancelText="Нет"
            >
              <Button disabled={isNew} danger>Удалить</Button>
            </Popconfirm>,
            <Button key="2" onClick={handleCancel}>
              {isNew ? 'Отмена' : 'Закрыть'}
            </Button>,
            <Button
              key="1"
              type="primary"
              disabled={isSaveBtnDisabled}
              onClick={handleSave}
            >
              Сохранить
            </Button>,
          ]}
        />
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={data}
          layout="vertical"
          {...commonVerticalLayout}
        >
          <BlogTabs generalTabProps={{ data, form }} />
        </Form>
      </>
    </ACL>
  );
};
