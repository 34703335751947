import { Image } from 'antd';
import { useState } from 'react';

import { WithoutImg } from '../../constants/image';
import { getThumbUrl } from '../../../utils/thumb';

interface IImagePreview {
  image?: string;
  gallery?: string[];
  height?: number;
  width?: number | string;
}

export const ImagePreview = ({ 
  image, 
  gallery, 
  height = 60, 
  width = 'auto', 
}: IImagePreview) => {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = (value: boolean) => {
    setVisible(value);
  };

  const onVisibleClick = () => {
    setVisible(true);
  };

  const previewImage = image || gallery && gallery[0];

  return (
    <>
      <Image
        preview={{ visible: false }}
        height={height}
        width={width}
        src={getThumbUrl(previewImage) || WithoutImg}
        fallback={WithoutImg}
        onClick={onVisibleClick}
      />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange }}>
          <Image key={image} src={getThumbUrl(image)} />
          {gallery?.map((item: string) => (
            <Image key={item} src={getThumbUrl(item)} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};
