import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';
import { Checkbox, Table } from 'antd';
import type { TableProps } from 'antd';
import { AnyObject } from '../../interfaces';

import styles from './VirtualTable.module.scss';

export const VirtualTable = <RecordType extends object>(props: TableProps<RecordType>) => {
  // @ts-ignore
  const { columns, scroll, onCheck, onCheckAll, selectedRows } = props;
  const [tableWidth, setTableWidth] = useState(0);

  const widthColumnCount = columns!.filter(({ width }) => !width).length;
  const mergedColumns = columns!.map(column => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rowData: object[], { scrollbarSize, ref, onScroll }: any) => {
    const rowHeight = 60;
    ref.current = connectObject;
    const totalHeight = rowData.length * rowHeight;

    return (
      <Grid
        ref={gridRef}
        className={styles.virtualGrid}
        columnCount={mergedColumns.length}
        columnWidth={(index: number) => {
          const { width } = mergedColumns[index];

          return width as number;
          // return totalHeight > scroll!.y! && index === mergedColumns.length - 1
          //   ? (width as number) - scrollbarSize - 1
          //   : (width as number);
        }}
        height={scroll!.y as number}
        rowCount={rowData.length}
        rowHeight={() => rowHeight}
        width={tableWidth}
      // onScroll={({ scrollLeft }: { scrollLeft: number }) => {
      //   onScroll({ scrollLeft });
      // }}
      >
        {({
          columnIndex,
          rowIndex,
          style,
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const row: AnyObject = rowData[rowIndex];
          const id = row.id;
          const cell = mergedColumns[columnIndex];
          // @ts-ignore
          const cellKey = cell.dataIndex;
          let cellValue = row[cellKey];

          if (cell.render && typeof cell.render === 'function') {
            // @ts-ignore
            cellValue = cell.render(cellValue, row, rowIndex);
          }

          return (
            <div
              className={classNames(styles.virtualTableCell, {
                [styles.virtualTableCellLast]: columnIndex === mergedColumns.length - 1,
              })}
              style={style}
            >
              {cellKey === 'checkbox'
                ? (
                  <Checkbox
                    onChange={() => onCheck(id, row)}
                    checked={selectedRows.has(id)}
                  />
                )
                : cellValue
              }
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <div style={{ width: '1750px' }}>
        <table className={styles.header}>
          <thead>
            <tr>
              {columns?.map(({ title, key, width }) => (
                <td key={key} className={styles.headerCell} style={{ width }}>
                  {key === 'checkbox'
                    ? (
                      <Checkbox
                        onChange={onCheckAll}
                        checked={props?.dataSource?.length === selectedRows.size}
                      />
                    )
                    : title
                  }
                </td>
              ))}
            </tr>
          </thead>
        </table>
        <Table
          {...props}
          className="virtual-table"
          columns={mergedColumns}
          pagination={false}
          showHeader={false}
          tableLayout="fixed"
          components={{
            // @ts-ignore
            body: renderVirtualList,
          }}
        />
      </div>
    </ResizeObserver>
  );
};